import React, { useState, useCallback } from 'react';

import style from './TouchRipple.module.css';

/**
 * show a rippling effect after touch event
 */
export const TouchRipple: React.FC = ({ children }) => {
  const [ripples, setRipples] = useState<{ x: number; y: number; c: number }[]>([]);
  const [touchPosition, setTouchPosition] = useState({ x: 0, y: 0 });

  const appendRipple = useCallback(({ x, y }: { x: number; y: number }) => {
    setRipples([...ripples, { x, y, c: +(new Date()) }]);
    // remove done ripple
    setTimeout(() => {
      setRipples((updatedRipples) => {
        const newRipples = [...updatedRipples];
        newRipples.shift();
        return newRipples;
      });
    }, 1000);
  }, [ripples, setRipples]);

  return (
    <div
      onTouchStart={ev => setTouchPosition({ x: ev.touches[0].clientX, y: ev.touches[0].clientY })}
      onTouchEnd={(ev) => {
        const distance = Math.sqrt((touchPosition.x - ev.changedTouches[0].clientX) ** 2 + (touchPosition.y - ev.changedTouches[0].clientY) ** 2);
        if (distance < 15) {
          appendRipple({ x: ev.changedTouches[0].clientX, y: ev.changedTouches[0].clientY });
        }
      }}
    >
      <>{children}</>
      {ripples.map(({ x, y, c }) => (
        <div
          key={`${x}-${y}-${c}`}
          className={style.ripple}
          style={{ left: `${x}px`, top: `${y}px` }}
        />
      ))}
    </div>
  );
};
