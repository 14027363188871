import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import { sounds, CartoonButton } from '../../../components';

import style from './Mokia.module.css';

import buttonSrc from './assets/button.png';
import buttonPressed from './assets/pressed.png';
import keyboard from './assets/keyboard.png';

export const interfaceImages = [
  buttonSrc,
  buttonPressed,
  keyboard
];

const buttons = ['   ', 'abc', 'def', 'ghi', 'jkl', 'mno', 'pqrs', 'tuv', 'wxyz'];

const getUpcomingText = ({ pressedButton, howManyTimes }: { pressedButton: number; howManyTimes: number }) => {
  if (!pressedButton) return '';
  const text = (buttons[pressedButton - 1]).split('');
  const i = howManyTimes % text.length;
  return text[i].toLocaleUpperCase();
};

export const Interface = () => {
  const [screenText, setScreenText] = useState('');
  const [timeout, setLockinTimeout] = useState<any>();
  const [{ pressedButton, howManyTimes }, setPressedButton] = useState({ pressedButton: 0, howManyTimes: 0 });

  // eslint-disable-next-line no-shadow
  const lockInTheLetter = useCallback(({ pressedButton, howManyTimes, screenText }: { pressedButton: number; howManyTimes: number; screenText: string }) => {
    if (!pressedButton) return '';
    const text = screenText + getUpcomingText({ pressedButton, howManyTimes });
    setScreenText(text.slice(-5));
    setPressedButton({ pressedButton: 0, howManyTimes: 0 });
    clearTimeout(timeout);
    return getUpcomingText({ pressedButton, howManyTimes });
  }, [timeout]);

  const onClick = useCallback((button: number) => {
    const i = (button % 3) + 1;
    let upcomingText = '';
    sounds[`phoneTone${i}`]();

    clearTimeout(timeout);
    if (pressedButton !== button) {
      upcomingText = lockInTheLetter({ pressedButton, howManyTimes, screenText });
    }

    const combination = {
      pressedButton: button,
      howManyTimes: pressedButton === button ? howManyTimes + 1 : 0
    };
    setPressedButton(combination);
    setLockinTimeout(setTimeout(() => lockInTheLetter({ ...combination, screenText: screenText + upcomingText }), 1000));
  }, [howManyTimes, lockInTheLetter, pressedButton, screenText, timeout]);

  return (
    <div className={style.interface}>
      <div className={style.screen}>{screenText}{getUpcomingText({ pressedButton, howManyTimes })}</div>
      <div className={style.keyboard} style={{ backgroundImage: `url(${keyboard})` }} />
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(n => (
        <CartoonButton
          key={`button${n}`}
          src={buttonSrc}
          hoverSrc={buttonSrc}
          activeSrc={buttonPressed}
          onClick={() => onClick(n)}
          className={classNames(style.button, style[`button${n}`])}
        />
      ))}
    </div>
  );
};

