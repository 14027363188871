import React, { useCallback } from 'react';

import { GameItem, sounds } from '../../../components';
import { useCurrentPlayer } from '../../../state';
import { useKeypad } from '../Keypad/keypadState';

export const Stairs = () => {
  const [{ isOldDog }, { changeLevel }] = useCurrentPlayer();
  const [{ solved }] = useKeypad();

  const onDescent = useCallback(() => {
    sounds.success2();
    setTimeout(() => changeLevel('2-waiting'), 1000);
  }, [changeLevel]);

  if (!solved) {
    return <></>;
  }

  if (isOldDog) {
    return (
      <GameItem
        name="l1StairsOffice"
        disabled={true}
        onClick={() => onDescent()}
        iconStyle={{
          width: '21vw',
          height: '33vw',
          top: '11vw',
          left: '75vw'
        }}
      />
    );
  }

  return (
    <GameItem
      name="l1StairsStoreroom"
      disabled={true}
      onClick={() => onDescent()}
      iconStyle={{
        width: '20vw',
        height: '32vw',
        top: '7vw',
        left: '70vw'
      }}
    />
  );
};
