import page1_pic1 from './assets/page1_pic1.png';
import page1_pic2 from './assets/page1_pic2.png';
import page1_pic3 from './assets/page1_pic3.png';
import page1_pic4 from './assets/page1_pic4.png';
import page1_txt1 from './assets/page1_txt1.png';
import page2_pic1 from './assets/page2_pic1.png';
import page2_pic2 from './assets/page2_pic2.png';
import page2_pic3 from './assets/page2_pic3.png';
import page2_pic4 from './assets/page2_pic4.png';
import page2_txt1 from './assets/page2_txt1.png';
import page2_txt2 from './assets/page2_txt2.png';
import page2_txt3 from './assets/page2_txt3.png';
import page2_txt4 from './assets/page2_txt4.png';
import page2_txt6 from './assets/page2_txt6.png';
import page3_pic1 from './assets/page3_pic1.png';
import page3_pic2 from './assets/page3_pic2.png';
import page3_pic3 from './assets/page3_pic3.png';
import page3_pic4 from './assets/page3_pic4.png';
import page3_txt2 from './assets/page3_txt2.png';
import page3_txt3 from './assets/page3_txt3.png';
import page3_txt4 from './assets/page3_txt4.png';
import page3_txt5 from './assets/page3_txt5.png';
import page3_txt6 from './assets/page3_txt6.png';

const WIDTH = 2800;

export const CUTSCENE5 = [
  { type: 'sound', src: 'spooky', delay: 0, volume: 0.2 },
  { type: 'sound', src: 'open', delay: 500 },
  { type: 'image', src: page1_pic1, delay: 1000, top: 0, left: 0, width: (564 / WIDTH) * 100 },
  { type: 'sound', src: 'scream', delay: 500 },
  { type: 'image', src: page1_pic2, delay: 500, top: -4.7, left: 21.5, width: (725 / WIDTH) * 100, zIndex: 1 },
  { type: 'image', src: page1_pic3, delay: 500, top: 0, left: 46.3, width: (755 / WIDTH) * 100 },
  { type: 'sound', src: 'doorBurstIn', delay: 200 },
  { type: 'image', src: page1_txt1, delay: 500, top: 8, left: 49, width: (287 / WIDTH) * 100 },
  { type: 'image', src: page1_pic4, delay: 1500, top: 0, left: 74, width: (726 / WIDTH) * 100 },
  { type: 'sound', src: 'monkey1', delay: 1000 },
  { type: 'page-end', delay: 3000 },
  { type: 'image', src: page2_pic1, delay: 1000, top: 0, left: 0, width: (755 / WIDTH) * 100 },
  { type: 'image', src: page2_txt1, delay: 1000, top: 0, left: -2, width: (291 / WIDTH) * 100, zIndex: 2 },
  { type: 'sound', src: 'voice04', delay: 200 },
  { type: 'image', src: page2_pic2, delay: 200, top: 0, left: 28.5, width: (689 / WIDTH) * 100 },
  { type: 'image', src: page2_txt2, delay: 500, top: -1, left: 9, width: (651 / WIDTH) * 100 },
  { type: 'image', src: page2_txt3, delay: 2000, top: 64, left: -2, width: (540 / WIDTH) * 100 },
  { type: 'image', src: page2_txt4, delay: 2000, top: 0, left: 35, width: (493 / WIDTH) * 100, zIndex: 1 },
  { type: 'image', src: page2_txt6, delay: 1000, top: 66, left: 73, width: (712 / WIDTH) * 100, zIndex: 1 },
  { type: 'sound', src: 'parrotWhoGoes', delay: 200 },
  { type: 'image', src: page2_pic3, delay: 1000, top: 0, left: 55, width: (660 / WIDTH) * 100 },
  { type: 'image', src: page2_pic4, delay: 1000, top: 0, left: 80, width: (564 / WIDTH) * 100 },
  { type: 'page-end', delay: 2000 },
  { type: 'image', src: page3_pic1, delay: 1000, top: 0, left: 0, width: (564 / WIDTH) * 100 },
  { type: 'sound', src: 'cloth1', delay: 1000 },
  { type: 'image', src: page3_pic2, delay: 500, top: 0, left: 21, width: (660 / WIDTH) * 100 },
  { type: 'sound', src: 'parrot3', delay: 600 },
  { type: 'image', src: page2_txt6, delay: 1000, top: 66, left: 20, width: (712 / WIDTH) * 100, zIndex: 1 },
  { type: 'sound', src: 'parrotWhoGoes', delay: 200 },
  { type: 'image', src: page3_pic3, delay: 1000, top: 0, left: 46, width: (755 / WIDTH) * 100, animationIn: 'fadeInRight' },
  { type: 'image', src: page3_txt2, delay: 1000, top: -2, left: 45, width: (493 / WIDTH) * 100 },
  { type: 'sound', src: 'allyParrot', delay: 200 },
  { type: 'image', src: page3_pic4, delay: 1000, top: 0, left: 74, width: (726 / WIDTH) * 100 },
  { type: 'image', src: page3_txt3, delay: 500, top: 21, left: 49, width: (288 / WIDTH) * 100 },
  { type: 'sound', src: 'voice28', delay: 200 },
  { type: 'image', src: page3_txt4, delay: 1000, top: 61, left: 52, width: (749 / WIDTH) * 100 },
  { type: 'sound', src: 'allyParrot2', delay: 200 },
  { type: 'image', src: page3_txt5, delay: 3000, top: 0, left: 70, width: (820 / WIDTH) * 100 },
  { type: 'sound', src: 'voice14', delay: 200 },
  { type: 'image', src: page3_txt6, delay: 2500, top: 66, left: 81, width: (288 / WIDTH) * 100 },
  { type: 'sound', src: 'allyOk', delay: 200 },
];
