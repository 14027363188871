import React from 'react';
import { useDrop } from 'react-dnd';

import style from './Microscope.module.css';

interface DropzoneOutProps {
  isDragging: boolean;
}

export const DropzoneOut: React.FC<DropzoneOutProps> = ({ isDragging }) => {
  const [, drop] = useDrop({
    accept: 'sample',
    drop: () => ({
      where: 'out'
    }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  return (
    <div ref={drop} className={style.dropzoneOut} style={{ zIndex: isDragging ? 3 : 1 }} />
  );
};
