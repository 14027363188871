import React from 'react';

import { GameBoard, GameItem } from '../../components';
import { useCurrentPlayer } from '../../state';
import { Biohazard, biohazardImages } from './Biohazard';
import { Lock, lockImages } from './Lock';
import { Arcade, arcadeImages } from './Arcade';
import { Microscope, microscopeImages } from './Microscope';
import { Loading } from '../../Loading';
import { unicelledImages, UnicelledOrganisms } from './UnicelledOrganisms';

import style from './Level4.module.css';

import ally from './assets/l4Ally.png';
import allyLights from './assets/l4AllyLights.png';
import olddog from './assets/l4OldDog.png';
import olddogLights from './assets/l4OldDogLights.png';
import article from './assets/article.png';
import dnaItem from './assets/dnaItem.png';
import dnaItem2 from './assets/dnaItem2.png';
import portrait from './assets/portrait.png';
import office from './assets/office.png';

export const level4Images = [
  ally,
  allyLights,
  olddog,
  olddogLights,
  article,
  dnaItem,
  dnaItem2,
  portrait,
  office,
  ...arcadeImages,
  ...biohazardImages,
  ...lockImages,
  ...microscopeImages,
  ...unicelledImages
];

const hintsDnaOldDog = [
  'This is a complex puzzle. You will need two halves of a DNA drawing and two paintings to solve it. Each of the two can be found in both detectives\' rooms.',
  'First, look at the DNA drawing closely. The strands of DNA seem to form the path that you should follow. But where to start? Communicate with Ally, and you will find the beginning.',
  'Follow the path in the DNA drawing from a blue end and decipher each two-letter set. Collaborate with Ally.',
  'When you reach the second side of a DNA strand, don\'t forget about the order of the letters.',
  'The solution is H-A-N-D-O-V-E-R-B-O-O-K (hand over book).',
];

const hintsDnaAlly = [
  'This is a complex puzzle. You will need two halves of a DNA drawing and two paintings to solve it. Each of the two can be found in both detectives\' rooms.',
  'First, look at the DNA drawing closely. The strands of DNA seem to form the path that you should follow. But where to start? Communicate with Old Dog, and you will find the beginning.',
  'Follow the path in the DNA drawing from a blue end and decipher each two-letter set. Collaborate with Old Dog.',
  'When you reach the second side of a DNA strand, don\'t forget about the order of the letters.',
  'The solution is H-A-N-D-O-V-E-R-B-O-O-K (hand over book).',
];

const hintsPortraitOldDog = [
  'This is a part of a complex puzzle. You will need to solve the DNA drawing puzzle first.',
  'Look at the painting closely. Do you see any artwork elements that correspond with a DNA drawing puzzle solution?',
  'Look closely at the hand of the man in the painting. Can you find any geometrical shape there?',
  'The solution is the pentagon.'
];

const hintsOfficeAlly = [
  'This is a part of a complex puzzle. You will need to solve the DNA drawing puzzle first.',
  'Look at the painting closely. Do you see any artwork elements that correspond with a DNA drawing puzzle solution?',
  'Look closely at the book in the painting. Can you find any geometrical shape there?',
  'The solution is a reversed T letter.'
];

const hintsArticleAlly = [
  'You will need a scientific paper in Ally’s room and a microscope in Old Dog’s room to solve this puzzle.',
  'Communicate with Old Dog and describe the images you see in the scientific paper to him. Maybe he can see some microorganisms under the microscope? ',
  'The scientific paper in Ally’s room will also help you find the correct order in this puzzle. Look at the numbers under the images.',
  'The order is first - blue, second - green, third - red, fourth - yellow.'
];

export const Level4 = () => {
  const [{ isOldDog, loading }] = useCurrentPlayer();
  const backgroundImage = isOldDog ? olddog : ally;

  if (loading) {
    return <Loading />;
  }

  return (
    <GameBoard backgroundImage={backgroundImage}>
      <Biohazard />
      <Lock />
      <Arcade />
      <UnicelledOrganisms />
      {isOldDog ? (
        <>
          <Microscope />
          <GameItem
            name="l4DnaItemOldDog"
            imageSrc={dnaItem2}
            hints={hintsDnaOldDog}
            canvas={true}
            canvasColor="#ff0000"
            iconStyle={{
              top: '29.7vw',
              left: '55.7vw',
              width: '14vw',
              height: '9vw'
            }}
          />
          <GameItem
            name="l4Portrait"
            imageSrc={portrait}
            hints={hintsPortraitOldDog}
            canvas={true}
            canvasColor="#ff0000"
            iconStyle={{
              top: '2.5vw',
              left: '74vw',
              width: '18vw',
              height: '15vw'
            }}
          />
          <img src={olddogLights} alt="Old Dog Lights" className={style.lights} />
        </>
      ) : (
        <>
          <GameItem
            name="l4Article"
            imageSrc={article}
            hints={hintsArticleAlly}
            canvas={true}
            canvasColor="#ff0000"
            iconStyle={{
              top: '12.1vw',
              left: '19.7vw',
              width: '15vw',
              height: '14vw'
            }}
          />
          <GameItem
            name="l4Office"
            imageSrc={office}
            hints={hintsOfficeAlly}
            canvas={true}
            canvasColor="#ff0000"
            iconStyle={{
              top: '0vw',
              left: '31vw',
              width: '15vw',
              height: '13vw'
            }}
          />
          <GameItem
            name="l4DnaItemAlly"
            imageSrc={dnaItem}
            hints={hintsDnaAlly}
            canvas={true}
            canvasColor="#ff0000"
            iconStyle={{
              top: '21.8vw',
              left: '33.5vw',
              width: '15vw',
              height: '9vw'
            }}
          />
          <img src={allyLights} alt="Ally Lights" className={style.lights} />
        </>
      )}
    </GameBoard>
  );
};
