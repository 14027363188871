import { useCallback } from 'react';
import { db, useFirestoreQuery } from '../../../firebase';
import { useAccessCode } from '../../../state';
import { sounds } from '../../../components';

export const TURN_LIMIT = 3;

interface ZebrafishPayload {
  solved: boolean;
  hotValue: number;
  coldValue: number;
}

export const initialState: ZebrafishPayload = {
  solved: false,
  hotValue: 0,
  coldValue: 0
};

type ZebrafishResponse = [ZebrafishPayload, {
  turn: (direction: 'left' | 'right', tap: 'cold' | 'hot') => void;
}];

export function useZebrafishState(): ZebrafishResponse {
  const [{ code }] = useAccessCode();
  const stateDoc = db.collection('game')
    .doc(code)
    .collection('l5Zebrafish')
    .doc('state');
  const { data } = useFirestoreQuery<ZebrafishPayload>(stateDoc);
  const state = { ...initialState, ...data };

  const turn = useCallback((direction: 'left' | 'right', tap: 'cold' | 'hot') => {
    if (state.solved) {
      sounds.error();
      return;
    }

    sounds.waterTurn();
    sounds.waterFlow();

    if (tap === 'cold') {
      const newValue = direction === 'left' ? state.coldValue - 1 : state.coldValue + 1;
      if (Math.abs(newValue) > TURN_LIMIT || newValue < 0) {
        sounds.error();
        return;
      }
      stateDoc.set({ coldValue: newValue }, { merge: true });
    } else {
      const newValue = direction === 'left' ? state.hotValue - 1 : state.hotValue + 1;
      if (Math.abs(newValue) > TURN_LIMIT || newValue < 0) {
        sounds.error();
        return;
      }
      stateDoc.set({ hotValue: newValue }, { merge: true });
    }
  }, [state, stateDoc]);

  return [state, { turn }];
}
