import React, { useCallback } from 'react';

import { CustomDragLayer } from './CustomDragLayer';
import { VialDropzone } from './SlotDropzone';
import { Vial, vialImages } from './Vial';
import { SlotDisplay, slotDisplayImages } from './SlotDisplay';
import { ResetButton, resetButtonImages } from './ResetButton';
import { useVialsState } from './vialsState';
import { GameItem } from '../../../components';

import style from './VialMachine.module.css';

import background from './assets/vmBg.png';
import tableFront from './assets/vmTableFront.png';

export const vialMachineImages = [
  background,
  tableFront,
  ...resetButtonImages,
  ...slotDisplayImages,
  ...vialImages
];

const hints = [
  'This is a part of a complex puzzle. To solve it, you will need a poster of flags of the world, a periodic table, and three wheels in Old Dog\'s room, and a device with funnels and boxes filled with powders in Ally\'s room.',
  'The flags above the funnels must mean something. The numbers on the screens too. ',
  'These boxes with powders and numbers…maybe Old Dog can find some numbers that will give you an answer? He should look at the periodic table.',
  'Oh wow, you now see some icons on the screen. Describe them to Old Dog. Maybe he has seen them somewhere? ',
  'But wait a minute, which symbol should go to which wheel? Look closely at the colors of funnels and the pipes.',
  'The solution is blue - magnifying glass, green - 3 neck round bottom flask, red - glove.'
];

export const VialMachine = () => {
  const [, { putInSlot }] = useVialsState();

  const onDrop = useCallback((vialNumber: string, slot: string) => {
    putInSlot(vialNumber, slot);
  }, [putInSlot]);

  return (
    <GameItem
      name="l3VialMachine"
      imageSrc={background}
      hints={hints}
      iconStyle={{
        top: '28vw',
        left: '4vw',
        width: '35.5vw',
        height: '28vw'
      }}
    >
      <CustomDragLayer />
      <VialDropzone slot="Poland" />
      <VialDropzone slot="Ireland" />
      <VialDropzone slot="Nepal" />
      <Vial vialNumber="10" onDrop={onDrop} />
      <Vial vialNumber="13" onDrop={onDrop} />
      <Vial vialNumber="15" onDrop={onDrop} />
      <Vial vialNumber="16" onDrop={onDrop} />
      <Vial vialNumber="25" onDrop={onDrop} />
      <Vial vialNumber="46" onDrop={onDrop} />
      <Vial vialNumber="53" onDrop={onDrop} />
      <Vial vialNumber="57" onDrop={onDrop} />
      <Vial vialNumber="60" onDrop={onDrop} />
      <Vial vialNumber="75" onDrop={onDrop} />
      <Vial vialNumber="83" onDrop={onDrop} />
      <Vial vialNumber="08" onDrop={onDrop} />
      <SlotDisplay slot="Poland" />
      <SlotDisplay slot="Ireland" />
      <SlotDisplay slot="Nepal" />
      <ResetButton slot="Poland" />
      <ResetButton slot="Ireland" />
      <ResetButton slot="Nepal" />
      <img src={tableFront} alt="Table front" className={style.tableFront} />
    </GameItem>
  );
};
