import React, { useEffect, useState } from 'react';
import { useStoreActions } from 'easy-peasy';

import { GameBoard, GameItem, GameLock, sounds } from '../../components';
import { useCurrentPlayer, useGame } from '../../state';
import { PhTable, phTableImages } from './PhTable';
import { scalesImages, ScalesTable } from './Scales';
import { synthesisImages, SynthesisTable } from './Synthesis';
import { Equipment, equipmentImages } from './Equipment';
import { VialMachine, vialMachineImages } from './VialMachine';
import { Discs, discsImages } from './Discs';
import { Loading } from '../../Loading';
import { useScaleState } from './Scales/scaleState';
import { useSynthesisState } from './Synthesis/synthesisState';
import { useEquipmentState } from './Equipment/equipmentState';

import style from './Level3.module.css';

import lab from './assets/lab.png';

import labLightDisc from './assets/lab_light_disc.png';
import labLightFire1 from './assets/lab_light_fire_1.png';
import labLightFire2 from './assets/lab_light_fire_2.png';
import labLightRoom from './assets/lab_light_room.png';
import labLightScales from './assets/lab_light_scales.png';
import labSeven from './assets/lab_seven.png';

import storage from './assets/storage.png';
import storageCabinetDone from './assets/storage_cabinet_done.png';
import storageCabinetOpen from './assets/storage_cabinet_open.png';
import storageLightBottles from './assets/storage_light_bottles.png';
import storageLightRoom from './assets/storage_light_room.png';
import storageLightScales from './assets/storage_light_scales.png';
import storageLightVialMachine from './assets/storage_light_vialmachine.png';

import ph from './assets/ph.png';
import synthesisNote from './assets/synthesis.png';
import equipmentNote from './assets/note.png';
import lock from './assets/lock.png';
import flags from './assets/flags.png';

export const level3Images = [
  lab,
  labLightDisc,
  labLightFire1,
  labLightFire2,
  labLightRoom,
  labLightScales,
  labSeven,
  storage,
  storageCabinetDone,
  storageCabinetOpen,
  storageLightBottles,
  storageLightRoom,
  storageLightScales,
  storageLightVialMachine,
  ph,
  synthesisNote,
  equipmentNote,
  lock,
  flags,
  ...discsImages,
  ...equipmentImages,
  ...phTableImages,
  ...scalesImages,
  ...synthesisImages,
  ...vialMachineImages
];

const lockSolutions = [
  { combination: '3478', correct: true }
];

const hintsNoteOnTheWallOldDog = [
  'To solve this puzzle, you need to solve the puzzle with scales first.',
  'The puzzle requires both the note in Old Dog’s room and a cabinet with lab equipment in Ally’s room.',
  'Read carefully the instruction written on a piece of paper on the wall in Old Dog’s room. Then, communicating with Ally, decide on the position of each piece of laboratory equipment on the shelf. ',
  'The correct order is 1-cylinder, 2-beaker, 3-mortar with a pestle, 4- flask, 5- funnel, 6-test tube, which gives a final answer: 3.'
];

const hintsPhScaleOldDog = [
  'To solve this puzzle, you need the pH scale in Old Dog’s room and bottles filled with various liquids in Ally’s room.',
  'Is there any critical information on the pH scale? For example, which pH is better, and what is its color? Communicate it to Ally.',
  'The answer is 8.'
];

const hintsFlagsAndPeriodicTableOldDog = [
  'This is a part of a complex puzzle. To solve it, you will need a poster of flags of the world, a periodic table, and three wheels in Old Dog\'s room, and a device with funnels and boxes filled with powders in Ally\'s room.',
  'Flags of the world…have you seen them somewhere? Definitely no…but maybe Ally has?',
  'POLaNd, IReLaNd, and NePAl and periodic table? There must be a connection.',
  'You can find P i.e. phosphorus in the periodic table, but what number does it have? Oh, number 15. Let\'s now see if Ally has a powder with a similar number.',
  'The solution is P-15, O-8, La-57, Nd-60; I-53, Re-75, La-57, Nd-60; Ne-10, P-15, Al-13.'
];

const hintsLock = [
  'To unlock the padlock, you need to solve all puzzles in this room. They will all require cooperation.',
  'The mathematical symbols between the number will guide you where each puzzle\'s solution should go.',
  'The solution is 3478.'
];

const hintsNoteOnTheWallAlly = [
  'To solve this puzzle, you will need a note on the Super-brain catalyst in Ally\'s room and probes in various colors in Old Dog\'s room.',
  'The names of the ingredients you should add to create the Super-brain catalyst are drawn all over with markers. This complicates things…or does it not? Ask Old Dog what kind of probes he has in his room.',
  'What can the last ingredient be? Which color can turn blue into green? If you don\'t know, search the internet.',
  'You add purple, then two times blue, and finally yellow. This gives number 7.'
];

export const Level3 = () => {
  const [{ isOldDog, loading }, { changeLevel }] = useCurrentPlayer();
  const [{ solved: isCabinetOpen }] = useScaleState();
  const [{ data }] = useGame();
  const [{ solved: synthesisSolved }] = useSynthesisState();
  const [{ solved: cabinetSolved }] = useEquipmentState();

  const [showCabinet, setShowCabinet] = useState(false);
  const setModalVisible = useStoreActions((state: any) => state.app.setModalOpen);
  const backgroundImage = isOldDog ? lab : storage;
  const levelCompletedByOtherPlayer = !!data?.level3End;

  useEffect(() => {
    if (isCabinetOpen && !isOldDog) {
      setTimeout(() => {
        setModalVisible(false);
        sounds.magic();
        setShowCabinet(true);
      }, 1000);
    }
  }, [isCabinetOpen, isOldDog, setModalVisible]);

  if (loading) {
    return <Loading />;
  }

  return (
    <GameBoard backgroundImage={backgroundImage}>
      {isOldDog ? (
        <>
          <GameItem
            iconStyle={{ left: '29vw', top: '6vw', width: '17vw', height: '8vw' }}
            imageSrc={ph}
            canvas={true}
            canvasColor="#ff0000"
            name="l3PH"
            hints={hintsPhScaleOldDog}
          />
          <GameItem
            iconStyle={{ left: '7vw', top: '4vw', width: '14vw', height: '11vw' }}
            imageSrc={equipmentNote}
            canvas={true}
            canvasColor="#ff0000"
            name="l3EquipmentNote"
            hints={hintsNoteOnTheWallOldDog}
          />
          <GameItem
            iconStyle={{ left: '68vw', top: '8vw', width: '12vw', height: '11.5vw' }}
            imageSrc={flags}
            canvas={true}
            canvasColor="#ff0000"
            name="l3Flags"
            hints={hintsFlagsAndPeriodicTableOldDog}
          />
          <SynthesisTable />
          <Discs />
          <img src={labLightDisc} alt="Light Disc" className={style.light} />
          <img src={labLightRoom} alt="Light Room" className={style.light} />
          <img src={labLightScales} alt="Light Scales" className={style.light} />
          <img src={labLightFire1} alt="Light Fire 1" className={style.lightFire1} />
          <img src={labLightFire2} alt="Light Fire 2" className={style.lightFire2} />
          <img src={labSeven} alt="Seven" className={style.seven} style={{ opacity: synthesisSolved ? 1 : 0 }} />
        </>
      ) : (
        <>
          <img src={storageCabinetOpen} alt="Open cabinet" className={style.openCabinet} style={{ opacity: showCabinet ? 1 : 0 }} />
          <img src={storageCabinetDone} alt="Done cabinet" className={style.openCabinetDone} style={{ opacity: cabinetSolved ? 1 : 0 }} />
          <GameItem
            iconStyle={{ left: '29vw', top: '14vw', width: '14vw', height: '10vw' }}
            imageSrc={synthesisNote}
            canvas={true}
            canvasColor="#ff0000"
            name="l3SynthesisNote"
            hints={hintsNoteOnTheWallAlly}
          />
          <PhTable />
          <Equipment />
          <VialMachine />
          <img src={storageLightBottles} alt="Light Bottles" className={style.light} />
          <img src={storageLightRoom} alt="Light Room" className={style.light} />
          <img src={storageLightScales} alt="Light Scales" className={style.light} />
          <img src={storageLightVialMachine} alt="Light Vial Machine" className={style.light} />
        </>
      )}
      <ScalesTable />
      <GameLock
        type="numbers"
        iconStyle={isOldDog
          ? { left: '79.7vw', top: '7vw', width: '15vw', height: '21vw' }
          : { left: '42.7vw', top: '4vw', width: '17vw', height: '20vw' }}
        imageSrc={lock}
        leftStart={38.4}
        leftStep={6.3}
        name="l3Lock"
        shortDescriptionPosition={isOldDog ? 'bottom' : 'top'}
        numberContainerStyle={{ top: '22vw' }}
        solutions={lockSolutions}
        hints={hintsLock}
        submitStyle={{
          position: 'absolute',
          right: '15vw',
          top: '32vw'
        }}
        onSolved={() => {
          sounds.success2();
          setTimeout(() => changeLevel('4-waiting'), 500);
        }}
        solution="3478"
        showSolution={levelCompletedByOtherPlayer}
      />
    </GameBoard>
  );
};
