/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useCallback } from 'react';
import { Input, Button, Form } from 'antd';
import { TwitterOutlined, FacebookOutlined, InstagramOutlined } from '@ant-design/icons';
import axios from 'axios';
import { validate } from 'email-validator';
import { GameBoard } from '../../components';
import { useGame } from '../../state';

import style from './Level6.module.css';

import unboxing from './assets/unboxing.png';
import final from './assets/final.png';

export const level6Images = [
  unboxing,
  final
];

const NEWSLETTER_URL = 'https://us-central1-eleven-puzzles-website.cloudfunctions.net/newsletter';

/**
 * @param {number} time in seconds * 1000
 */
export function formatTime(time: number) {
  const timer = Math.max(Math.floor(time / 1000), 0);

  // timer is in seconds
  const s = timer % 60;
  const m = ((timer - s) / 60) % 60;
  const h = (timer - s - m * 60) / 3600;

  return `${h}:${m < 10 ? '0' : ''}${m}:${s < 10 ? '0' : ''}${s}`;
}

export const Level6Waiting = () => (
  <>
    <p>Ally finds papers in the safe. She reads them.</p>
    <p>
      <strong>Ally:</strong> Two brothers… Adopted… And their real father… Old Dog? You won't believe this. <br />
      <strong>Old Dog:</strong> What? <br />
      <strong>Ally:</strong> We know their father.
    </p>
    <p>They exit the lab. The police cars arrive. Ally and Old Dog hop into the car and drive away.</p>
    <p>
      <strong>Ally:</strong> Are we going where I think we're going? <br />
      <strong>Old Dog:</strong> Yes. Let's pay daddy a visit.
    </p>
    <p>They drive away into the sunset.</p>
  </>
);

export const Level6 = () => {
  const [{ data }] = useGame();

  // newsletter
  const [email, setEmail] = useState('');
  const [cc0f56a6940e30d95a3a1a7a0_2827db7fbc, setCC] = useState('');
  const [subscribing, setSubscribing] = useState(false);
  const [error, setError] = useState('');
  const [subscribeSuccess, setSubscribeSuccess] = useState(false);

  const totalTime = data?.playTime;
  const totalTimeText = totalTime ? formatTime(totalTime * 1000) : 'Not sure...';

  const onFormSubmit = useCallback(async() => {
    try {
      setError('');
      setSubscribing(true);

      if (!validate(email)) {
        setError('Please provide a valid e-mail');
        setSubscribing(false);
        return;
      }

      const response = await axios.post(NEWSLETTER_URL, {
        b_cc0f56a6940e30d95a3a1a7a0_2827db7fbc: cc0f56a6940e30d95a3a1a7a0_2827db7fbc,
        email,
        tags: ['game', 'parallel-lab']
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.data.status === 'ok') {
        setSubscribing(false);
        setSubscribeSuccess(true);
      } else {
        setError('Something went wrong, please try again later');
      }
      setSubscribing(false);
    } catch (err) {
      setSubscribing(false);
      setError('Something went wrong, please try again later');
      console.error(err);
    }
  }, [email, cc0f56a6940e30d95a3a1a7a0_2827db7fbc]);

  return (
    <GameBoard backgroundImage={final} className={style.finalPaper}>
      <div className={style.parallelLabUncovered}>
        <p>Two detectives followed Cryptic Killer's trail to a secret laboratory hidden underneath an old electronics shop.</p>
        <p>"We've heard a scream and rushed inside. It belonged to a monkey, and the culprit was nowhere to be found. After a thorough search, we discovered a piece of evidence indicating possible whereabouts of the Cryptic Killer." said detective Steve.</p>
        <p>"How long you were inside?"</p>
        <p>"Just long enough to feel that I need a drink. Which, according to my watch, is exactly:</p>
        <p className={style.time}>{totalTimeText}</p>
        <p>Detectives refused to give any further comments and drove off in the direction of Anagram Asylum, a terrifying facility for the criminally insane. </p>
        <p>Could it be their next destination?</p>
      </div>
      <div className={style.otherCases}>
        <h1>Other cases</h1>
        <p>The case "Unboxing the Mind of a Cryptic Killer" tells a story directly preceding today's events. Two detectives, Steve "Old Dog" Kowalski and Allison Abernathy, were trapped by the Cryptic Killer in an old warehouse.</p>
        <p>What exactly happened inside? Only the detectives know.</p>
        <p>Discover more on our <a href="https://elevenpuzzles.com" target="_blank">website</a>.</p>
        <a href="https://elevenpuzzles.com/games/cryptic-killer-unboxing" target="_blank" className={style.unboxingLink}><img src={unboxing} alt="Unboxing the mind of a Cryptic Killer" /></a>
      </div>
      <div className={style.social}>
        <h1>Social</h1>
        <p>Follow and share to help Eleven Puzzles out. We're a small company, and spreading the word means the world to us!</p>
        <div className={style.socialMediaButtons}>
          <Button
            className={style.shareButton}
            href="https://twitter.com/elevenpuzzles"
            type="link"
            size="large"
            target="_blank"
          >
            <TwitterOutlined />
          </Button>
          <Button
            className={style.shareButton}
            href="https://facebook.com/elevenpuzzles"
            type="link"
            size="large"
            target="_blank"
          >
            <FacebookOutlined />
          </Button>
          <Button
            className={style.shareButton}
            href="https://www.instagram.com/elevenpuzzles/"
            type="link"
            size="large"
            target="_blank"
          >
            <InstagramOutlined />
          </Button>
        </div>
        <h1 className={style.newsletterHeader}>Newsletter</h1>
        {subscribeSuccess ? (
          <p>Thank you for subscribing!</p>
        ) : (
          <>
            <p>Get fresh Eleven Puzzles news right in your inbox. Be first to learn about the new case developments!</p>
            <form
              action="https://elevenpuzzles.us7.list-manage.com/subscribe/post?u=cc0f56a6940e30d95a3a1a7a0&amp;id=2827db7fbc"
              method="post"
              target="_blank"
              onSubmit={(ev) => {
                ev.preventDefault();
                onFormSubmit();
              }}
            >
              <Form.Item
                name="EMAIL"
                className={style.nameInput}
              >
                {error ? <div className={style.errorMessage}>{error}</div> : <></>}
                <Input
                  style={{ textAlign: 'center' }}
                  name="EMAIL"
                  autoComplete="off"
                  type="email"
                  placeholder="Type in your e-mail here"
                  onChange={ev => {
                    setEmail(ev.target.value);
                    setError('');
                  }}
                  value={email}
                  disabled={subscribing}
                />
                <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                  <input type="text" name="b_cc0f56a6940e30d95a3a1a7a0_2827db7fbc" tabIndex={-1} value={cc0f56a6940e30d95a3a1a7a0_2827db7fbc} onChange={ev => setCC(ev.target.value)} />
                </div>
              </Form.Item>

              <Button
                type="link"
                size="large"
                htmlType="submit"
                className={style.signUp}
                disabled={subscribing}
              >
                {subscribing ? 'Subscribing...' : 'Sign up'}
              </Button>
            </form>
          </>
        )}
      </div>
    </GameBoard>
  );
};
