import React, { useCallback, useState } from 'react';
import classNames from 'classnames';

import { GameItem, sounds, speech } from '../../../components';
import { useCurrentPlayer, useGame } from '../../../state';
import { Slider, sliderImages } from './Slider';
import { Pipes, pipesImages } from './Pipes';
import { useVentilationState } from './ventilationState';

import style from './Ventilation.module.css';

import animalRoom from './assets/animalRoom.png';
import labRoom from './assets/labRoom.png';

import ventilationCase from './assets/case.png';
import bg from './assets/bg.png';
import cover1 from './assets/cover1.png';
import cover2 from './assets/cover2.png';
import cover3 from './assets/cover3.png';
import gridBlue from './assets/grid_blue.png';
import gridGreen from './assets/grid_green.png';

export const ventilationImages = [
  animalRoom,
  labRoom,
  ventilationCase,
  bg,
  cover1,
  cover2,
  cover3,
  gridBlue,
  gridGreen,
  ...pipesImages,
  ...sliderImages
];

export const Ventilation = () => {
  const [{ isOldDog }] = useCurrentPlayer();
  const [{ gridSolved }] = useVentilationState();
  const [{ data }] = useGame();
  const isBombActivated = data?.bombArmed;
  const [coverStage, setCoverStage] = useState(0);

  const hintsOldDog = [
    'You will need consoles in Old Dog\'s and Ally\'s room to solve this puzzle.',
    isBombActivated ? 'SMASH IT!' : 'You can\'t do anything here until you\'ve tried to open the safe.',
    'Collaborating with Ally, move all objects so that they don\'t cover the red line. You can move the green ones, and Ally can move the red ones.',
    'When you unlock the second puzzle, first, you need to determine the start and end of pipes in the animal room. Ask Ally if she can see the scheme for the laboratory room.',
    'Knowing the positions of start and end, you need to rotate the parts of the pipes to connect the start with the end.',
    'The start is in the top part of the top left square, and the end is on the bottom part of the bottom right square. Make sure to connect the start with the end, and eliminate any leaks. The rest you\'ll have to work out by yourself…'
  ];

  const hintsAlly = [
    'You will need consoles in Old Dog\'s and Ally\'s room to solve this puzzle.',
    isBombActivated ? 'SMASH IT!' : 'You can\'t do anything here until you\'ve tried to open the safe.',
    'Collaborating with Old Dog, move all objects so that they don\'t cover the red line. You can move the red ones, and Old Dog can move the green ones.',
    'When you unlock the second puzzle, first, you need to determine the start and end of pipes in the animal room. Ask Old Dog if he can see the scheme for the animal room.',
    'Knowing the positions of start and end, you need to rotate the parts of the pipes to connect the start with the end.',
    'The start is in the top part of the top left square, and the end is on the bottom part of the bottom right square. Make sure to connect the start with the end, and eliminate any leaks. The rest you\'ll have to work out by yourself…'
  ];

  const cover = (() => {
    if (coverStage < 2) {
      return cover1;
    }
    if (coverStage < 5) {
      return cover2;
    }
    return cover3;
  })();

  const ventilationImage = (() => {
    if (gridSolved) {
      return isOldDog ? labRoom : animalRoom;
    }
    return bg;
  })();

  const coverTremble = coverStage === 1 || coverStage === 2 || coverStage === 4 || coverStage === 5 || coverStage > 6;
  const coverFall = coverStage === 10;
  const coverInvisible = coverStage === 11;

  const onCoverHit = useCallback(() => {
    if (!isBombActivated) {
      speech.say(isOldDog ? 'l5OldDogNoVentilation' : 'l5AllyNoVentilation');
      return;
    }

    if (Math.random() * 2 > 1) {
      sounds.ventilationHit2();
    } else {
      sounds.ventilationHit1();
    }

    if (coverStage === 0 || coverStage === 3 || coverStage === 6) {
      setCoverStage(stage => stage + 1);
      setTimeout(() => setCoverStage(stage => stage + 1), 200);
      setTimeout(() => setCoverStage(stage => stage + 1), 600);
      if (coverStage === 6) {
        setTimeout(() => setCoverStage(10), 650);
        setTimeout(() => sounds.squeek4(0.6), 600);
        setTimeout(() => sounds.coverFall(), 3500);
        setTimeout(() => setCoverStage(11), 3500);
      }
    }
  }, [coverStage, isOldDog, isBombActivated]);

  return (
    <GameItem
      name="l5Ventilation"
      imageSrc={ventilationImage}
      hints={isOldDog ? hintsOldDog : hintsAlly}
      iconStyle={isOldDog ? {
        top: '7vw',
        left: '28vw',
        width: '11vw',
        height: '12vw'
      } : {
        top: '9vw',
        left: '31vw',
        width: '11vw',
        height: '12vw'
      }}
    >
      {gridSolved ? (
        <>
          <Pipes />
        </>
      ) : (
        <>
          <img src={ventilationCase} alt="Case" className={style.case} />
          <img src={isOldDog ? gridBlue : gridGreen} alt="Grid" className={style.gridBackground} />
          <div className={classNames(coverFall && style.coverFall, style.coverWrapper, coverInvisible && style.coverInvisible)}>
            <img src={cover} alt="Cover" className={classNames(style.cover, coverTremble && style.tremble)} onClick={() => onCoverHit()} />
          </div>
          <Slider />
        </>
      )}
    </GameItem>
  );
};
