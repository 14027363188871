import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useStoreActions } from 'easy-peasy';

import { GameBoard, GameItem, speech, sounds } from '../../components';
import { useCurrentPlayer, useTimer, useGame } from '../../state';
import { useComputerState } from './Computer/computerState';
import { useBlindMiceState } from './BlindMice/miceState';
import { useVentilationState } from './Ventilation/ventilationState';
import { useLevel5State } from './level5State';
import { BlindMice, blindMiceImages } from './BlindMice';
import { Monkey, monkeyImages } from './Monkey';
import { Mokia, mokiaImages } from './Mokia';
import { Parrot, parrotImages } from './Parrot';
import { Snake, snakeImages } from './Snake';
import { FoodWeight, foodWeightImages } from './FoodWeight';
import { Control, Tank, zebrafishImages } from './Zebrafish';
import { Computer, computerImages } from './Computer';
import { Ventilation, ventilationImages } from './Ventilation';
import { Safe, safeImages } from './Safe';
import { Door, doorImages } from './Door';
import { Loading } from '../../Loading';

import painting from './assets/painting.png';
import bgAlly from './assets/bgAlly.png';
import bgOldDog from './assets/bgOldDog.png';
import pupa from './assets/pupa.png';
import clock from './assets/clock.png';
import smokeVent from './assets/smokeSmall.png';
import smoke from './assets/smoke.png';

// old dog
import lightPicture from './assets/light_picture.png';
import lightMonitor2 from './assets/light_monitor2.png';
import lightMobile from './assets/light_mobile.png';
import lightBulb2 from './assets/light_bulb2.png';
import lightSnake from './assets/light_snake.png';
import lightMouse from './assets/light_mouse.png';
import lightMonitor3 from './assets/light_monitor_2.png';

// ally
import lightRed from './assets/light_bulb.png';
import lightClock from './assets/light_clock.png';
import lightFruits from './assets/light_fruits.png';
import lightFish from './assets/light_fishtank.png';
import lightMonitor from './assets/light_monitor.png';

import style from './Level5.module.css';

export const level5Images = [
  painting,
  bgAlly,
  bgOldDog,
  pupa,
  clock,
  smokeVent,
  smoke,
  lightPicture,
  lightMonitor2,
  lightMobile,
  lightBulb2,
  lightSnake,
  lightMouse,
  lightMonitor3,
  lightRed,
  lightClock,
  lightFruits,
  lightFish,
  lightMonitor,
  ...blindMiceImages,
  ...computerImages,
  ...doorImages,
  ...foodWeightImages,
  ...mokiaImages,
  ...monkeyImages,
  ...parrotImages,
  ...safeImages,
  ...snakeImages,
  ...ventilationImages,
  ...zebrafishImages
];

const hintsPupaOldDog = [
  'To solve this puzzle, you will need a poster with a Drosophila melanogaster life cycle in Old Dog\'s room and a broken clock in Ally\'s room.',
  'Let Ally describe a broken clock to you. Do you see any connection?',
  'Look closely at the life cycle of a Drosophila melanogaster. What is the position of a pupa in the poster? What number does it correspond with on Ally\'s clock?',
  'The solution is 9.'
];

const hintsClockAlly = [
  'To solve this puzzle, you will need a broken clock in Ally\'s room and a poster with a Drosophila melanogaster life cycle in Old Dog\'s room.',
  'Let Old Dog describe  Drosophila melanogaster life cycle to you. Do you see any connection?',
  'Old Dog should tell you the position of a pupa in the poster? What number does it correspond with on your clock?',
  'The solution is 9.'
];

export const Level5 = () => {
  const [{ mode }] = useTimer();
  const isBombMode = mode === 'bomb' || mode === 'bomb2';
  const [{ isOldDog, loading }] = useCurrentPlayer();
  const [{ playingSoundName, playingSoundDestination }] = useComputerState();
  const [, { setBombDefused }] = useGame();
  const [{ solved }] = useBlindMiceState();
  const [safeVisible, setSafeVisible] = useState(false);
  const [smokeVentsVisible, setSmokeVentsVisible] = useState(false);
  const [smokeVisible, setSmokeVisible] = useState(false);
  const [{ pipesSolved }] = useVentilationState();
  const [{ documentsCollected }] = useLevel5State();
  const backgroundImage = isOldDog ? bgOldDog : bgAlly;
  const setModalVisible = useStoreActions((state: any) => state.app.setModalOpen);

  useEffect(() => {
    if (solved && !safeVisible && !isOldDog) {
      setTimeout(() => setModalVisible(false), 2000);
      setTimeout(() => {
        sounds.paintingSlideOpen();
        setSafeVisible(true);
      }, 3000);
    }
  }, [solved, safeVisible, isOldDog, setModalVisible]);

  useEffect(() => {
    if (
      (isOldDog && playingSoundDestination === 'Room 1') ||
      (!isOldDog && playingSoundDestination === 'Room 2')
    ) {
      if (playingSoundName === 'gustav_funny.mp3') {
        speech.say('l5GustavFunny');
      } else if (playingSoundName === 'test.mp3') {
        speech.say('l5GustavTest');
      } else if (playingSoundName === 'gustav_name.mp3') {
        speech.say('l5GustavName');
      } else if (playingSoundName === 'gustav_warning.mp3') {
        speech.say('l5GustavWarning');
      } else if (playingSoundName === 'gustav_shadow.mp3') {
        speech.say('l5GustavShadow');
      } else if (playingSoundName === 'time_to_go.mp3') {
        speech.say('l5GustavTimeToGO');
      }
    }
  }, [playingSoundName, playingSoundDestination, isOldDog]);

  useEffect(() => {
    if (!isBombMode) return;
    setTimeout(() => setModalVisible(false), 2000);
    setTimeout(() => {
      speech.say('l5Alert');
    }, 3000);
    setTimeout(() => {
      speech.say(isOldDog ? 'l5OldDogAlert' : 'l5AllyAlert');
    }, 10000);
    setTimeout(() => {
      setSmokeVentsVisible(true);
      setTimeout(() => {
        setSmokeVentsVisible(false);
      }, 15600);
      setSmokeVisible(true);
      sounds.hiss(0.3);
    }, 2500);
  }, [isBombMode, isOldDog, setModalVisible]);

  useEffect(() => {
    if (pipesSolved) {
      setTimeout(() => setModalVisible(false), 300);
      sounds.fan();
      speech.say(isOldDog ? 'l5OldDogVentilationFixed' : 'l5AllyVentilationFixed');
      setSmokeVisible(false);
      setBombDefused();
      setTimeout(() => {
        if (isOldDog) {
          speech.say('l5OldDogDoorFinal');
        }
      }, 15000);
    }
  }, [pipesSolved, setModalVisible, isOldDog, setBombDefused]);

  useEffect(() => {
    if (documentsCollected) {
      speech.say(isOldDog ? 'l5OldDogDocumentsCollected' : 'l5AllyDocumentsCollected');
    }
  }, [documentsCollected, isOldDog]);

  if (loading) {
    return <Loading />;
  }

  return (
    <GameBoard backgroundImage={backgroundImage}>
      <img src={smoke} alt="Smoke" className={style.smoke} style={{ opacity: smokeVisible ? 0.8 : 0 }} />
      {smokeVentsVisible ? (
        <>
          <img src={smokeVent} alt="Smoke 1" className={classNames(style.smokeVent, isOldDog && style.smokeVentOldDog, style.smokeVent1)} />
          <img src={smokeVent} alt="Smoke 2" className={classNames(style.smokeVent, isOldDog && style.smokeVentOldDog, style.smokeVent2)} />
          <img src={smokeVent} alt="Smoke 3" className={classNames(style.smokeVent, isOldDog && style.smokeVentOldDog, style.smokeVent3)} />
        </>
      ) : <></>}
      <BlindMice />
      <Mokia />
      <Door />
      {isOldDog ? (
        <>
          <GameItem
            name="l5Pupa"
            imageSrc={pupa}
            canvas={true}
            canvasColor="#ff0000"
            hints={hintsPupaOldDog}
            iconStyle={{
              top: '0vw',
              left: '5vw',
              width: '13vw',
              height: '19vw'
            }}
          />
          <Monkey />
          <Snake />
          <Control />
          <Computer />
          <img src={lightPicture} className={classNames(style.lightOldDog)} alt="Light 1" />
          <img src={lightMonitor2} className={classNames(style.lightOldDog)} alt="Light 2" />
          <img src={lightMobile} className={classNames(style.lightOldDog)} alt="Light 3" />
          <img src={lightSnake} className={classNames(style.lightOldDog)} alt="Light 5" />
          <img src={lightBulb2} className={classNames(style.lightOldDog)} alt="Light 4" />
          <img src={lightMouse} className={classNames(style.lightOldDog)} alt="Light 6" />
          <img src={lightMonitor3} className={classNames(style.lightOldDog)} alt="Light 7" />
        </>
      ) : (
        <>
          <Parrot />
          <FoodWeight />
          <Tank />
          <Safe />
          <GameItem
            name="l5Clock"
            imageSrc={clock}
            canvas={true}
            canvasColor="#ff0000"
            hints={hintsClockAlly}
            iconStyle={{
              top: '3vw',
              left: '80vw',
              width: '12vw',
              height: '11vw'
            }}
          />
          <img src={painting} className={classNames(style.painting, safeVisible && style.paintingOpen)} alt="Painting" />
          <img src={lightRed} className={classNames(style.lightRed)} alt="Red light" />
          <img src={lightFruits} className={classNames(style.lightFruits)} alt="Light Fruits" />
          <img src={lightClock} className={classNames(style.lightClock)} alt="Clock" />
          <img src={lightFish} className={classNames(style.lightFish)} alt="Fish" />
          <img src={lightMonitor} className={classNames(style.lightMonitor)} alt="Monitor" />
        </>
      )}
      <Ventilation />
    </GameBoard>
  );
};
