import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { CustomDragLayer } from './CustomDragLayer';
import { ScaleDropzone, scaleDropzoneImages } from './ScaleDropzone';
import { PlaceholderDropzone } from './PlaceholderDropzone';
import { Weight, WEIGHTS_OLD_DOG, WEIGHTS_ALLY, weightImages } from './Weight';
import { GameItem } from '../../../components';
import { useCurrentPlayer } from '../../../state';
import { useScaleState, calculateTotalWeight } from './scaleState';
import { Screen, screenImages } from './Screen';

import style from './Scales.module.css';

import bgLeft from './assets/bgLeft.png';
import bgRight from './assets/bgRight.png';
import dragLeft from './assets/dragLeft.png';
import dragRight from './assets/dragRight.png';

export const scalesImages = [
  bgLeft,
  bgRight,
  dragLeft,
  dragRight,
  ...scaleDropzoneImages,
  ...screenImages,
  ...weightImages
];

const OFFSET_FACTOR = 0.5;

const hintsOldDog = [
  'To solve this puzzle, you need to cooperate with Ally to balance the scales.',
  'Place weights on the scale to balance it. However, do not put too many weights on the scales, as you will need to start over.',
  'Try using the heavier weights as well. This should help you solve the puzzle.',
  'There are multiple correct sequences. One of them is Old Dog 6 and Ally 8, Old Dog 9, Ally 2 and 3.'
];

const hintsAlly = [
  'To solve this puzzle, you need to cooperate with Old Dog to balance the scales.',
  'Place weights on the scale to balance it. However, do not put too many weights on the scales, as you will need to start over.',
  'Try using the heavier weights as well. This should help you solve the puzzle.',
  'There are multiple correct sequences. One of them is Old Dog 6 and Ally 8, Old Dog 9, Ally 2 and 3.'
];

export const ScalesTable = () => {
  const [{ isOldDog }] = useCurrentPlayer();
  const [isDragging, setIsDragging] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [tutorialVisible, setTutorialVisible] = useState(false);
  const weights = isOldDog ? WEIGHTS_OLD_DOG : WEIGHTS_ALLY;
  const [{ weightsOnScale }, { addWeight, isOnScale, removeWeight }] = useScaleState();
  const totalWeight = calculateTotalWeight(weightsOnScale);
  const scaleOffset = totalWeight * OFFSET_FACTOR * (isOldDog ? -1 : 1);

  const onDrop = useCallback((weight: Weight, destination: 'scale' | 'placeholder') => {
    if (destination === 'scale') {
      addWeight(weight);
    } else if (destination === 'placeholder') {
      removeWeight(weight);
    }
  }, [addWeight, removeWeight]);

  useEffect(() => {
    if (!modalOpen) return;
    if (weightsOnScale.length > 0) {
      setTutorialVisible(false);
    } else {
      const timeout = setTimeout(() => setTutorialVisible(true), 5000);
      // eslint-disable-next-line consistent-return
      return () => clearTimeout(timeout);
    }
  }, [modalOpen, weightsOnScale]);

  return (
    <GameItem
      imageSrc={isOldDog ? bgLeft : bgRight}
      name={isOldDog ? 'l3ScalesTableLeft' : 'l3ScalesTableRight'}
      hints={isOldDog ? hintsOldDog : hintsAlly}
      onOpen={() => setModalOpen(true)}
      onClose={() => setModalOpen(false)}
      iconStyle={isOldDog ? {
        top: '7vw',
        left: '47vw',
        width: '20.5vw',
        height: '19vw'
      } : {
        top: '5vw',
        left: '1vw',
        width: '29vw',
        height: '23vw'
      }}
    >
      <img
        src={isOldDog ? dragLeft : dragRight}
        className={classNames(isOldDog ? style.dragTutorialLeft : style.dragTutorialRight, 'animate__animated animate__pulse animate__infinite')}
        alt="Drag"
        onDragStart={ev => ev.preventDefault()}
        style={{ opacity: tutorialVisible ? 1 : 0 }}
      />
      <ScaleDropzone offset={scaleOffset} isDragging={isDragging} />
      <PlaceholderDropzone isDragging={isDragging} />
      <CustomDragLayer />
      <Screen />
      {weights.map(weight => (
        <Weight
          weight={weight}
          onBegin={() => setIsDragging(true)}
          onEnd={() => setIsDragging(false)}
          onDrop={onDrop}
          isOnScale={isOnScale(weight)}
          offset={scaleOffset}
        />
      ))}
    </GameItem>
  );
};
