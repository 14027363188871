import React from 'react';
import { GameItem } from '../../../components';
import { useCurrentPlayer } from '../../../state';
import { Interface, interfaceImages } from './Interface';

import cipher from './assets/cipher.png';
import mokia from './assets/mokia.png';

export const mokiaImages = [
  cipher,
  mokia,
  ...interfaceImages
];

const hintsOldDog = [
  'To solve the puzzle, you will need a mobile phone in Old Dog’s room and a code with pigs in Ally’s room.',
  'Look closely at the keyboard on the mobile phone. There are some dotted lines between buttons. I wonder if similar lines can be found in Ally’s room?',
  'Ask Ally to describe the code on the wall with pigs. The position of dotted lines and the number of pigs matter.',
  'The dotted lines say which button you should press, and a number of pigs say how many times. It will give you several letters, which can form a word when you do it correctly.',
  'The solution is SEVEN.'
];

const hintsAlly = [
  'You will need a code with pigs on the wall in Ally’s room and a mobile phone in Old Dog’s room to solve the puzzle.',
  'Hmm, there are pigs and dotted lines on the wall. Maybe Old Dog can find some dotted lines in his part of a puzzle.',
  'Describe carefully what you see - especially the position of the dotted lines in the square and the number of pigs to Old Dog. He should know what to do with this information.',
  'The solution is SEVEN.'
];

export const Mokia = () => {
  const [{ isOldDog }] = useCurrentPlayer();

  return (
    <GameItem
      name={`l5Mokia${isOldDog ? 'OldDog' : 'Ally'}`}
      imageSrc={isOldDog ? mokia : cipher}
      canvas={!isOldDog}
      hints={isOldDog ? hintsOldDog : hintsAlly}
      iconStyle={isOldDog ? {
        top: '36vw',
        left: '18vw',
        width: '19vw',
        height: '12vw'
      } : {
        top: '14vw',
        left: '78vw',
        width: '16vw',
        height: '7vw'
      }}
    >
      {isOldDog ? <Interface /> : <></>}
    </GameItem>
  );
};
