import React, { useState, useEffect, useCallback } from 'react';

import { GameItem, CartoonButton } from '../../../components';
import { useCurrentPlayer } from '../../../state';
import { useBiohazard } from './biohazardState';

import style from './Biohazard.module.css';

import bgAlly from './assets/brokenWithNote.png';
import brokenWithNoteLights from './assets/brokenWithNoteLights.png';
import bgOldDog from './assets/workingBrokenKnob.png';
import reflections from './assets/reflections.png';
import labels from './assets/labels.png';

import button1Normal from './assets/button1.png';
import button1Pressed from './assets/button1Pressed.png';
import button2Normal from './assets/button2.png';
import button2Pressed from './assets/button2Pressed.png';
import button3Normal from './assets/button3.png';
import button3Pressed from './assets/button3Pressed.png';
import button4Normal from './assets/button4.png';
import button4Pressed from './assets/button4Pressed.png';
import button5Normal from './assets/button5.png';
import button5Pressed from './assets/button5Pressed.png';
import button6Normal from './assets/button6.png';
import button6Pressed from './assets/button6Pressed.png';
import button7Normal from './assets/button7.png';
import button7Pressed from './assets/button7Pressed.png';
import button8Normal from './assets/button8.png';
import button8Pressed from './assets/button8Pressed.png';
import button9Normal from './assets/button9.png';
import button9Pressed from './assets/button9Pressed.png';
import button10Normal from './assets/button10.png';
import button10Pressed from './assets/button10Pressed.png';
import button11Normal from './assets/button11.png';
import button11Pressed from './assets/button11Pressed.png';
import button12Normal from './assets/button12.png';
import button12Pressed from './assets/button12Pressed.png';

import bacteria from './assets/bacteria.png';
import knob from './assets/knob.png';
import arrowNormal from './assets/arrowNormal.png';
import arrowClick from './assets/arrowClick.png';
import arrowHover from './assets/arrowHover.png';
import arrowNormalL from './assets/arrowNormalL.png';
import arrowClickL from './assets/arrowClickL.png';
import arrowHoverL from './assets/arrowHoverL.png';
import triangleOverLine from './assets/triangleOverLine.png';

export const biohazardImages = [
  bgAlly,
  bgOldDog,
  brokenWithNoteLights,
  reflections,
  labels,
  bacteria,
  knob,
  arrowNormal,
  arrowClick,
  arrowHover,
  arrowNormalL,
  arrowClickL,
  arrowHoverL,
  triangleOverLine,
  button1Normal,
  button1Pressed,
  button2Normal,
  button2Pressed,
  button3Normal,
  button3Pressed,
  button4Normal,
  button4Pressed,
  button5Normal,
  button5Pressed,
  button6Normal,
  button6Pressed,
  button7Normal,
  button7Pressed,
  button8Normal,
  button8Pressed,
  button9Normal,
  button9Pressed,
  button10Normal,
  button10Pressed,
  button11Normal,
  button11Pressed,
  button12Normal,
  button12Pressed
];

const CORRECT_BACTERIA_POSITIONS = [
  { top: '13vw', left: '60vw' },
  { top: '13vw', left: '73vw' },
  { top: '20vw', left: '66.5vw' },
  { top: '28vw', left: '66.5vw' },
  { top: '20vw', left: '66.5vw' }
];

const generateRandomBacteriaPosition = () => ({
  top: `${Math.random() * 20 + 10}vw`,
  left: `${Math.random() * 20 + 58}vw`
});

const hintsOldDog = [
  'To solve this puzzle, you need a device with laboratory safety signs in Old Dog’s room and a device without buttons with instructions in Ally’s room.',
  'Ask Ally to read the instructions on how to mutate the bacteria. Do you see any connexion between the elements of the instructions and the buttons?',
  'Push the buttons according to the instruction provided by Ally. If you do it correctly, you will see a bacteria moving. What did the last line of the instructions say?',
  'You need to set a proper current to make bacteria tell you something. Ask Ally to turn the knob slowly to change the current and carefully observe the bacteria.',
  <>The trajectory of bacteria’s movement under a certain current reveals this figure <br /><img src={triangleOverLine} alt="Solution" style={{ width: '5vw' }} /></>
];

const hintsAlly = [
  'To solve this puzzle, you need a device with laboratory safety signs in Old Dog’s room and a device without buttons with instructions in Ally’s room.',
  'Read the instructions on how to mutate the bacteria to Old Dog. Then, he should know what to do with it.',
  'After Old Dog presses the correct buttons, he should see a mutated bacteria. The last line of the instructions says something about the current. Turn the knob carefully to see if anything changes in Old Dog’s room.',
  <>The trajectory of bacteria’s movement under a certain current reveals this figure <br /><img src={triangleOverLine} alt="Solution" style={{ width: '5vw' }} /></>
];

export const Biohazard = () => {
  const [{ isOldDog }] = useCurrentPlayer();
  const [biohazardState, { increaseCurrent, decreaseCurrent, pressButton }] = useBiohazard();
  const [bacteriaPositions, setBacteriaPositions] = useState([generateRandomBacteriaPosition()]);
  const [bacteriaIndex, setBacteriaIndex] = useState(3);
  const { current, bacteriaVisible } = biohazardState;
  const transitionTime = 2000 / (current === 7 ? 8 : 1);
  const bacteriaPosition = bacteriaPositions[bacteriaIndex];

  const isPressed = useCallback((button: number): boolean => {
    return (biohazardState as any)[`button${button}Pressed`];
  }, [biohazardState]);

  useEffect(() => {
    let i = bacteriaIndex;
    const tick = () => {
      if (current === 7) {
        setBacteriaPositions(CORRECT_BACTERIA_POSITIONS);
        setBacteriaIndex((++i) % 5);
      } else {
        setBacteriaIndex(0);
        setBacteriaPositions([generateRandomBacteriaPosition()]);
      }
    };
    tick();
    const interval = setInterval(() => tick(), transitionTime);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [transitionTime, current]);

  return (
    <GameItem
      name={`l4Biohazard${isOldDog ? 'OldDog' : 'Ally'}`}
      description="Strange panel with many buttons"
      imageSrc={isOldDog ? bgOldDog : bgAlly}
      hints={isOldDog ? hintsOldDog : hintsAlly}
      iconStyle={isOldDog ? {
        top: '22vw',
        left: '73vw',
        width: '27vw',
        height: '32vw'
      } : {
        top: '23vw',
        left: '0vw',
        width: '26vw',
        height: '28vw'
      }}
    >
      {isOldDog ? (
        <>
          <CartoonButton src={isPressed(1) ? button1Pressed : button1Normal} hoverSrc={isPressed(1) ? button1Pressed : button1Normal} activeSrc={button1Pressed} onClick={() => pressButton(1)} style={{ width: '5.8vw', top: '10.2vw', left: '23.7vw' }} />
          <CartoonButton src={isPressed(2) ? button2Pressed : button2Normal} hoverSrc={isPressed(2) ? button2Pressed : button2Normal} activeSrc={button2Pressed} onClick={() => pressButton(2)} style={{ width: '5.8vw', top: '10.2vw', left: '29.4vw' }} />
          <CartoonButton src={isPressed(3) ? button3Pressed : button3Normal} hoverSrc={isPressed(3) ? button3Pressed : button3Normal} activeSrc={button3Pressed} onClick={() => pressButton(3)} style={{ width: '5.8vw', top: '10.2vw', left: '35.1vw' }} />
          <CartoonButton src={isPressed(4) ? button4Pressed : button4Normal} hoverSrc={isPressed(4) ? button4Pressed : button4Normal} activeSrc={button4Pressed} onClick={() => pressButton(4)} style={{ width: '5.8vw', top: '10.2vw', left: '40.75vw' }} />
          <CartoonButton src={isPressed(5) ? button5Pressed : button5Normal} hoverSrc={isPressed(5) ? button5Pressed : button5Normal} activeSrc={button5Pressed} onClick={() => pressButton(5)} style={{ width: '5.8vw', top: '18.1vw', left: '23.7vw' }} />
          <CartoonButton src={isPressed(6) ? button6Pressed : button6Normal} hoverSrc={isPressed(6) ? button6Pressed : button6Normal} activeSrc={button6Pressed} onClick={() => pressButton(6)} style={{ width: '5.8vw', top: '18.1vw', left: '29.4vw' }} />
          <CartoonButton src={isPressed(7) ? button7Pressed : button7Normal} hoverSrc={isPressed(7) ? button7Pressed : button7Normal} activeSrc={button7Pressed} onClick={() => pressButton(7)} style={{ width: '5.8vw', top: '18.1vw', left: '35.1vw' }} />
          <CartoonButton src={isPressed(8) ? button8Pressed : button8Normal} hoverSrc={isPressed(8) ? button8Pressed : button8Normal} activeSrc={button8Pressed} onClick={() => pressButton(8)} style={{ width: '5.8vw', top: '18.1vw', left: '40.75vw' }} />
          <CartoonButton src={isPressed(9) ? button9Pressed : button9Normal} hoverSrc={isPressed(9) ? button9Pressed : button9Normal} activeSrc={button9Pressed} onClick={() => pressButton(9)} style={{ width: '5.8vw', top: '26.6vw', left: '23.7vw' }} />
          <CartoonButton src={isPressed(10) ? button10Pressed : button10Normal} hoverSrc={isPressed(10) ? button10Pressed : button10Normal} activeSrc={button10Pressed} onClick={() => pressButton(10)} style={{ width: '5.8vw', top: '26.6vw', left: '29.4vw' }} />
          <CartoonButton src={isPressed(11) ? button11Pressed : button11Normal} hoverSrc={isPressed(11) ? button11Pressed : button11Normal} activeSrc={button11Pressed} onClick={() => pressButton(11)} style={{ width: '5.8vw', top: '26.6vw', left: '35.1vw' }} />
          <CartoonButton src={isPressed(12) ? button12Pressed : button12Normal} hoverSrc={isPressed(12) ? button12Pressed : button12Normal} activeSrc={button12Pressed} onClick={() => pressButton(12)} style={{ width: '5.8vw', top: '26.6vw', left: '40.75vw' }} />
          <img src={bacteria} style={{ transition: `all ${transitionTime}ms linear`, ...bacteriaPosition, opacity: bacteriaVisible ? 1 : 0 }} alt="Bacteria" className={style.bacteria} />
          <img src={reflections} alt="Reflections" className={style.reflections} />
          <img src={labels} alt="labels" className={style.labels} />
        </>
      ) : (
        <>
          <img src={brokenWithNoteLights} alt="brokenWithNoteLights" className={style.light} onDragStart={ev => ev.preventDefault()} />
          <img src={knob} alt="Knob" className={style.knob} style={{ transform: `rotate(${current * 25 - 20}deg)` }} onDragStart={ev => ev.preventDefault()} />
          <CartoonButton src={arrowNormalL} hoverSrc={arrowHoverL} activeSrc={arrowClickL} onClick={() => decreaseCurrent()} style={{ width: '5vw', top: '31.8vw', left: '43.3vw', transform: 'rotate(66deg)' }} />
          <CartoonButton src={arrowNormal} hoverSrc={arrowHover} activeSrc={arrowClick} onClick={() => increaseCurrent()} style={{ width: '5vw', top: '32.2vw', left: '58vw', transform: 'rotate(295deg)' }} />
        </>
      )}
    </GameItem>
  );
};
