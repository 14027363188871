import React, { useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import classNames from 'classnames';

import { GameBoard, sounds } from '../../components';
import { useCurrentPlayer } from '../../state';
import { TornPaper, tornPaperImages } from './TornPaper';
import { Keypad, keypadImages } from './Keypad';
import { Stairs } from './Stairs';
import { useKeypad } from './Keypad/keypadState';
import { Loading } from '../../Loading';

import style from './Level1.module.css';

import office from './assets/level_1_Olddog_bg.jpg';
import storeroom from './assets/level_1_Ally_bg.jpg';

import oldDogShelves from './assets/level_1_Olddog_shelves.png';
import oldDogShelvesLight from './assets/level_1_Olddog_shelves_blue.png';
import oldDogLight1 from './assets/level_1_Olddog_yellow.png';
import oldDogLight2 from './assets/level_1_Olddog_blue2.png';
import oldDogLight3 from './assets/level_1_Olddog_blue1.png';

import allyShelves from './assets/level_1_Ally_shelves.png';
import allyLightYellow from './assets/level_1_Ally_yellow.png';
import allyLightRed from './assets/level_1_Ally_red.png';
import allyLightBlue from './assets/level_1_Ally_blue.png';

export const level1Images = [
  office,
  storeroom,
  oldDogShelves,
  oldDogShelvesLight,
  oldDogLight1,
  oldDogLight2,
  oldDogLight3,
  allyShelves,
  allyLightYellow,
  allyLightRed,
  allyLightBlue,
  ...keypadImages,
  ...tornPaperImages
];

export const Level1 = () => {
  const [{ isOldDog, loading }] = useCurrentPlayer();
  const setModalVisible = useStoreActions((state: any) => state.app.setModalOpen);
  const [{ solved }] = useKeypad();

  useEffect(() => {
    if (solved) {
      setModalVisible(false);
      sounds.pullHeavy();
    }
  }, [solved, isOldDog, setModalVisible]);

  if (loading) {
    return <Loading />;
  }

  return (
    <GameBoard backgroundImage={isOldDog ? office : storeroom}>
      <TornPaper />
      <Keypad />
      <Stairs />
      { isOldDog ? (
        <>
          <img
            src={oldDogShelves}
            className={classNames(style.oldDogShelves, solved && style.oldDogShelvesSolved)}
            alt="Shelves"
            onDragStart={ev => ev.preventDefault()} // Fix Firefox drag problem
          />
          <img
            src={oldDogShelvesLight}
            className={classNames(style.oldDogShelvesLight, solved && style.oldDogShelvesLightSolved)}
            alt="Shelves light"
            onDragStart={ev => ev.preventDefault()} // Fix Firefox drag problem
          />
          <img
            src={oldDogLight1}
            className={style.oldDogLight1}
            alt="Light 1"
            onDragStart={ev => ev.preventDefault()} // Fix Firefox drag problem
          />
          <img
            src={oldDogLight2}
            className={style.oldDogLight2}
            alt="Light 2"
            onDragStart={ev => ev.preventDefault()} // Fix Firefox drag problem
          />
          <img
            src={oldDogLight3}
            className={style.oldDogLight3}
            alt="Light 3"
            onDragStart={ev => ev.preventDefault()} // Fix Firefox drag problem
          />
        </>
      ) : (
        <>
          <img
            src={allyShelves}
            className={classNames(style.allyShelves, solved && style.allyShelvesSolved)}
            alt="Shelves"
            onDragStart={ev => ev.preventDefault()} // Fix Firefox drag problem
          />
          <img
            src={allyLightYellow}
            className={style.allyLight1}
            alt="Light 1"
            onDragStart={ev => ev.preventDefault()} // Fix Firefox drag problem
          />
          <img
            src={allyLightRed}
            className={style.allyLightRed}
            alt="Light 1"
            onDragStart={ev => ev.preventDefault()} // Fix Firefox drag problem
          />
          <img
            src={allyLightBlue}
            className={style.allyLightBlue}
            alt="Light 3"
            onDragStart={ev => ev.preventDefault()} // Fix Firefox drag problem
          />
        </>
      )}
    </GameBoard>
  );
};
