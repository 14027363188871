import { useCallback } from 'react';
import { db, useFirestoreQuery } from '../../../firebase';
import { useAccessCode } from '../../../state';
import { sounds } from '../../../components';

interface EquipmentPayload {
  solved: boolean;
  item1InSlot: number;
  item2InSlot: number;
  item3InSlot: number;
  item4InSlot: number;
  item5InSlot: number;
  item6InSlot: number;
}

export const initialState: EquipmentPayload = {
  solved: false,
  item1InSlot: 0,
  item2InSlot: 0,
  item3InSlot: 0,
  item4InSlot: 0,
  item5InSlot: 0,
  item6InSlot: 0
};

type EquipmentResponse = [EquipmentPayload, {
  putIn: (itemId: number, slot: number) => void;
  putOut: (itemId: number) => void;
}];

export function useEquipmentState(): EquipmentResponse {
  const [{ code }] = useAccessCode();
  const stateDoc = db.collection('game')
    .doc(code)
    .collection('l3Equipment')
    .doc('state');
  const { data } = useFirestoreQuery<EquipmentPayload>(stateDoc);

  const state = { ...initialState, ...data };

  const putIn = useCallback((itemId: number, slot: number) => {
    if (state.solved) {
      sounds.error();
      return;
    }
    sounds.elementPutOut();

    const previousItemSlot = (state as any)[`item${itemId}InSlot`];

    // check if other item is already in the spot
    let otherItemInSlot = 0;
    for (let i = 0; i <= 6; i++) {
      if ((state as any)[`item${i}InSlot`] === slot && slot > 0) {
        otherItemInSlot = i;
      }
    }

    const updatedState = { [`item${itemId}InSlot`]: slot };

    // make sure to not allow two items in the same slot
    if (otherItemInSlot) {
      updatedState[`item${otherItemInSlot}InSlot`] = previousItemSlot;
    }
    stateDoc.set(updatedState, { merge: true });
    const solution = [state.item1InSlot, state.item2InSlot, state.item3InSlot, state.item4InSlot, state.item5InSlot, state.item6InSlot];
    solution[itemId - 1] = slot;
    if (solution.join('') === '615324') {
      sounds.success();
      stateDoc.set({ solved: true }, { merge: true });
    }
  }, [state, stateDoc]);

  const putOut = useCallback((itemId: number) => {
    if (state.solved) {
      sounds.error();
      return;
    }
    sounds.elementPutOut();
    stateDoc.set({ [`item${itemId}InSlot`]: 0 }, { merge: true });
  }, [state, stateDoc]);

  return [state, { putIn, putOut }];
}
