import React, { FunctionComponent, useState } from 'react';
import classNames from 'classnames';

import style from './Hints.module.css';
import { useGame } from '../../state';
import { ClassicButton } from '../ClassicButton';

interface HintsProps {
  itemName: string;
  hints: React.ReactNode[];
}

export const Hints: FunctionComponent<HintsProps> = ({ hints, itemName }) => {
  const [current, setCurrent] = useState(-1);
  const [completed, setCompleted] = useState(-1);
  const [, { logHint }] = useGame();

  function setPage(page: number) {
    setCurrent(page);
    setCompleted(Math.max(page, completed));
    logHint(itemName, page + 1);
  }

  if (hints.length === 0) {
    return (
      <div className={style.hints}>
        <p>Sorry, this puzzle has no hints... yet!</p>
      </div>
    );
  }

  return (
    <div className={style.hints}>
      <div className={style.steps}>
        {hints.map((_, i) => (
          <button
            key={`button-${i}`}
            type="button"
            className={classNames(style.stepsButton, current > i && style.stepsButtonCompleted, current === i && style.stepsButtonActive)}
            onClick={() => setCurrent(i)}
          >
            {i + 1 < hints.length ? `Hint ${i + 1}` : 'Solution'}
          </button>
        ))}
      </div>
      <div className={style.hintContent}>
        {current > -1 && current < hints.length ? <p>{hints[current]}</p> : <></>}
        {current === -1 ? <p><i>You can choose to take as many hints as you wish, <br /> the only thing negatively impacted is your pride...</i></p> : <></>}
        <p>
          {current > hints.length - 2 ? (<></>) : (
            <ClassicButton
              size="small"
              disabled={current > hints.length - 2}
              onClick={() => setPage(current + 1)}
              className={style.hintsButton}
            >
              {current === -1 ? 'I don\'t care, show me a hint!' : 'Show me next hint'}
            </ClassicButton>
          )}
        </p>
      </div>
    </div>
  );
};
