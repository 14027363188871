import React from 'react';

import { GameItem } from '../../../components';
import { useComputerState } from './computerState';
import { SnakeInterface } from './SnakeInterface';
import { desktopImages, DesktopInterface } from './DesktopInterface';
import { SoundInterface } from './SoundInterface';

import background from './assets/computer.png';

export const computerImages = [
  background,
  ...desktopImages
];

const hints = [
  'This is a complex puzzle. To solve it, you will need a snake in a terrarium and a computer in Old Dog’s room, and fruits and vegetables with scales and a parrot in Ally’s room.',
  'You need to first solve the snake puzzle. To solve it, collaborate with Ally to calculate the snake’s weight.',
  'Enter the snake’s weight (1650) in the Snake Weightmate app. ',
  'Sounds System App may help solve the puzzle with a parrot in Ally’s room. Check out the recordings.',
  'Oh nice, we have Gustav’s voice samples. Now you need to play the file with the correct answer in the proper room so that a parrot can hear it.',
  'Play the file gustav_shadow.mp3 in Room 2.'
];

export const Computer = () => {
  const [{ snakeSolved, soundAppOpen }] = useComputerState();
  const snakeVisible = !snakeSolved;
  const desktopVisible = snakeSolved && !soundAppOpen;
  const soundVisible = snakeSolved && soundAppOpen;

  return (
    <GameItem
      name="l5Computer"
      imageSrc={background}
      hints={hints}
      iconStyle={{
        top: '11vw',
        left: '15vw',
        width: '18vw',
        height: '26vw'
      }}
    >
      {snakeVisible ? <SnakeInterface /> : <></>}
      {desktopVisible ? <DesktopInterface /> : <></>}
      {soundVisible ? <SoundInterface /> : <></>}
    </GameItem>
  );
};
