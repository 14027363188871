import React from 'react';

import { CartoonButton, GameItem } from '../../../components';
import { useCurrentPlayer } from '../../../state';
import { useKeypad } from './keypadState';

import style from './Keypad.module.css';

import keypad from './assets/keypad.png';
import keypad2 from './assets/keypad2.png';

import button0 from './assets/buttonsNormal/button0.png';
import button1 from './assets/buttonsNormal/button1.png';
import button2 from './assets/buttonsNormal/button2.png';
import button3 from './assets/buttonsNormal/button3.png';
import button4 from './assets/buttonsNormal/button4.png';
import button5 from './assets/buttonsNormal/button5.png';
import button6 from './assets/buttonsNormal/button6.png';
import button7 from './assets/buttonsNormal/button7.png';
import button8 from './assets/buttonsNormal/button8.png';
import button9 from './assets/buttonsNormal/button9.png';
import buttonEnter from './assets/buttonsNormal/buttonEnter.png';

import button0Hover from './assets/buttonsHover/button0.png';
import button1Hover from './assets/buttonsHover/button1.png';
import button2Hover from './assets/buttonsHover/button2.png';
import button3Hover from './assets/buttonsHover/button3.png';
import button4Hover from './assets/buttonsHover/button4.png';
import button5Hover from './assets/buttonsHover/button5.png';
import button6Hover from './assets/buttonsHover/button6.png';
import button7Hover from './assets/buttonsHover/button7.png';
import button8Hover from './assets/buttonsHover/button8.png';
import button9Hover from './assets/buttonsHover/button9.png';
import buttonEnterHover from './assets/buttonsHover/buttonEnter.png';

import button0Pressed from './assets/buttonsPressed/button0.png';
import button1Pressed from './assets/buttonsPressed/button1.png';
import button2Pressed from './assets/buttonsPressed/button2.png';
import button3Pressed from './assets/buttonsPressed/button3.png';
import button4Pressed from './assets/buttonsPressed/button4.png';
import button5Pressed from './assets/buttonsPressed/button5.png';
import button6Pressed from './assets/buttonsPressed/button6.png';
import button7Pressed from './assets/buttonsPressed/button7.png';
import button8Pressed from './assets/buttonsPressed/button8.png';
import button9Pressed from './assets/buttonsPressed/button9.png';
import buttonEnterPressed from './assets/buttonsPressed/buttonEnter.png';

export const keypadImages = [
  keypad,
  keypad2,
  button0,
  button1,
  button2,
  button3,
  button4,
  button5,
  button6,
  button7,
  button8,
  button9,
  buttonEnter,
  button0Hover,
  button1Hover,
  button2Hover,
  button3Hover,
  button4Hover,
  button5Hover,
  button6Hover,
  button7Hover,
  button8Hover,
  button9Hover,
  buttonEnterHover,
  button0Pressed,
  button1Pressed,
  button2Pressed,
  button3Pressed,
  button4Pressed,
  button5Pressed,
  button6Pressed,
  button7Pressed,
  button8Pressed,
  button9Pressed,
  buttonEnterPressed
];

const hintsOldDog = [
  'The keypad requires information from the thorn note.',
  'Using the information gathered from the thorn note, you should be able to enter the code. But, wait, buttons are missing? Maybe Ally can help?',
  'Enter the numbers you see on your keypad and ask Ally to do the same. But remember about the correct order.',
  'The answer is 0385.'
];

const hintsAlly = [
  'The keypad requires information from the thorn note.',
  'Using the information gathered from the thorn note, you should be able to enter the code. But, wait, buttons are missing? Maybe Old Dog can help?',
  'Enter the numbers you see on your keypad and ask Old Dog to do the same. But remember about the correct order.',
  'The answer is 0385.'
];

export const Keypad = () => {
  const [{ isOldDog }] = useCurrentPlayer();
  const [{ code }, { onButtonPress, onSubmit }] = useKeypad();

  if (isOldDog) {
    return (
      <GameItem
        name="l1Keypad1234"
        imageSrc={keypad}
        hints={hintsOldDog}
        iconStyle={{
          top: '12.3vw',
          left: '59vw',
          width: '13vw',
          height: '13vw'
        }}
      >
        <div className={style.screen1}>{code}</div>
        <CartoonButton
          src={button1}
          hoverSrc={button1Hover}
          activeSrc={button1Pressed}
          style={{ top: '14.8vw', left: '43.7vw', width: '4.5vw' }}
          onClick={() => onButtonPress('1')}
        />
        <CartoonButton
          src={button2}
          hoverSrc={button2Hover}
          activeSrc={button2Pressed}
          style={{ top: '14.8vw', left: '50.4vw', width: '4.5vw' }}
          onClick={() => onButtonPress('2')}
        />
        <CartoonButton
          src={button3}
          hoverSrc={button3Hover}
          activeSrc={button3Pressed}
          style={{ top: '14.8vw', left: '56.9vw', width: '4.5vw' }}
          onClick={() => onButtonPress('3')}
        />
        <CartoonButton
          src={button4}
          hoverSrc={button4Hover}
          activeSrc={button4Pressed}
          style={{ top: '20.8vw', left: '43.7vw', width: '4.5vw' }}
          onClick={() => onButtonPress('4')}
        />
        <CartoonButton
          src={button0}
          hoverSrc={button0Hover}
          activeSrc={button0Pressed}
          style={{ top: '32.1vw', left: '43.7vw', width: '4.5vw' }}
          onClick={() => onButtonPress('0')}
        />
        <CartoonButton
          src={buttonEnter}
          hoverSrc={buttonEnterHover}
          activeSrc={buttonEnterPressed}
          style={{ top: '32.2vw', left: '50vw', width: '11.3vw' }}
          onClick={() => onSubmit()}
        />
      </GameItem>
    );
  }

  return (
    <GameItem
      name="l1Keypad6789"
      description="A keypad with missing buttons"
      imageSrc={keypad2}
      hints={hintsAlly}
      iconStyle={{
        top: '7vw',
        left: '51.6vw',
        width: '13vw',
        height: '13vw'
      }}
    >
      <div className={style.screen2}>{code}</div>
      <CartoonButton
        src={button5}
        hoverSrc={button5Hover}
        activeSrc={button5Pressed}
        style={{ top: '20.8vw', left: '50.4vw', width: '4.5vw' }}
        onClick={() => onButtonPress('5')}
      />
      <CartoonButton
        src={button6}
        hoverSrc={button6Hover}
        activeSrc={button6Pressed}
        style={{ top: '20.8vw', left: '56.9vw', width: '4.5vw' }}
        onClick={() => onButtonPress('6')}
      />
      <CartoonButton
        src={button7}
        hoverSrc={button7Hover}
        activeSrc={button7Pressed}
        style={{ top: '26.2vw', left: '43.7vw', width: '4.5vw' }}
        onClick={() => onButtonPress('7')}
      />
      <CartoonButton
        src={button8}
        hoverSrc={button8Hover}
        activeSrc={button8Pressed}
        style={{ top: '26.2vw', left: '50.4vw', width: '4.5vw' }}
        onClick={() => onButtonPress('8')}
      />
      <CartoonButton
        src={button9}
        hoverSrc={button9Hover}
        activeSrc={button9Pressed}
        style={{ top: '26.2vw', left: '56.9vw', width: '4.5vw' }}
        onClick={() => onButtonPress('9')}
      />
      <CartoonButton
        src={buttonEnter}
        hoverSrc={buttonEnterHover}
        activeSrc={buttonEnterPressed}
        style={{ top: '32.2vw', left: '50.5vw', width: '11.3vw' }}
        onClick={() => onSubmit()}
      />
    </GameItem>
  );
};
