import { useCallback } from 'react';
import { db, useFirestoreQuery } from '../../firebase';
import { useAccessCode } from '../../state';
import { sounds } from '../../components';

interface Level5Payload {
  documentsCollected: boolean;
}

export const initialState: Level5Payload = {
  documentsCollected: false
};

type Level5Response = [Level5Payload, {
  collectDocuments: () => void;
}];

export function useLevel5State(): Level5Response {
  const [{ code }] = useAccessCode();
  const stateDoc = db.collection('game')
    .doc(code)
    .collection('l5')
    .doc('state');
  const { data } = useFirestoreQuery<Level5Payload>(stateDoc);

  const state = { ...initialState, ...data };

  const collectDocuments = useCallback(() => {
    sounds.paper2();
    stateDoc.set({ documentsCollected: true }, { merge: true });
  }, [stateDoc]);

  return [state, { collectDocuments }];
}
