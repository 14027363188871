import axios from 'axios';
import { voices } from './voices';

export { voices } from './voices';

// preload audio files
const soundFiles = {
  // music
  menuMusic: './sounds/menuMusic.mp3',

  buttonBeep: './sounds/buttonBeep.mp3',
  keypadError: './sounds/keypadError.mp3',
  open: './sounds/door.mp3',
  lockTurn: './sounds/lock1.mp3',
  magic: './sounds/magic.mp3',
  connect: './sounds/connect.mp3',
  success: './sounds/success.mp3',
  success2: './sounds/success2.mp3',
  fanfare: './sounds/fanfare.mp3',
  error: './sounds/error.mp3',
  beep: './sounds/beep.mp3',
  beep2: './sounds/beep2.mp3',
  beep3: './sounds/beep3.mp3',
  beep4: './sounds/beep4.mp3',
  click: './sounds/click.mp3',
  clickQuiet: './sounds/clickQuiet.mp3',
  powerUp: './sounds/powerUp.mp3',
  potion: './sounds/potion.mp3',
  weapon: './sounds/weapon.mp3',
  knock: './sounds/knock.mp3',
  tick: './sounds/tick.mp3',
  tack: './sounds/tack.mp3',
  toe: './sounds/toe.mp3',
  alarm: './sounds/alarm.mp3',
  mystery: './sounds/mystery.mp3',
  mystery2: './sounds/mystery2.mp3',
  mystery3: './sounds/mystery3.mp3',
  wobble: './sounds/wobble.mp3',
  waterDrop: './sounds/waterDrop.mp3',
  waterTurn: './sounds/waterTurn.mp3',
  waterFlow: './sounds/waterFlow.mp3',
  tapOnGlass: './sounds/tapOnGlass.mp3',
  pullHeavy: './sounds/pullHeavy.mp3',
  fire: './sounds/fire.mp3',
  safeLocked: './sounds/safeLocked.mp3',
  safeUnlocked: './sounds/safeUnlocked.mp3',
  doorLocked2: './sounds/doorLocked2.mp3',
  doorLocked3: './sounds/doorLocked3.mp3',
  doorBurstIn: './sounds/doorBurst.mp3',
  cloth1: './sounds/cloth1.mp3',
  rotate: './sounds/rotate.mp3',
  keyPickUp: './sounds/keyPickUp.mp3',
  keyPutDown: './sounds/keyPutDown.mp3',
  keyPutDown2: './sounds/keyPutDown2.mp3',

  phoneTone: './sounds/phoneTone.mp3',
  phoneTone1: './sounds/phoneTone.mp3',
  phoneTone2: './sounds/phoneTone2.mp3',
  phoneTone3: './sounds/phoneTone3.mp3',
  phoneRinging: './sounds/phoneRinging.mp3',
  phoneRingingShort: './sounds/phoneRingingShort.mp3',
  phoneUnreachable: './sounds/phoneUnreachable.mp3',

  weight: './sounds/weight.mp3',
  weight2: './sounds/weight2.mp3',
  weight3: './sounds/weight3.mp3',

  elementPutIn: './sounds/elementPutIn.mp3',
  elementPutOut: './sounds/elementPutOut.mp3',

  ventilationHit1: './sounds/ventilationHit1.mp3',
  ventilationHit2: './sounds/ventilationHit2.mp3',
  squeek1: './sounds/squeek1.mp3',
  squeek2: './sounds/squeek2.mp3',
  squeek3: './sounds/squeek3.mp3',
  squeek4: './sounds/squeek4.mp3',
  coverFall: './sounds/coverFall.mp3',

  boiling: './sounds/boiling.mp3',
  smokePuff: './sounds/smokePuff.mp3',

  bzzt: './sounds/bzzt.mp3',
  pump: './sounds/pump.mp3',

  button1Down: './sounds/button1Down.mp3',
  button1Up: './sounds/button1Up.mp3',

  pour: './sounds/pour.mp3',

  discTurn1: './sounds/discTurn1.mp3',
  discTurn2: './sounds/discTurn2.mp3',
  discTurn3: './sounds/discTurn3.mp3',

  robotMove: './sounds/robotMove.mp3',
  robotMoveError: './sounds/robotMoveError.mp3',
  putOnTable: './sounds/putOnTable.mp3',

  mouseSqueek: './sounds/mouseSqueek.mp3',
  descend: './sounds/descend.mp3',

  monkey1: './sounds/monkey1.mp3',
  monkey2: './sounds/monkey2.mp3',
  monkey3: './sounds/monkey3.mp3',

  parrot1: './sounds/parrot1.mp3',
  parrot2: './sounds/parrot2.mp3',
  parrot3: './sounds/parrot3.mp3',
  parrotNotGustav: './sounds/parrotNotGustav.mp3',
  parrotRiddle: './sounds/parrotRiddle.mp3',
  parrotSilly: './sounds/parrotSilly.mp3',
  parrotSolution: './sounds/parrotSolution.mp3',
  parrotWhoGoes: './sounds/parrotWhoGoes.mp3',

  snake1: './sounds/snake1.mp3',

  paintingSlideOpen: './sounds/paintingSlideOpen.mp3',
  hiss: './sounds/hiss.mp3',
  fan: './sounds/fan.mp3',

  // cutscenes
  arrival: './sounds/arrival.mp3',
  footsteps: './sounds/footsteps.mp3',
  doorLocked: './sounds/doorLocked.mp3',
  doorKnockHard: './sounds/doorKnockHard.mp3',
  scream: './sounds/scream.mp3',
  woodImpact: './sounds/woodImpact.mp3',
  brickPickUp: './sounds/brickPickUp.mp3',
  windowBreak: './sounds/windowBreak.mp3',
  doorUnlock: './sounds/doorUnlock.mp3',
  footstepsGlass: './sounds/footstepsGlass.mp3',
  retroPulse: './sounds/retroPulse.wav',
  streetAmbient: './sounds/streetAmbient.mp3',
  chain1: './sounds/chain1.mp3',
  chain2: './sounds/chain2.mp3',
  chain3: './sounds/chain3.mp3',
  chain4: './sounds/chain4.mp3',
  unlock1: './sounds/unlock1.mp3',
  unlock2: './sounds/unlock2.mp3',
  zoneEnd: './sounds/zoneEnd.mp3',
  gobletOfWisdom: './sounds/gobletOfWisdom2.mp3',
  openDoor: './sounds/openDoor.mp3',
  spooky: './sounds/spooky.mp3',
  paper: './sounds/paper.mp3',
  paper2: './sounds/paper2.mp3',
  bubblingSalts: './sounds/bubblingSalts.mp3',
  crackle: './sounds/crackle.mp3',
  crackle2: './sounds/crackle2.mp3',
  outro: './sounds/outro.mp3',
  sirens: './sounds/sirens.mp3',
  driveAway: './sounds/driveAway.mp3',
  carDoorClose: './sounds/carDoorClose.mp3',

  // recorded voices
  voice01: './sounds/old-dog/ally-collected-docs.mp3',
  voice02: './sounds/old-dog/ally-collected-docs2.mp3',
  voice03: './sounds/old-dog/ally-its-a-monkey.mp3',
  voice04: './sounds/old-dog/ally-its-a-monkey2.mp3',
  voice05: './sounds/old-dog/better-not-touch.mp3',
  voice06: './sounds/old-dog/daddy1.mp3',
  voice09: './sounds/old-dog/gas.mp3',
  voice10: './sounds/old-dog/i-think-it-was-coming-from-inside.mp3',
  voice11: './sounds/old-dog/i-think-it-was-coming-from-inside2.mp3',
  voice12: './sounds/old-dog/i-think-split.mp3',
  voice13: './sounds/old-dog/it-seems-pretty-cleaer.mp3',
  voice14: './sounds/old-dog/lets-search.mp3',
  voice15: './sounds/old-dog/maybe-he-knew2.mp3',
  voice16: './sounds/old-dog/messed-this-up.mp3',
  voice17: './sounds/old-dog/only-place-stupid-name.mp3',
  voice18: './sounds/old-dog/playing-a-psychiatrist.mp3',
  voice19: './sounds/old-dog/right-catch-them.mp3',
  voice20: './sounds/old-dog/right-catch-them2.mp3',
  voice21: './sounds/old-dog/take-one-of-devices.mp3',
  voice22: './sounds/old-dog/they.mp3',
  voice23: './sounds/old-dog/wait-this-monkey-funky.mp3',
  voice24: './sounds/old-dog/well-its-shut.mp3',
  voice25: './sounds/old-dog/were-good-for-now.mp3',
  voice26: './sounds/old-dog/what-idiotic-super-brain.mp3',
  voice27: './sounds/old-dog/what1.mp3',
  voice28: './sounds/old-dog/what2.mp3',
  voice29: './sounds/old-dog/what3.mp3',
  voice30: './sounds/old-dog/yeah-real-funny.mp3',
  voice31: './sounds/old-dog/there-must-be-reason.mp3',
  voice32: './sounds/old-dog/we-should-split.mp3',
  voice33: './sounds/old-dog/yes.mp3',
  voice34: './sounds/old-dog/daddy2.mp3',

  // ally
  thisIsThePlace: './sounds/ally/thisIsThePlace.mp3',
  itsClosed: './sounds/ally/itsClosed.mp3',
  beCarefulSteve: './sounds/ally/beCarefulSteve.mp3',
  thisLooksOminous: './sounds/ally/thisLooksOminous.mp3',
  split: './sounds/ally/split.mp3',
  ummmOk: './sounds/ally/ummmOk.mp3',
  famousLastWords: './sounds/ally/famousLastWords.mp3',
  whatDoYouThinkTheyreDoingHere: './sounds/ally/whatDoYouThinkTheyreDoingHere.mp3',
  clearlyAtLeastTwoPeople: './sounds/ally/clearlyAtLeastTwoPeople.mp3',
  inferiorityComplex: './sounds/ally/inferiorityComplex.mp3',
  whatElseWouldYouCallThat: './sounds/ally/whatElseWouldYouCallThat.mp3',
  allyParrot: './sounds/ally/parrot.mp3',
  allyParrot2: './sounds/ally/parrot2.mp3',
  allyOk: './sounds/ally/ok.mp3',
  readingDocuments: './sounds/ally/readingDocuments.mp3',
  weKnowTheirFather: './sounds/ally/weKnowTheirFather.mp3',
  areWeGoingWhereIThink: './sounds/ally/areWeGoingWhereIThink.mp3',
};

// this will preload voices as well
Object.entries(voices).forEach(([key, value]) => {
  (soundFiles as any)[key] = value.file;
});

const buffers: { [key: string]: AudioBuffer } = {};

const AudioContext = window.AudioContext || (window as any).webkitAudioContext;
const context = new AudioContext();

export async function preloadAudioFiles(onProgress?: (progress: number, completed: boolean) => void) {
  const filesToPreload = Object.values(soundFiles);
  let completed = 0;

  filesToPreload.forEach(async(filename: string, i) => {
    if (!context) return;
    const res = await axios.get(filename, { responseType: 'arraybuffer' });
    context.decodeAudioData(res.data, (buffer) => {
      buffers[filename] = buffer;
      completed++;
      onProgress && onProgress(completed / filesToPreload.length, completed === filesToPreload.length);
    });
  });
}

// preloadAudioFiles(); // make sure audio files will preload

function playSound(filename: string, volume = 1) {
  if (context) {
    // FIXME: shouldn't we keep the source in memory as well?
    const source = context.createBufferSource();
    source.buffer = buffers[filename];
    const gainNode = context.createGain();
    gainNode.gain.value = volume;
    source.connect(gainNode);
    gainNode.connect(context.destination);
    source.start(0);
    return () => source.stop();
  }

  const audio = new Audio(filename);
  audio.play();
  return () => audio.pause();
}

export const sounds: { [key: string]: (volume?: number) => void } = {};

for (const prop in soundFiles) {
  sounds[prop] = (volume?: number) => playSound((soundFiles as any)[prop], volume || 1);
}
