import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { preloadAudioFiles, GameBoard } from './components';
import { preloadImages } from './utils/preloadImages';
import { getCutsceneImagesToPreload } from './cutscenes';
import { level1Images, level2Images, level3Images, level4Images, level5Images, level6Images } from './levels';
import { prepImages } from './menu/GamePreparationMenu';

import style from './AssetLoader.module.css';

export const getLevelImagesToPreload = (level: string) => {
  switch (level) {
    case '1':
      return level1Images;
    case '2':
      return level2Images;
    case '3':
      return level3Images;
    case '4':
      return level4Images;
    case '5':
      return level5Images;
    case '6':
      return level6Images;
    default:
      return [];
  }
};

interface AssetLoaderProps {
  level?: string;
  cutscene?: string;
}

// load sounds and some other assets
export const AssetLoader: React.FC<AssetLoaderProps> = ({ level, cutscene, children }) => {
  const [audioProgress, setAudioProgress] = useState(0);
  const [audioProgressCompleted, setAudioProgressCompleted] = useState(false);
  const [imageProgress, setImageProgress] = useState(0);
  const [imageProgressCompleted, setImageProgressCompleted] = useState(false);
  const [loadingCompleted, setLoadingCompleted] = useState(false);

  const progress = audioProgress * 50 + imageProgress * 50;
  const progressCompleted = audioProgressCompleted && imageProgressCompleted;
  // const progressCompleted = false;

  const images: string[] = (() => {
    if (cutscene) {
      return getCutsceneImagesToPreload(cutscene);
    }
    if (level === 'prep') {
      return prepImages;
    }
    if (level) {
      return getLevelImagesToPreload(level) as string[];
    }
    return [];
  })();

  useEffect(() => {
    preloadAudioFiles((internalProgress, internalCompleted) => {
      setAudioProgress(internalProgress);
      setAudioProgressCompleted(internalCompleted);
    });

    preloadImages(images, (internalProgress, internalCompleted) => {
      setImageProgress(internalProgress);
      setImageProgressCompleted(internalCompleted);
    });
    // eslint-disable-next-line
  }, [level, cutscene]);

  useEffect(() => {
    let timeout: any;
    if (progressCompleted) {
      timeout = setTimeout(() => setLoadingCompleted(true), 1000);
    }
    return () => clearTimeout(timeout);
  }, [progressCompleted]);

  if (!loadingCompleted) {
    return (
      <GameBoard>
        <div className={classNames(style.loadingPage, progressCompleted && 'animate__animated', progressCompleted && 'animate__fadeOut')}>
          <div className={style.barWrapper}>
            <div
              className={style.bar}
              style={{
                left: `${50 * (1 - (progress / 100))}%`,
                right: `${50 * (1 - (progress / 100))}%`
              }}
            />
          </div>
        </div>
      </GameBoard>
    );
  }
  return <div className="animate__animated animate__fadeIn">{children}</div>;
};
