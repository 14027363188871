import intro1 from './assets/intro1.png';
import intro2 from './assets/intro2.png';
import page1_pic1_01 from './assets/page1_pic1_01.png';
import page1_pic2_01 from './assets/page1_pic2_01.png';
import page1_pic2_txt1_01 from './assets/page1_pic2_txt1_01.png';
import page1_pic3_01 from './assets/page1_pic3_01.png';
import page1_pic3_txt1_01 from './assets/page1_pic3_txt1_01.png';
import page1_pic4_01 from './assets/page1_pic4_01.png';
import page1_pic4_txt2_01 from './assets/page1_pic4_txt2_01.png';
import page2_pic1_01 from './assets/page2_pic1_01.png';
import page2_pic2_01 from './assets/page2_pic2_01.png';
import page2_pic2_txt1_01 from './assets/page2_pic2_txt1_01.png';
import page2_pic3_01 from './assets/page2_pic3_01.png';
import page2_pic4_01 from './assets/page2_pic4_01.png';
import page3_pic1_01 from './assets/page3_pic1_01.png';
import page3_pic2_01 from './assets/page3_pic2_01.png';
import page3_pic3_01 from './assets/page3_pic3_01.png';
import page3_pic4_01 from './assets/page3_pic4_01.png';
import page3_pic5_01 from './assets/page3_pic5_01.png';
import page3_pic6_01 from './assets/page3_pic6_01.png';
import page3_pic6_txt1_01 from './assets/page3_pic6_txt1_01.png';
import page3_pic6_txt2_01 from './assets/page3_pic6_txt2_01.png';

const WIDTH = 2800;

export const CUTSCENE1 = [
  { type: 'sound', src: 'streetAmbient', delay: 0, volume: 0.3 },
  { type: 'image', src: intro1, delay: 3000, top: 5, left: 15, width: 70, visible: false, animationIn: 'fadeIn', animationSpeed: 'slow' },
  { type: 'page-end', delay: 6000 },

  // page 0
  { type: 'sound', src: 'arrival', delay: 0 },
  { type: 'image', src: intro2, delay: 3000, top: 15, left: 30, width: 40, visible: false, animationIn: 'fadeIn', animationSpeed: 'slow' },
  { type: 'page-end', delay: 6000 },

  // page 1
  { type: 'image', src: page1_pic1_01, delay: 4500, top: 0, left: 0, width: (1725 / WIDTH) * 100, visible: false },
  { type: 'sound', src: 'footsteps', delay: 0 },
  { type: 'image', src: page1_pic2_01, delay: 2000, top: 32.5, left: 0, width: (813 / WIDTH) * 100, visible: false },
  { type: 'image', src: page1_pic2_txt1_01, delay: 100, top: 30, left: 4, width: (754 / WIDTH) * 100, visible: false, animationIn: 'fadeInDown', zIndex: 1 },
  { type: 'sound', src: 'thisIsThePlace', delay: 500 },
  { type: 'image', src: page1_pic3_01, delay: 2500, top: 32.5, left: (813 / WIDTH) * 100 + 1.5, width: (860 / WIDTH) * 100, visible: false },
  { type: 'image', src: page1_pic3_txt1_01, delay: 100, top: 71, left: 23, width: (820 / WIDTH) * 100, visible: false, animationIn: 'fadeInUp' },
  { type: 'sound', src: 'voice17', delay: 0 },
  { type: 'image', src: page1_pic4_01, delay: 2000, top: 0, left: (1725 / WIDTH) * 100 + 1.5, width: (1024 / WIDTH) * 100, visible: false },
  { type: 'sound', src: 'doorKnockHard', delay: 1500 },
  { type: 'page-end', delay: 2000 },

  // page 2
  { type: 'image', src: page2_pic1_01, delay: 0, top: 0, left: 0, width: (1023 / WIDTH) * 100, visible: false },
  { type: 'sound', src: 'doorLocked', delay: 0 },
  { type: 'image', src: page2_pic2_01, delay: 3000, top: 25, left: 0, width: (1022 / WIDTH) * 100, visible: false },
  { type: 'image', src: page2_pic2_txt1_01, delay: 0, top: 25, left: 15, width: (544 / WIDTH) * 100, visible: false, animationIn: 'fadeInDown' },
  { type: 'sound', src: 'itsClosed', delay: 500 },
  { type: 'image', src: page1_pic4_txt2_01, delay: 500, top: 65, left: 9, width: (820 / WIDTH) * 100, visible: false, animationIn: 'fadeInUp' },
  { type: 'sound', src: 'voice15', delay: 500 },
  { type: 'sound', src: 'scream', delay: 2000, volume: 0.5 },
  { type: 'image', src: page2_pic3_01, delay: 0, top: 0, left: 37.4, width: (1087 / WIDTH) * 100, visible: false, animationIn: 'jackInTheBox' },
  { type: 'sound', src: 'retroPulse', delay: 300 },
  { type: 'image', src: page2_pic4_01, delay: 700, top: 0, left: 75, width: (636 / WIDTH) * 100, visible: false, animationIn: 'bounceInRight' },
  { type: 'page-end', delay: 2000 },

  // page 3
  { type: 'image', src: page3_pic1_01, delay: 0, top: 0, left: 0, width: (1063 / WIDTH) * 100, visible: false },
  { type: 'sound', src: 'woodImpact', delay: 0 },
  { type: 'image', src: page3_pic2_01, delay: 800, top: 0, left: 40, width: (472 / WIDTH) * 100, visible: false },
  { type: 'sound', src: 'brickPickUp', delay: 0 },
  { type: 'image', src: page3_pic3_01, delay: 800, top: 0, left: 58, width: (584 / WIDTH) * 100, visible: false },
  { type: 'sound', src: 'windowBreak', delay: 0 },
  { type: 'image', src: page3_pic4_01, delay: 800, top: 0, left: 80, width: (549 / WIDTH) * 100, visible: false },
  { type: 'sound', src: 'doorUnlock', delay: 0 },
  { type: 'image', src: page3_pic5_01, delay: 1000, top: 27, left: 0, width: (1053 / WIDTH) * 100, visible: false },
  { type: 'sound', src: 'footstepsGlass', delay: 0 },
  { type: 'image', src: page3_pic6_txt1_01, delay: 1500, top: 31, left: 20, width: (750 / WIDTH) * 100, visible: false, animationIn: 'fadeInRight' },
  { type: 'sound', src: 'beCarefulSteve', delay: 500 },
  { type: 'image', src: page3_pic6_01, delay: 1000, top: 46, left: 40, width: (1647 / WIDTH) * 100, visible: false },
  { type: 'image', src: page3_pic6_txt2_01, delay: 700, top: 75, left: 79, width: (556 / WIDTH) * 100, visible: false },
  { type: 'sound', src: 'phoneRingingShort', delay: 800, volume: 0.2 },
  { type: 'sound', src: 'phoneRingingShort', delay: 2000, volume: 0.2 }
];
