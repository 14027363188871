import React, { useEffect, useState } from 'react';

const urlSearchParams = new URLSearchParams(window.location.search);
urlSearchParams.append('game', '1');

const RESIZE_REFRESH_INTERVAL = 100;
const RATIO = 16 / 9;

export const Frame = () => {
  const [{ width, height }, setDimensions] = useState({
    height: document.documentElement.clientHeight,
    width: document.documentElement.clientWidth
  });

  let frameWidth = width - 1;
  let frameHeight = height;
  let topDiff = 0;
  let leftDiff = 0;

  if (frameWidth / frameHeight <= RATIO) {
    topDiff = Math.floor((frameHeight - frameWidth / RATIO) / 2);
    frameHeight = Math.floor(frameWidth / RATIO);
  } else {
    leftDiff = Math.floor((frameWidth - frameHeight * RATIO) / 2);
    frameWidth = Math.floor(frameHeight * RATIO);
  }

  useEffect(() => {
    setInterval(() => {
      setDimensions(() => ({
        height: document.documentElement.clientHeight,
        width: document.documentElement.clientWidth
      }));
    }, RESIZE_REFRESH_INTERVAL);
  }, []);

  return (
    <iframe
      scrolling="no"
      style={{
        position: 'absolute',
        top: `${topDiff}px`,
        left: `${leftDiff}px`,
        width: `${frameWidth}px`,
        height: `${frameHeight}px`
      }}
      title="Game"
      id="rootFrame"
      src={`/?${urlSearchParams}`}
    />
  );
};
