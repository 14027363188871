import React, { memo } from 'react';
import { ArrowImage } from './Arrow';

const styles = {
  display: 'inline-block',
  transform: 'rotate(7deg)',
  WebkitTransform: 'rotate(7deg)',
  width: '5vw'
};

export interface ArrowDragPreviewProps {
  direction: string;
}

export const ArrowDragPreview: React.FC<ArrowDragPreviewProps> = memo(
  ({ direction }) => (
    <div style={styles}>
      <ArrowImage direction={direction} dark={false} />
    </div>
  )
);
