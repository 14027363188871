import React, { memo } from 'react';
import { BeakerImage } from './Beaker';

const styles = {
  display: 'inline-block',
  transform: 'rotate(7deg)',
  WebkitTransform: 'rotate(7deg)',
  width: '5vw'
};

export interface BeakerDragPreviewProps {
  color: string;
}

export const BeakerDragPreview: React.FC<BeakerDragPreviewProps> = memo(
  ({ color }) => (
    <div style={styles}>
      <BeakerImage color={color} />
    </div>
  )
);
