import React from 'react';
import { useComputerState } from './computerState';
import { File } from './File';
import { CustomDragLayer } from './CustomDragLayer';
import { RoomDropzone } from './RoomDropzone';

import style from './Computer.module.css';

export const SoundInterface = () => {
  const [{ playingSoundDestination }, { playSound, closeSound }] = useComputerState();

  return (
    <div className={style.screen}>
      <CustomDragLayer />
      <div className={style.window}>
        <div className={style.header}>
          Sound System App
          <button type="button" className={style.closeWindow} onClick={() => closeSound()}>X</button>
        </div>
        <div className={style.bodyLeft}>
          <File onDrop={playSound} filename="gustav_funny.mp3" />
          <File onDrop={playSound} filename="test.mp3" />
          <File onDrop={playSound} filename="gustav_name.mp3" />
          <File onDrop={playSound} filename="gustav_warning.mp3" />
          <File onDrop={playSound} filename="gustav_shadow.mp3" />
          <File onDrop={playSound} filename="time_to_go.mp3" />
        </div>
        <div className={style.bodyRight}>
          <RoomDropzone destination="Room 1" playing={playingSoundDestination === 'Room 1'} />
          <RoomDropzone destination="Room 2" playing={playingSoundDestination === 'Room 2'} />
        </div>
        <div className={style.dragNotification}>Drag to play in a room:</div>
      </div>
    </div>
  );
};
