import React, { memo } from 'react';
import { SampleImage } from './Sample';

const styles = {
  display: 'inline-block',
  transform: 'rotate(7deg)',
  WebkitTransform: 'rotate(7deg)',
  width: '5vw'
};

export interface SampleDragPreviewProps {
  name: string;
}

export const SampleDragPreview: React.FC<SampleDragPreviewProps> = memo(
  ({ name }) => (
    <div style={styles}>
      <SampleImage name={name} small={false} />
    </div>
  )
);
