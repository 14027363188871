import { useCallback } from 'react';
import { db, useFirestoreQuery } from '../../../firebase';
import { useAccessCode } from '../../../state';
import { sounds } from '../../../components';

export const vialToLetterMap = {
  10: 'Ne',
  13: 'Al',
  15: 'P',
  16: 'S',
  25: 'Mn',
  46: 'Pd',
  53: 'I',
  57: 'La',
  60: 'Nd',
  75: 'Re',
  83: 'Bi',
  '08': 'O'
};

export const correctSequences = {
  Poland: ['15', '08', '57', '60'],
  Ireland: ['53', '75', '57', '60'],
  Nepal: ['10', '15', '13']
};

interface VialsPayload {
  slotPoland: string; // comma-separated sequence of vials that went in
  slotPolandSolved: boolean;
  slotIreland: string;
  slotIrelandSolved: boolean;
  slotNepal: string;
  slotNepalSolved: boolean;
}

type VialsState = VialsPayload & {
  slotPolandState: number;
  slotIrelandState: number;
  slotNepalState: number;
};

export const initialState: VialsState = {
  slotPoland: '',
  slotPolandSolved: false,
  slotPolandState: correctSequences.Poland.length,
  slotIreland: '',
  slotIrelandSolved: false,
  slotIrelandState: correctSequences.Ireland.length,
  slotNepal: '',
  slotNepalSolved: false,
  slotNepalState: correctSequences.Nepal.length
};

type VialsResponse = [VialsState, {
  putInSlot: (vialNumber: string, slot: string) => void;
  emptySlot: (slot: string) => void;
}];

export function useVialsState(): VialsResponse {
  const [{ code }] = useAccessCode();
  const stateDoc = db.collection('game')
    .doc(code)
    .collection('l3Vials')
    .doc('state');
  const { data } = useFirestoreQuery<VialsPayload>(stateDoc);

  const state = { ...initialState, ...data };

  ['Poland', 'Ireland', 'Nepal'].forEach((slot) => {
    const sequence = (state as any)[`slot${slot}`].split(',').filter((x: any) => !!x);
    const remainingLength = (correctSequences as any)[slot].length - sequence.length;
    (state as any)[`slot${slot}State`] = remainingLength;
  });

  const emptySlot = useCallback((slot: string) => {
    if ((state as any)[`slot${slot}Solved`]) {
      sounds.error();
      return;
    }
    stateDoc.set({ [`slot${slot}`]: '' }, { merge: true });
  }, [stateDoc, state]);

  const putInSlot = useCallback((vialNumber: string, slot: string) => {
    if ((state as any)[`slot${slot}Solved`] || (state as any)[`slot${slot}State`] <= 0) {
      sounds.error();
      return;
    }

    const previousState = (state as any)[`slot${slot}`].split(',');
    const newSequence = [...previousState, vialNumber].filter(x => !!x).join(',');
    const solved = newSequence === (correctSequences as any)[slot].join(',');

    sounds.pour();

    stateDoc.set({ [`slot${slot}`]: newSequence, [`slot${slot}Solved`]: solved }, { merge: true });

    sounds.elementPutOut();
    if (solved) {
      sounds.success();
    }
  }, [stateDoc, state]);

  return [state, { putInSlot, emptySlot }];
}
