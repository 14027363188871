import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import style from './Discs.module.css';

import discB from './assets/discB.png';
import discTL from './assets/discTL.png';
import discTR from './assets/discTR.png';
import { useDiscsState } from './discState';

type DiscPossibleLocations = 'B' | 'TL' | 'TR';

const getSrc = (location: DiscPossibleLocations) => {
  if (location === 'B') return discB;
  if (location === 'TL') return discTL;
  return discTR;
};

interface DiscProps {
  location: DiscPossibleLocations;
}

export const Disc: React.FC<DiscProps> = ({ location }) => {
  const [rotations, { rotate }] = useDiscsState();
  const [animationEnabled, setAnimationEnabled] = useState(false);
  const rotation = rotations[location];

  const onClick = () => rotate(location);

  useEffect(() => {
    // make sure that the animation does not fire up when player returns to the box
    setTimeout(() => setAnimationEnabled(true), 500);
    return () => setAnimationEnabled(false);
  }, []);

  return (
    <img
      className={classNames(style.disc, style[`disc${location}`])}
      src={getSrc(location)}
      alt={`Disc ${location}`}
      onClick={onClick}
      onDragStart={ev => ev.preventDefault()}
      style={{
        transform: `rotate(${rotation * 45}deg)`,
        transition: animationEnabled ? 'transform 500ms ease-in-out' : 'none'
      }}
    />
  );
};
