import { controlsImages, gameLockImages, speechBubbleImages } from '../components';

export function preloadImages(images: string[], onProgress: (progress: number, completed: boolean) => void) {
  let completed = 0;

  const imagesToPreload = [...images, ...gameLockImages, ...speechBubbleImages, ...controlsImages];
  imagesToPreload.forEach((image) => {
    const img = new Image();
    img.src = image;
    img.onload = () => {
      completed++;
      onProgress(completed / imagesToPreload.length, completed === imagesToPreload.length);
    };
  });
}
