import React from 'react';
import { useDrop } from 'react-dnd';

import { useCurrentPlayer } from '../../../state';

import style from './Scales.module.css';

interface DropzoneProps {
  isDragging: boolean;
}

export const PlaceholderDropzone: React.FC<DropzoneProps> = ({ isDragging }) => {
  const [{ isOldDog }] = useCurrentPlayer();

  const [, drop] = useDrop({
    accept: 'weight',
    drop: () => ({ type: 'placeholder' }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  return (
    <div
      ref={drop}
      className={isOldDog ? style.placeholderDropLeft : style.placeholderDropRight}
      style={{ zIndex: isDragging ? 3 : 1 }}
    />
  );
};
