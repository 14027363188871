import * as firebase from 'firebase';

let firebaseConfig;

if (process.env.REACT_APP_ENV === 'development' || process.env.NODE_ENV === 'development') {
  firebaseConfig = {
    apiKey: 'AIzaSyChZonIYR1v1vbVf08agFCeEICQ71OEk5o',
    authDomain: 'secret-lab-dev.firebaseapp.com',
    projectId: 'secret-lab-dev',
    storageBucket: 'secret-lab-dev.appspot.com',
    messagingSenderId: '958106735259',
    appId: '1:958106735259:web:d2574421d7a5e63afdec3e',
    databaseURL: 'https://secret-lab-dev-default-rtdb.europe-west1.firebasedatabase.app'
  };
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyAKXtm_PIlUbyark7MOZqVBbC13_U68VRc',
    authDomain: 'ep-secret-lab.firebaseapp.com',
    projectId: 'ep-secret-lab',
    storageBucket: 'ep-secret-lab.appspot.com',
    messagingSenderId: '1015062265888',
    appId: '1:1015062265888:web:e8e9db719c8aa78eb73c45',
    databaseURL: 'https://ep-secret-lab-default-rtdb.europe-west1.firebasedatabase.app'
  };
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const db = firebase.firestore();

export const database = firebase.database();
