/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { useBlindMiceState } from './miceState';
import { sounds, CartoonButton } from '../../../components';

import style from './BlindMice.module.css';

import mouseLefty from './assets/mouse_l.png';
import mouseRighty from './assets/mouse_r.png';
import buttonStart from './assets/button_start.png';
import buttonStartActive from './assets/button_start_active.png';
import buttonReset from './assets/button_reset.png';
import buttonResetActive from './assets/button_reset_active.png';

export const mouseImages = [
  mouseLefty,
  mouseRighty,
  buttonStart,
  buttonStartActive,
  buttonReset,
  buttonResetActive
];

const GRID_HEIGHT = 6;
const GRID_WIDTH = 6;

// ['x1-y1-x2-y2]
// top-left = 0-0
const INTERNAL_WALLS = [
  '4-0/5-0',
  '0-1/0-2',
  '3-1/3-2',
  '4-1/4-2',
  '1-3/1-4',
  '2-3/2-4',
  '3-5/4-5'
];

const DESTINATION = '2--1';

const TRAPS = [
  '5-0',
  '2-2',
  '5-3'
];

const INITIAL_POSITION_LEFTY = { left: 32.4, top: 29.4, x: -1, y: 5, direction: 'right', rotation: 0 };
const INITIAL_POSITION_RIGHTY = { left: 62.1, top: 29.7, x: 6, y: 5, direction: 'left', rotation: 180 };
const STEP = 4.09; // vw
const INTERVAL = 400; // ms

const DIRECTIONS = {
  left: [-1, 0],
  right: [1, 0],
  up: [0, -1],
  down: [0, 1]
};

function turnLeft(currentDirection: string) {
  switch (currentDirection) {
    case 'left':
      return 'down';
    case 'down':
      return 'right';
    case 'right':
      return 'up';
    case 'up':
      return 'left';
    default:
      return 'up';
  }
}

function turnRight(currentDirection: string) {
  switch (currentDirection) {
    case 'left':
      return 'up';
    case 'up':
      return 'right';
    case 'right':
      return 'down';
    case 'down':
      return 'left';
    default:
      return 'up';
  }
}

function isAtDestination({ x, y }: Mouse) {
  return `${x}-${y}` === DESTINATION;
}

function canMakeTheMove(mouse: Mouse, newPosition: { x: number; y: number }) {
  const { x: x1, y: y1 } = mouse;
  const { x: x2, y: y2 } = newPosition;

  // check if destination
  if (isAtDestination({ ...mouse, ...newPosition })) {
    return true;
  }

  // check if out of bounds
  if (x2 < 0 || y2 < 0 || x2 >= GRID_WIDTH || y2 >= GRID_HEIGHT) {
    return false;
  }

  // check for walls
  const wallCombination = `${Math.min(x1, x2)}-${Math.min(y1, y2)}/${Math.max(x1, x2)}-${Math.max(y1, y2)}`;

  return INTERNAL_WALLS.indexOf(wallCombination) === -1;
}

function shouldDie({ x, y }: Mouse) {
  return TRAPS.indexOf(`${x}-${y}`) !== -1;
}

const turnToArrow = (mouse: Mouse, arrow0: [number, number] | null, arrow1: [number, number] | null, arrow2: [number, number] | null, arrow3: [number, number] | null) => {
  const arrows = [arrow0 ? arrow0.join('-') : '-', arrow1 ? arrow1.join('-') : '-', arrow2 ? arrow2.join('-') : '-', arrow3 ? arrow3.join('-') : '-'];
  const index = arrows.indexOf(`${mouse.x}-${mouse.y}`);
  if (index === -1) {
    return false;
  }
  if (index < 2 && mouse.direction !== 'left') {
    const possibleRotation1 = 180 + Math.floor(mouse.rotation / 360) * 360;
    const possibleRotation2 = possibleRotation1 - 360;
    const newRotation = Math.abs(possibleRotation1 - mouse.rotation) < Math.abs(possibleRotation2 - mouse.rotation) ? possibleRotation1 : possibleRotation2;
    return {
      newDirection: 'left',
      newRotation
    };
  }
  if (index >= 2 && mouse.direction !== 'up') {
    const possibleRotation1 = 270 + Math.floor(mouse.rotation / 360) * 360;
    const possibleRotation2 = possibleRotation1 - 360;
    const newRotation = Math.abs(possibleRotation1 - mouse.rotation) < Math.abs(possibleRotation2 - mouse.rotation) ? possibleRotation1 : possibleRotation2;
    return {
      newDirection: 'up',
      newRotation
    };
  }
  return false;
};

interface Mouse {
  x: number;
  y: number;
  direction: string;
  rotation: number;
  dead: boolean;
}

export const MouseOverlay: React.FC = () => {
  const [{ arrow0, arrow1, arrow2, arrow3, solved, playing }, { play, reset, setSolved }] = useBlindMiceState();
  let [lefty, setLefty] = useState<Mouse>({ x: INITIAL_POSITION_LEFTY.x, y: INITIAL_POSITION_LEFTY.y, direction: INITIAL_POSITION_LEFTY.direction, rotation: INITIAL_POSITION_LEFTY.rotation, dead: false });
  let [righty, setRighty] = useState<Mouse>({ x: INITIAL_POSITION_RIGHTY.x, y: INITIAL_POSITION_RIGHTY.y, direction: INITIAL_POSITION_RIGHTY.direction, rotation: INITIAL_POSITION_RIGHTY.rotation, dead: false });

  if (solved) {
    lefty = {
      x: 2, y: -1, direction: 'up', rotation: 270 - 2 * 360, dead: false
    };
    righty = {
      x: 2, y: -1, direction: 'up', rotation: 270 + 360, dead: false
    };
  }

  const leftyPosition = {
    top: INITIAL_POSITION_LEFTY.top - INITIAL_POSITION_LEFTY.y * STEP + lefty.y * STEP,
    left: INITIAL_POSITION_LEFTY.left - INITIAL_POSITION_LEFTY.x * STEP + lefty.x * STEP
  };

  const rightyPosition = {
    top: INITIAL_POSITION_RIGHTY.top - INITIAL_POSITION_RIGHTY.y * STEP + righty.y * STEP,
    left: INITIAL_POSITION_RIGHTY.left - INITIAL_POSITION_RIGHTY.x * STEP + righty.x * STEP
  };

  useEffect(() => {
    if (solved) return () => {};
    let interval: any;
    let stopSound: any;
    if (playing) {
      stopSound = sounds.mouseSqueek();
      interval = setInterval(() => {
        stopSound = sounds.mouseSqueek();
      }, 5700);
    } else {
      // not playing, so reset
      setLefty({ x: INITIAL_POSITION_LEFTY.x, y: INITIAL_POSITION_LEFTY.y, direction: INITIAL_POSITION_LEFTY.direction, rotation: INITIAL_POSITION_LEFTY.rotation, dead: false });
      setRighty({ x: INITIAL_POSITION_RIGHTY.x, y: INITIAL_POSITION_RIGHTY.y, direction: INITIAL_POSITION_RIGHTY.direction, rotation: INITIAL_POSITION_RIGHTY.rotation, dead: false });
    }
    return () => {
      clearInterval(interval);
      stopSound && stopSound();
    };
  }, [playing, solved]);

  const createScheduleReset = (mouse: Mouse) => () => {
    let timeout: any;
    if (mouse.dead) {
      timeout = setTimeout(() => {
        // reset the game, instead of moving mice to original position
        reset();
        // setLefty({ x: INITIAL_POSITION_LEFTY.x, y: INITIAL_POSITION_LEFTY.y, direction: INITIAL_POSITION_LEFTY.direction, rotation: INITIAL_POSITION_LEFTY.rotation, dead: false });
        // setRighty({ x: INITIAL_POSITION_RIGHTY.x, y: INITIAL_POSITION_RIGHTY.y, direction: INITIAL_POSITION_RIGHTY.direction, rotation: INITIAL_POSITION_RIGHTY.rotation, dead: false });
      }, 3000);
    }
    return () => clearTimeout(timeout);
  };

  useEffect(createScheduleReset(righty), [righty.dead]);
  useEffect(createScheduleReset(lefty), [lefty.dead]);

  useEffect(() => {
    let interval: any;
    const mice = { lefty: { ...lefty }, righty: { ...righty } };

    const nextMoveTick = (mouse: Mouse, setMouse: (mouse: Mouse) => void, turn: string) => {
      if (isAtDestination(mouse)) {
        return;
      }

      const change = DIRECTIONS[mouse.direction as 'left' | 'right' | 'up' | 'down'];
      const newPosition = { x: mouse.x + change[0], y: mouse.y + change[1] };
      const arrowTurn = turnToArrow(mouse, arrow0, arrow1, arrow2, arrow3);

      if (shouldDie(mouse)) {
        if (!mouse.dead) {
          sounds.descend(0.15);
        }
        mouse.dead = true;
      } else if (arrowTurn) {
        mouse.rotation = arrowTurn.newRotation;
        mouse.direction = arrowTurn.newDirection;
      } else if (canMakeTheMove(mouse, newPosition)) {
        mouse.x = newPosition.x;
        mouse.y = newPosition.y;
      } else {
        mouse.direction = turn === 'left' ? turnLeft(mouse.direction) : turnRight(mouse.direction);
        mouse.rotation = turn === 'left' ? mouse.rotation - 90 : mouse.rotation + 90;
      }

      setMouse({ ...mouse });
    };

    if (playing) {
      interval = setInterval(() => {
        if (isAtDestination(mice.lefty) && isAtDestination(mice.righty)) {
          setSolved();
        } else {
          nextMoveTick(mice.lefty, setLefty, 'left');
          nextMoveTick(mice.righty, setRighty, 'right');
        }
      }, INTERVAL);
    }

    return () => {
      clearInterval(interval);
    };
  }, [playing, solved, arrow0, arrow1, arrow2, arrow3, lefty, righty, setSolved]);

  return (
    <>
      <img
        style={{ left: `${leftyPosition.left}vw`, top: `${leftyPosition.top}vw`, transform: `rotate(${lefty.rotation + (lefty.dead ? 720 : 0)}deg) scale(${lefty.dead ? 0 : 1})` }}
        src={mouseLefty}
        alt="Mouse Lefty"
        className={classNames(style.mouseLefty, lefty.dead && style.mouseDead)}
      />
      <img
        style={{ left: `${rightyPosition.left}vw`, top: `${rightyPosition.top}vw`, transform: `rotate(${righty.rotation + (righty.dead ? 720 : 0)}deg) scale(${righty.dead ? 0 : 1})` }}
        src={mouseRighty}
        alt="Mouse Righty"
        className={classNames(style.mouseRighty, righty.dead && style.mouseDead)}
      />
      <CartoonButton
        className={style.playButton}
        onClick={() => play()}
        src={buttonStart}
        hoverSrc={buttonStart}
        activeSrc={buttonStartActive}
      />
      <CartoonButton
        className={style.resetButton}
        onClick={() => reset()}
        src={buttonReset}
        hoverSrc={buttonReset}
        activeSrc={buttonResetActive}
      />
    </>
  );
};
