import React, { useState } from 'react';
import classNames from 'classnames';
import { CartoonButton, GameItem, sounds, speech } from '../../../components';
import { useCurrentPlayer, useGame } from '../../../state';
import { useSafeState } from './safeState';
import { useLevel5State } from '../level5State';
import { useBlindMiceState } from '../BlindMice/miceState';

import style from './Safe.module.css';

import buttonInvisible from './assets/button.png';
import buttonPressed from './assets/pressed.png';
import closed from './assets/safe_closed.png';
import open from './assets/safe_opened.png';
import handle from './assets/handle.png';
import handleShadow from './assets/handleShadow.png';
import digits from './assets/digits.png';
import documents from './assets/documents.png';

export const safeImages = [
  buttonInvisible,
  buttonPressed,
  closed,
  open,
  handle,
  handleShadow,
  digits,
  documents
];

const hints = [
  'To solve the puzzle, you\'ll need to solve all other puzzles in this room.',
  'The parrot puzzle will give you the last number and the order of other numbers.',
  'The solution is  9, 8, 7, 7.',
  'Where can you find the TRUE valid code? Maybe have a look at the monkey in Old Dog’s room?',
  'The monkey shows buttons in this order: 6, 4, 2, 9, and OK.'
];

export const Safe = () => {
  const [combination, setCombination] = useState('');
  const [{ data }, { setBombStart }] = useGame();
  const [, { logAction }] = useCurrentPlayer();
  const [{ solved: safeOpen }, { setSolved }] = useSafeState();
  const [{ documentsCollected }, { collectDocuments }] = useLevel5State();
  const [{ solved: blindMiceSolved }] = useBlindMiceState();
  const [handleState, setHandleState] = useState<'default' | 'moving' | 'unlocked'>('default');
  const [screenMode, setScreenMode] = useState<'default' | 'error' | 'parrot' | 'unlocked'>('default');
  const bombArmed = data?.bombArmed;

  const screenText = combination.split('')
    .map(l => screenMode === 'parrot' || screenMode === 'unlocked' ? l : '●')
    .join('');

  const onClick = (button: string) => {
    if (screenMode === 'parrot') {
      return;
    }
    if (button === 'DEL') {
      sounds.buttonBeep();
      setCombination('');
    } else if (button === 'OK') {
      const DELAY = 500;
      const DISPLAY = 2000;
      if (combination === '9877' && !bombArmed) {
        setCombination('');
        sounds.success();
        setScreenMode('parrot');
        setTimeout(() => { setCombination('ENTERED'); sounds.beep4(0.2); }, DELAY);
        setTimeout(() => setCombination(''), DELAY + DISPLAY);
        setTimeout(() => { setCombination('PARROT CODE'); sounds.beep4(0.2); }, DELAY * 2 + DISPLAY);
        setTimeout(() => setCombination(''), DELAY * 2 + DISPLAY * 2);
        setTimeout(() => { setCombination('WELCOME'); sounds.beep4(0.2); }, DELAY * 3 + DISPLAY * 2);
        setTimeout(() => setCombination(''), DELAY * 3 + DISPLAY * 3);
        setTimeout(() => { setCombination('DETECTIVES'); sounds.beep4(0.2); }, DELAY * 4 + DISPLAY * 3);
        setTimeout(() => setCombination(''), DELAY * 4 + DISPLAY * 4);
        setTimeout(() => { setCombination('ACTIVATING'); sounds.beep4(0.2); }, DELAY * 5 + DISPLAY * 4);
        setTimeout(() => setCombination(''), DELAY * 5 + DISPLAY * 5);
        setTimeout(() => { setCombination('TRAP'); sounds.beep4(0.2); }, DELAY * 6 + DISPLAY * 5);
        setTimeout(() => setCombination(''), DELAY * 6 + DISPLAY * 7);

        setTimeout(() => {
          // SET ALARM!!!
          setBombStart();
          setCombination('');
          setScreenMode('default');
        }, DELAY * 6 + DISPLAY * 6);

        logAction('lock-solved', 'l5Lock');
      } else if (combination === '6429') {
        sounds.success();
        setCombination('');
        setScreenMode('unlocked');
        setTimeout(() => setCombination('UNLOCKED'), DELAY);

        logAction('lock-solved', 'l5LockFinal');
      } else {
        sounds.keypadError();
        setScreenMode('error');
        setTimeout(() => {
          setScreenMode('default');
        }, 1400);
        logAction('lock-error', 'l5Lock', { combination });
      }
    } else if (combination.length === 4) {
      sounds.keypadError();
    } else {
      sounds.buttonBeep();
      setCombination(combination + button);
    }
  };

  const buttons = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'OK', 'DEL'].map(val => (
    <CartoonButton
      src={buttonInvisible}
      activeSrc={buttonPressed}
      hoverSrc={buttonInvisible}
      className={classNames(style.button, style[`button${val}`])}
      onClick={() => onClick(val)}
    />
  ));

  const onDocumentsClick = () => collectDocuments();

  const tryHandle = () => {
    if (handleState !== 'moving') {
      if (screenMode === 'unlocked') {
        sounds.safeUnlocked();
        setHandleState('unlocked');
        setTimeout(() => setSolved(), 1300);
      } else {
        sounds.safeLocked();
        setHandleState('moving');
        setTimeout(() => setHandleState('default'), 1000);
      }
    }
  };

  return (
    <GameItem
      name="l5Safe"
      hints={hints}
      imageSrc={safeOpen ? open : closed}
      onClick={() => {
        if (!blindMiceSolved) {
          speech.say('l5AllyFather');
        }
      }}
      disabled={!blindMiceSolved}
      iconStyle={{
        top: '0vw',
        left: '0vw',
        width: '16vw',
        height: '28vw',
        zIndex: 3
      }}
    >
      {safeOpen ? (
        <>
          {documentsCollected ? (
            <></>
          ) : (
            <img src={documents} className={style.documents} onClick={() => onDocumentsClick()} alt="Documents" />
          )}
        </>
      ) : (
        <>
          <img src={handle} alt="Handle" className={classNames(style.handle, style[`handle${handleState}`])} onClick={() => tryHandle()} />
          <img src={handleShadow} alt="Handle shadow" className={style.handleShadow} />
          <img src={digits} alt="Digits" className={style.digits} />
          <div className={classNames(style.screen, style[`screen${screenMode}`])}>{screenText}</div>
          {buttons}
        </>
      )}
    </GameItem>
  );
};
