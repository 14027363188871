import React from 'react';
import classNames from 'classnames';
import { useDrag, DragSourceMonitor } from 'react-dnd';

import style from './Synthesis.module.css';

import beakerBlue from './assets/tube_blue.png';
import beakerGreen from './assets/tube_green.png';
import beakerPurple from './assets/tube_purple.png';
import beakerYellow from './assets/tube_yellow.png';
import beakerRed from './assets/tube_red.png';
import beakerGrey from './assets/tube_grey.png';
import beakerOrange from './assets/tube_orange.png';

export const beakerImages = [
  beakerBlue,
  beakerGreen,
  beakerPurple,
  beakerYellow,
  beakerRed,
  beakerGrey,
  beakerOrange
];

export function getBeakerImg(color: string) {
  switch (color) {
    case 'Blue':
      return beakerBlue;
    case 'Green':
      return beakerGreen;
    case 'Purple':
      return beakerPurple;
    case 'Yellow':
      return beakerYellow;
    case 'Red':
      return beakerRed;
    case 'Grey':
      return beakerGrey;
    case 'Orange':
      return beakerOrange;
    default:
      return beakerYellow;
  }
}

interface BeakerImageProps {
  color: string;
}

export const BeakerImage: React.FC<BeakerImageProps> = ({ color }) => {
  const img = getBeakerImg(color);
  return (
    <div
      style={{ backgroundImage: `url(${img})` }}
      className={classNames(style.beaker, style[`beaker${color}`])}
    />
  );
};

interface BeakerProps {
  color: string;
  onDrop: (color: string) => void;
}

export const Beaker: React.FC<BeakerProps> = ({ color, onDrop }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { name: `Beaker ${color}`, type: 'beaker', color },
    end: (item: { name: string } | undefined, monitor: DragSourceMonitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onDrop(color);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;

  return (
    <div
      ref={drag}
      style={{ opacity }}
      className={classNames(style.beakerDrag, style[`beakerDrag${color}`])}
    >
      <BeakerImage color={color} />
    </div>
  );
};
