import React, { useState, useEffect, useCallback, useRef } from 'react';

import { ClassicButton, GameBoard, sounds } from '../components';
import { CutsceneImage } from './CutsceneImage';
import { useCurrentPlayer } from '../state';
import { CreditsImage } from './Credits';

import style from './Cutscene.module.css';

import { CUTSCENE1 } from './cutscene1';
import { CUTSCENE2 } from './cutscene2';
import { CUTSCENE3 } from './cutscene3';
import { CUTSCENE4 } from './cutscene4';
import { CUTSCENE5 } from './cutscene5';
import { CUTSCENE6 } from './cutscene6';

interface CutsceneProps {
  nextLevel: string;
}

const getCutsceneEvents = (nextLevel: string) => {
  const events = (() => {
    switch (nextLevel) {
      case '1':
        return CUTSCENE1;
      case '2':
        return CUTSCENE2;
      case '3':
        return CUTSCENE3;
      case '4':
        return CUTSCENE4;
      case '5':
        return CUTSCENE5;
      case '6':
        return CUTSCENE6;
      default:
        return CUTSCENE1;
    }
  })() as typeof CUTSCENE1;
  return events;
};

export const getCutsceneImagesToPreload = (cutscene: string) => {
  const events = getCutsceneEvents(cutscene);
  const images = events.map(event => event.type === 'image' ? event.src : '').filter(x => !!x);
  return images as string[];
};

export const Cutscene = ({ nextLevel }: CutsceneProps) => {
  const events = getCutsceneEvents(nextLevel);
  const [index, setIndex] = useState(0);
  const [visibility, setVisibility] = useState(events.map(e => !!e.visible));
  const [, { changeLevel }] = useCurrentPlayer();

  const soundCancelationQueue = useRef<any>([]);

  const doContinue = useCallback(() => {
    changeLevel(nextLevel);
    sounds.mystery();
    setTimeout(() => window.scrollTo(0, 0), 1);
    soundCancelationQueue.current.forEach((cancel: any) => cancel());
  }, [changeLevel, nextLevel]);

  const triggerNextEvent = () => {
    const nextEvent = events[index];
    if (nextEvent.type === 'image' || nextEvent.type === 'credits') {
      visibility[index] = true;
      setVisibility([...visibility]);
    }
    if (nextEvent.type === 'sound') {
      soundCancelationQueue.current.push(sounds[nextEvent.src as string](nextEvent.volume));
    }
    if (nextEvent.type === 'page-end') {
      setVisibility([...visibility.map(v => false)]);
    }

    if (index < events.length - 1) {
      setIndex(index + 1);
    }
  };

  useEffect(() => {
    // triggers the next event after a delay
    const timeout = setTimeout(() => triggerNextEvent(), events[index].delay);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  return (
    <GameBoard>
      <div className={style.cutscene}>
        {events.map((event: any, i: number) => {
          if (event.type === 'image') {
            return (
              <CutsceneImage
                key={`event_${i}`}
                src={event.src}
                visible={visibility[i]}
                top={event.top}
                left={event.left}
                width={event.width}
                zIndex={event.zIndex}
                animationIn={event.animationIn}
                animationSpeed={event.animationSpeed}
              />
            );
          }
          if (event.type === 'credits') {
            return (
              <CreditsImage
                key={`event_${i}`}
                height={event.height}
                src={event.src}
                visible={visibility[i]}
              />
            );
          }

          return <></>;
        })}
        <ClassicButton
          className={style.continueButton}
          size="small"
          style={{ opacity: index === visibility.length - 1 ? 1 : 0 }}
          onClick={() => doContinue()}
        >
          Continue
        </ClassicButton>
      </div>
    </GameBoard>
  );
};
