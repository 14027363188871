import React from 'react';
import classNames from 'classnames';
import { useDrop } from 'react-dnd';

import style from './BlindMice.module.css';

interface DropzoneProps {
  base?: boolean;
  x: number;
  y: number;
}

export const Dropzone: React.FC<DropzoneProps> = ({ x, y, base }) => {
  const [, drop] = useDrop({
    accept: 'arrow',
    drop: () => ({ type: 'grid', x, y, base }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  return (
    <div
      ref={drop}
      className={classNames(style.dropzone, base && style.dropzoneBase)}
      style={base ? {} : {
        left: `${37.11 + 4.09 * x}vw`,
        top: `${9.25 + 4.09 * y}vw`
      }}
    />
  );
};
