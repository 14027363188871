import React, { useCallback, useState } from 'react';

import { CartoonButton, GameItem, sounds, speech } from '../../../components';
import { useMobileState } from './mobileState';

import style from './Mobile.module.css';

import background from './assets/mobile.png';

import dotOpen from './assets/dotOpen.png';
import dotClosed from './assets/dotClosed.png';

import button1 from './assets/1Normal.png';
import button2 from './assets/2Normal.png';
import button3 from './assets/3Normal.png';
import button4 from './assets/4Normal.png';
import button5 from './assets/5Normal.png';
import button6 from './assets/6Normal.png';
import button7 from './assets/7Normal.png';
import button8 from './assets/8Normal.png';
import button9 from './assets/9Normal.png';
import button0 from './assets/0Normal.png';
import buttonStar from './assets/starNormal.png';
import buttonHash from './assets/hashNormal.png';
import buttonCall from './assets/callNormal.png';
import buttonUndo from './assets/undoNormal.png';

import button1Hover from './assets/1Hover.png';
import button2Hover from './assets/2Hover.png';
import button3Hover from './assets/3Hover.png';
import button4Hover from './assets/4Hover.png';
import button5Hover from './assets/5Hover.png';
import button6Hover from './assets/6Hover.png';
import button7Hover from './assets/7Hover.png';
import button8Hover from './assets/8Hover.png';
import button9Hover from './assets/9Hover.png';
import button0Hover from './assets/0Hover.png';
import buttonStarHover from './assets/starHover.png';
import buttonHashHover from './assets/hashHover.png';
import buttonCallHover from './assets/callHover.png';
import buttonUndoHover from './assets/undoHover.png';

import button1Pressed from './assets/1Pressed.png';
import button2Pressed from './assets/2Pressed.png';
import button3Pressed from './assets/3Pressed.png';
import button4Pressed from './assets/4Pressed.png';
import button5Pressed from './assets/5Pressed.png';
import button6Pressed from './assets/6Pressed.png';
import button7Pressed from './assets/7Pressed.png';
import button8Pressed from './assets/8Pressed.png';
import button9Pressed from './assets/9Pressed.png';
import button0Pressed from './assets/0Pressed.png';
import buttonStarPressed from './assets/starPressed.png';
import buttonHashPressed from './assets/hashPressed.png';
import buttonCallPressed from './assets/callPressed.png';
import buttonUndoPressed from './assets/undoPressed.png';

export const mobileImages = [
  background,
  dotOpen,
  dotClosed,
  button1,
  button2,
  button3,
  button4,
  button5,
  button6,
  button7,
  button8,
  button9,
  button0,
  buttonStar,
  buttonHash,
  buttonCall,
  buttonUndo,
  button1Hover,
  button2Hover,
  button3Hover,
  button4Hover,
  button5Hover,
  button6Hover,
  button7Hover,
  button8Hover,
  button9Hover,
  button0Hover,
  buttonStarHover,
  buttonHashHover,
  buttonCallHover,
  buttonUndoHover,
  button1Pressed,
  button2Pressed,
  button3Pressed,
  button4Pressed,
  button5Pressed,
  button6Pressed,
  button7Pressed,
  button8Pressed,
  button9Pressed,
  button0Pressed,
  buttonStarPressed,
  buttonHashPressed,
  buttonCallPressed,
  buttonUndoPressed
];

const hints = [
  'Solving the puzzle requires a few steps. First, it will require the mobile phone in Old Dog’s room and the newspaper and phone book in Ally’s room.',
  'You need to use the phone, but it’s password protected. How to unlock it? Maybe Ally can find some helpful information in the newspaper?',
  'Ally will read an article on the most common number combination used to lock phones in the newspaper. Maybe it’s worth trying on the phone you have?',
  'When you unlock the phone by using „1234” code, you should call Gustav. But do you have Gustav’s number? Maybe Ally can find it somewhere?',
  'If Ally looks closely in the phone book, she will notice a number to Gustav Marmont. Maybe it’s worth trying this number?',
  'When you dial the correct telephone number, you will hear the voicemail saying number 8.'
];

export const Mobile = () => {
  const [combination, setCombination] = useState('');
  const [enteredNumber, setEnteredNumber] = useState('');
  const [ringing, setRinging] = useState(false);
  const [{ unlocked }, { onUnlocked }] = useMobileState();

  // FIXME: refactor this callback, it's primitive
  const onClick = useCallback((button: string) => {
    if (!unlocked) {
      const newCombination = combination + button;
      if (newCombination === '1234') {
        sounds.success();
        onUnlocked();
      } else if (newCombination.length >= 4) {
        setCombination('');
        sounds.error();
      } else {
        setCombination(newCombination);
      }
      sounds.beep2();
      return;
    }

    if (ringing) {
      return;
    }

    // on unlocked
    if (button === 'UNDO') {
      setEnteredNumber(enteredNumber.length > 0 ? enteredNumber.substr(0, enteredNumber.length - 1) : '');
    } else if (button === 'CALL' && enteredNumber === '9567544') {
      setRinging(true);
      sounds.phoneRingingShort();
      setTimeout(() => speech.say('l2Phone'), 2000);
      setTimeout(() => setRinging(false), 3000);
    } else if (button === 'CALL') {
      setRinging(true);
      sounds.phoneRingingShort();
      setTimeout(() => speech.say('l2PhoneNotFound'), 2000);
      setTimeout(() => setRinging(false), 3000);
    } else if (enteredNumber.length >= 7) {
      sounds.keypadError();
    } else if (['1', '4', '7', '*'].indexOf(button) !== -1) {
      sounds.phoneTone();
      setEnteredNumber(enteredNumber + button);
    } else if (['2', '5', '8', '0'].indexOf(button) !== -1) {
      sounds.phoneTone2();
      setEnteredNumber(enteredNumber + button);
    } else if (['3', '6', '9', '#'].indexOf(button) !== -1) {
      sounds.phoneTone3();
      setEnteredNumber(enteredNumber + button);
    }
  }, [unlocked, onUnlocked, combination, enteredNumber, ringing]);

  return (
    <GameItem
      name="l2Mobile"
      imageSrc={background}
      hints={hints}
      iconStyle={{
        top: '30vw',
        left: '46vw',
        width: '20vw',
        height: '11vw'
      }}
    >
      <div className={style.mobileScreen}>
        {unlocked ? (
          <>
            <div className={style.enterNumber}>{enteredNumber}</div>
            <CartoonButton src={button1} hoverSrc={button1Hover} activeSrc={button1Pressed} onClick={() => onClick('1')} style={{ left: '1.5vw', top: '5vw', width: '3vw' }} />
            <CartoonButton src={button2} hoverSrc={button2Hover} activeSrc={button2Pressed} onClick={() => onClick('2')} style={{ left: '5.0vw', top: '5vw', width: '3vw' }} />
            <CartoonButton src={button3} hoverSrc={button3Hover} activeSrc={button3Pressed} onClick={() => onClick('3')} style={{ left: '8.5vw', top: '5vw', width: '3vw' }} />
            <CartoonButton src={button4} hoverSrc={button4Hover} activeSrc={button4Pressed} onClick={() => onClick('4')} style={{ left: '1.5vw', top: '8.5vw', width: '3vw' }} />
            <CartoonButton src={button5} hoverSrc={button5Hover} activeSrc={button5Pressed} onClick={() => onClick('5')} style={{ left: '5.0vw', top: '8.5vw', width: '3vw' }} />
            <CartoonButton src={button6} hoverSrc={button6Hover} activeSrc={button6Pressed} onClick={() => onClick('6')} style={{ left: '8.5vw', top: '8.5vw', width: '3vw' }} />
            <CartoonButton src={button7} hoverSrc={button7Hover} activeSrc={button7Pressed} onClick={() => onClick('7')} style={{ left: '1.5vw', top: '12vw', width: '3vw' }} />
            <CartoonButton src={button8} hoverSrc={button8Hover} activeSrc={button8Pressed} onClick={() => onClick('8')} style={{ left: '5.0vw', top: '12vw', width: '3vw' }} />
            <CartoonButton src={button9} hoverSrc={button9Hover} activeSrc={button9Pressed} onClick={() => onClick('9')} style={{ left: '8.5vw', top: '12vw', width: '3vw' }} />
            <CartoonButton src={button0} hoverSrc={button0Hover} activeSrc={button0Pressed} onClick={() => onClick('0')} style={{ left: '5vw', top: '15.5vw', width: '3vw' }} />
            <CartoonButton src={buttonStar} hoverSrc={buttonStarHover} activeSrc={buttonStarPressed} onClick={() => onClick('*')} style={{ left: '1.5vw', top: '15.5vw', width: '3vw' }} />
            <CartoonButton src={buttonHash} hoverSrc={buttonHashHover} activeSrc={buttonHashPressed} onClick={() => onClick('#')} style={{ left: '8.5vw', top: '15.5vw', width: '3vw' }} />
            <CartoonButton src={buttonCall} hoverSrc={buttonCallHover} activeSrc={buttonCallPressed} onClick={() => onClick('CALL')} style={{ left: '2vw', top: '19.1vw', width: '6vw' }} />
            <CartoonButton src={buttonUndo} hoverSrc={buttonUndoHover} activeSrc={buttonUndoPressed} onClick={() => onClick('UNDO')} style={{ left: '8.9vw', top: '18.9vw', width: '2vw' }} />
          </>
        ) : (
          <>
            <div className={style.enterPasscode}>Enter Passcode</div>
            <img className={style.dotOpen} src={dotOpen} alt="Dot" style={{ left: '4vw' }} />
            <img className={style.dotOpen} src={dotOpen} alt="Dot" style={{ left: '5.5vw' }} />
            <img className={style.dotOpen} src={dotOpen} alt="Dot" style={{ left: '7vw' }} />
            <img className={style.dotOpen} src={dotOpen} alt="Dot" style={{ left: '8.5vw' }} />
            <img className={style.dotClosed} src={dotClosed} alt="Dot" style={{ left: '4vw', opacity: combination.length > 0 ? 1 : 0 }} />
            <img className={style.dotClosed} src={dotClosed} alt="Dot" style={{ left: '5.5vw', opacity: combination.length > 1 ? 1 : 0 }} />
            <img className={style.dotClosed} src={dotClosed} alt="Dot" style={{ left: '7vw', opacity: combination.length > 2 ? 1 : 0 }} />
            <CartoonButton src={button1} hoverSrc={button1Hover} activeSrc={button1Pressed} onClick={() => onClick('1')} style={{ left: '1.5vw', top: '8vw', width: '3vw' }} />
            <CartoonButton src={button2} hoverSrc={button2Hover} activeSrc={button2Pressed} onClick={() => onClick('2')} style={{ left: '5.0vw', top: '8vw', width: '3vw' }} />
            <CartoonButton src={button3} hoverSrc={button3Hover} activeSrc={button3Pressed} onClick={() => onClick('3')} style={{ left: '8.5vw', top: '8vw', width: '3vw' }} />
            <CartoonButton src={button4} hoverSrc={button4Hover} activeSrc={button4Pressed} onClick={() => onClick('4')} style={{ left: '1.5vw', top: '11.5vw', width: '3vw' }} />
            <CartoonButton src={button5} hoverSrc={button5Hover} activeSrc={button5Pressed} onClick={() => onClick('5')} style={{ left: '5.0vw', top: '11.5vw', width: '3vw' }} />
            <CartoonButton src={button6} hoverSrc={button6Hover} activeSrc={button6Pressed} onClick={() => onClick('6')} style={{ left: '8.5vw', top: '11.5vw', width: '3vw' }} />
            <CartoonButton src={button7} hoverSrc={button7Hover} activeSrc={button7Pressed} onClick={() => onClick('7')} style={{ left: '1.5vw', top: '15vw', width: '3vw' }} />
            <CartoonButton src={button8} hoverSrc={button8Hover} activeSrc={button8Pressed} onClick={() => onClick('8')} style={{ left: '5.0vw', top: '15vw', width: '3vw' }} />
            <CartoonButton src={button9} hoverSrc={button9Hover} activeSrc={button9Pressed} onClick={() => onClick('9')} style={{ left: '8.5vw', top: '15vw', width: '3vw' }} />
            <CartoonButton src={button0} hoverSrc={button0Hover} activeSrc={button0Pressed} onClick={() => onClick('0')} style={{ left: '5vw', top: '18.5vw', width: '3vw' }} />
          </>
        )}
      </div>
    </GameItem>
  );
};
