import page1_01 from './assets/page1_01.png';
import page1_02 from './assets/page1_02.png';
import page1_03 from './assets/page1_03.png';
import page1_04 from './assets/page1_04.png';
import page1_txt1 from './assets/page1_txt1.png';
import page1_txt2 from './assets/page1_txt2.png';
import page1_txt3 from './assets/page1_txt3.png';
import page1_txt4 from './assets/page1_txt4.png';
import page1_txt5 from './assets/page1_txt5.png';
import page1_txt6 from './assets/page1_txt6.png';
import page1_txt7 from './assets/page1_txt7.png';
import page2_pic1 from './assets/page2_pic1.png';
import page2_pic2 from './assets/page2_pic2.png';
import page2_pic3 from './assets/page2_pic3.png';
import page2_txt1 from './assets/page2_txt1.png';
import page2_txt2 from './assets/page2_txt2.png';
import page2_txt3 from './assets/page2_txt3.png';
import credits from './assets/credits.png';

const WIDTH = 2800;

export const CUTSCENE6 = [
  { type: 'sound', src: 'outro', delay: 0, volume: 0.3 },
  { type: 'sound', src: 'sirens', delay: 0, volume: 0.2 },
  { type: 'image', src: page1_01, delay: 100, top: 2, left: 0, width: (564 / WIDTH) * 100 },
  { type: 'sound', src: 'paper2', delay: 500 },
  { type: 'image', src: page1_txt1, delay: 1500, top: 0, left: 0, width: (559 / WIDTH) * 100 },
  { type: 'sound', src: 'readingDocuments', delay: 200 },
  { type: 'image', src: page1_txt2, delay: 1400, top: 9.5, left: 3, width: (437 / WIDTH) * 100 },
  { type: 'image', src: page1_txt3, delay: 1500, top: 18, left: -2, width: (787 / WIDTH) * 100, zIndex: 2 },
  { type: 'image', src: page1_02, delay: 1000, top: 2, left: 21.5, width: (660 / WIDTH) * 100 },
  { type: 'image', src: page1_txt4, delay: 1000, top: 1, left: 22, width: (462 / WIDTH) * 100 },
  { type: 'image', src: page1_03, delay: 1000, top: 2, left: 46, width: (755 / WIDTH) * 100 },
  { type: 'image', src: page1_txt6, delay: 200, top: 6, left: 50, width: (334 / WIDTH) * 100 },
  { type: 'sound', src: 'voice29', delay: 200 },
  { type: 'image', src: page1_txt5, delay: 500, top: 10, left: 20, width: (787 / WIDTH) * 100 },
  { type: 'image', src: page1_txt7, delay: 2000, top: 66, left: 43, width: (564 / WIDTH) * 100 },
  { type: 'sound', src: 'weKnowTheirFather', delay: 200 },
  { type: 'image', src: page1_04, delay: 2000, top: 2, left: 74, width: (726 / WIDTH) * 100 },
  { type: 'page-end', delay: 4000 },
  { type: 'image', src: page2_pic1, delay: 2000, top: 2, left: 0, width: (1320 / WIDTH) * 100 },
  { type: 'sound', src: 'carDoorClose', delay: 1000 },
  { type: 'sound', src: 'driveAway', delay: 2000 },
  { type: 'image', src: page2_pic2, delay: 3000, top: 2, left: 48.5, width: (1449 / WIDTH) * 100 },
  { type: 'image', src: page2_txt1, delay: 2000, top: 0, left: 33, width: (811 / WIDTH) * 100 },
  { type: 'sound', src: 'areWeGoingWhereIThink', delay: 200 },
  { type: 'image', src: page2_txt2, delay: 2000, top: 0, left: 87, width: (270 / WIDTH) * 100 },
  { type: 'sound', src: 'voice33', delay: 200 },
  { type: 'image', src: page2_pic3, delay: 2000, top: 32, left: 47.5, width: (1467 / WIDTH) * 100 },
  { type: 'image', src: page2_txt3, delay: 1000, top: 30, left: 52, width: (607 / WIDTH) * 100 },
  { type: 'sound', src: 'voice34', delay: 200 },
  { type: 'page-end', delay: 4000 },
  { type: 'credits', src: credits, delay: 1000, height: 150 },
];
