import React, { useState } from 'react';
import { message } from 'antd';
import globalHook from 'use-global-hook';
import Cookies from 'universal-cookie';
import { db, useFirestoreQuery } from '../firebase';

const cookies = new Cookies();

type AccessCode = {
  code: string;
}

type AccessCodeResult = [{
  loading: boolean;
  code: string;
}, {
  setCode: (code: string) => void;
}];

const useGlobal = globalHook<any, any>(React, { code: cookies.get('accessCode') || '' }, {
  setCode: (store: any, code: any) => {
    store.setState({ code });
  }
});

export function useFirestoreAccessCode() {
  const [{ code }] = useGlobal();
  return useFirestoreQuery<AccessCode>(
    db.collection('accessCode')
      .doc(code || '-')
  );
}

export function useAccessCode(): AccessCodeResult {
  const [{ code }, { setCode: setGlobalCode }] = useGlobal();
  const [loading, setLoading] = useState(false);

  const setCode = (newCode: string) => {
    setLoading(true);
    // determine if access code is valid
    db.collection('accessCode')
      .doc(newCode)
      .get()
      .then((doc) => {
        const data = doc.data();
        if (data) {
          setGlobalCode(newCode);
          cookies.set('accessCode', newCode, {
            path: '/',
            maxAge: 60 * 60 * 3 // in seconds
          });
        } else {
          message.error('License Key not found');
        }
      })
      .catch((err) => {
        message.error('Unknown error occurred, please try again');
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  return [{ code, loading }, { setCode }];
}
