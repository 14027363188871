import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { Modal } from 'antd';
import { useStoreState, useStoreActions } from 'easy-peasy';
import CanvasDraw from 'react-canvas-draw';
import { isUndefined } from 'lodash';
import { Controls, speech } from '..';
import { Hints } from './Hints';
import { useCurrentPlayer, useDebugMode } from '../../state';

import style from './GameModal.module.css';

interface GameModalProps {
  visible: boolean;
  onCloseModal: () => void;
  name: string;
  canvas?: boolean;
  canvasColor?: string;
  description?: string;
  imageSrc?: string;
  imageClassName?: string;
  destroyOnClose?: boolean;
  hints?: React.ReactNode[];
  onReset?: () => void;
  speech?: string;
}

export const GameModal: FunctionComponent<GameModalProps> = (props) => {
  const loadedCanvas = useRef<CanvasDraw | null>();
  const { canvas, name, onCloseModal, description, imageSrc, visible } = props;
  const [showHints, setShowHints] = useState(false);
  const savedDrawings = useStoreState(state => state.canvas.drawings);
  const windowSize = useStoreState(state => state.resize);
  const saveDrawing = useStoreActions((state: any) => state.canvas.set);
  const [, { logAction }] = useCurrentPlayer();
  const [{ debugMode }] = useDebugMode();
  const hints = props.hints || [];

  useEffect(() => {
    if (!name || !loadedCanvas) return;
    if (savedDrawings[name]) {
      setTimeout(() => {
        // no idea why I need timeout, only way to make it work
        loadedCanvas.current && loadedCanvas.current.loadSaveData(savedDrawings[name], true);
      }, 100);
    }
    // eslint-disable-next-line
  }, [visible, loadedCanvas, name, visible, showHints]);

  function saveCanvasData() {
    if (name && loadedCanvas.current) {
      const drawing = loadedCanvas.current.getSaveData();
      saveDrawing({ key: name, drawing });
    }
  }

  function onClose() {
    if (showHints) {
      logAction('hide-hints', props.name);
      return setShowHints(false);
    }

    saveCanvasData();
    return onCloseModal();
  }

  function onClearCanvas() {
    if (loadedCanvas.current) {
      loadedCanvas.current.clear();
      logAction('clear-canvas', props.name);
    }
  }

  function onUndoCanvas() {
    if (loadedCanvas.current) {
      loadedCanvas.current.undo();
    }
  }

  return (
    <>
      <Modal
        transitionName=""
        maskTransitionName=""
        className="transparent"
        visible={visible}
        onCancel={onClose}
        width="100%"
        mask={true}
        maskStyle={{ backgroundColor: showHints ? 'rgba(0, 0, 0, 0.5)' : 'rgba(40, 40, 40, 0)' }}
        maskClosable={true}
        closable={true}
        title={description}
        closeIcon={<></>}
        footer={false}
        destroyOnClose={isUndefined(props.destroyOnClose) ? true : props.destroyOnClose}
        bodyStyle={{ padding: 0 }}
      >
        <>
          {canvas && !showHints && (
            <CanvasDraw
              className={style.canvas}
              backgroundColor="transparent"
              brushColor={props.canvasColor || '#20D000'}
              hideGrid={true}
              hideInterface={true}
              lazyRadius={1}
              brushRadius={1.5}
              canvasHeight={windowSize.height}
              canvasWidth={windowSize.width - Math.floor(windowSize.width / 14)}
              ref={(cnvs) => {
                loadedCanvas.current = cnvs;
              }}
            />
          )}
          <div className={style.modalBody}>
            {imageSrc && !showHints && (
              <img
                src={imageSrc}
                onDragStart={ev => ev.preventDefault()}
                alt="Background img"
                className={classNames(style.image, props.imageClassName)}
              />
            )}
            {!showHints && props.children}
            {showHints && (<Hints itemName={name} hints={hints} />)}
          </div>
          <Controls
            hint={!showHints && hints.length > 0}
            onHint={() => {
              saveCanvasData();
              setShowHints(!showHints);
              logAction(showHints ? 'hide-hints' : 'show-hints', props.name);
            }}
            speech={!showHints && !!props.speech}
            onSpeech={() => speech.say(props.speech!)}
            clear={canvas && !showHints}
            onClear={onClearCanvas}
            undo={canvas && !showHints}
            onUndo={onUndoCanvas}
            reset={debugMode && !!props.onReset}
            onReset={props.onReset}
            close={true}
            onClose={onClose}
          />
        </>
      </Modal>
    </>
  );
};
