import React from 'react';
import classNames from 'classnames';
import { useComputerState } from './computerState';
import style from './Computer.module.css';

export const SnakeInterface = () => {
  const [{ snakeWeight }, { onButtonPress }] = useComputerState();

  return (
    <div className={style.screen}>
      <div className={style.window}>
        <div className={style.header}>Snake Weightmate App</div>
        <div className={style.body}>
          <p>Enter snake's weight:</p>
          <p>{snakeWeight || '0'}g</p>
        </div>
        <div className={style.keypad}>
          <button type="button" onClick={() => onButtonPress('1')} className={classNames(style.button, style.button1)}>1</button>
          <button type="button" onClick={() => onButtonPress('2')} className={classNames(style.button, style.button2)}>2</button>
          <button type="button" onClick={() => onButtonPress('3')} className={classNames(style.button, style.button3)}>3</button>
          <button type="button" onClick={() => onButtonPress('4')} className={classNames(style.button, style.button4)}>4</button>
          <button type="button" onClick={() => onButtonPress('5')} className={classNames(style.button, style.button5)}>5</button>
          <button type="button" onClick={() => onButtonPress('6')} className={classNames(style.button, style.button6)}>6</button>
          <button type="button" onClick={() => onButtonPress('7')} className={classNames(style.button, style.button7)}>7</button>
          <button type="button" onClick={() => onButtonPress('8')} className={classNames(style.button, style.button8)}>8</button>
          <button type="button" onClick={() => onButtonPress('9')} className={classNames(style.button, style.button9)}>9</button>
          <button type="button" onClick={() => onButtonPress('0')} className={classNames(style.button, style.button0)}>0</button>
          <button type="button" onClick={() => onButtonPress('CLR')} className={classNames(style.button, style.buttonCLR)}>CLR</button>
          <button type="button" onClick={() => onButtonPress('OK')} className={classNames(style.button, style.buttonOK)}>OK</button>
        </div>
      </div>
    </div>
  );
};
