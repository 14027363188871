import React, { useState, useEffect, useRef } from 'react';
import { useStoreState } from 'easy-peasy';

import { GameItem, CartoonButton, sounds } from '../../../components';
import { useCurrentPlayer } from '../../../state';
import { useArcade, DEFAULT_POSITION } from './arcadeState';

import style from './Arcade.module.css';

import arcade from './assets/arcade.png';
import arcadeControls from './assets/arcadeControls.png';

import joystick from './assets/joystick.png';
import joystickCover from './assets/joystickCover.png';
import joystickUp from './assets/joystickUp.png';
import joystickDown from './assets/joystickDown.png';
import joystickLeft from './assets/joystickLeft.png';
import joystickRight from './assets/joystickRight.png';
import arm from './assets/arm.png';
import armPart from './assets/armPart.png';
import probe from './assets/arcadeProbe.png';
import probeWet from './assets/arcadeProbeBlue.png';
import probeSolutionTop from './assets/probeSolutionTop.png';
import probeSolutionBottom from './assets/probeSolutionBottom.png';
import rowBtm from './assets/rowBottom.png';
import rowMid from './assets/rowMiddle.png';
import rowTop from './assets/rowTop.png';
import glowLeft from './assets/glowLeft.png';
import glowRight from './assets/glowRight.png';

import buttonUpNormal from './assets/buttonUp.png';
import buttonDownNormal from './assets/buttonDown.png';
import buttonLeftNormal from './assets/buttonLeft.png';
import buttonRightNormal from './assets/buttonRight.png';
import buttonRaiseNormal from './assets/buttonRaise.png';
import buttonLowerNormal from './assets/buttonLower.png';

import buttonUpClick from './assets/buttonUpPressed.png';
import buttonDownClick from './assets/buttonDownPressed.png';
import buttonLeftClick from './assets/buttonLeftPressed.png';
import buttonRightClick from './assets/buttonRightPressed.png';
import buttonRaiseClick from './assets/buttonRaisePressed.png';
import buttonLowerClick from './assets/buttonLowerPressed.png';

export const arcadeImages = [
  arcade,
  arcadeControls,
  joystick,
  joystickCover,
  joystickUp,
  joystickDown,
  joystickLeft,
  joystickRight,
  glowLeft,
  glowRight,
  arm,
  armPart,
  probe,
  probeWet,
  probeSolutionTop,
  probeSolutionBottom,
  rowBtm,
  rowMid,
  rowTop,
  buttonUpNormal,
  buttonDownNormal,
  buttonLeftNormal,
  buttonRightNormal,
  buttonRaiseNormal,
  buttonLowerNormal,
  buttonUpClick,
  buttonDownClick,
  buttonLeftClick,
  buttonRightClick,
  buttonRaiseClick,
  buttonLowerClick
];

const hintsOldDog = [
  'You need a mechanical arm controller in Ally’s room and a robotic arm in Old Dog’s room to solve this puzzle.',
  'You should move the testing tube somewhere. But where? Does any of the holes differ? Guide Ally to move the testing tube there.',
  'The testing tube is covered in blue liquid after putting it into the blue hole. What to do next? Maybe the lamps will tell you? Collaborate with Ally.',
  'When you put the testing tube covered with blue liquids under the lamps, you see the same geometric shapes as on the lock. Should they be on the top or bottom? The lights will tell you.',
  'The solution is top - square, bottom - rhombus.'
];

const hintsAlly = [
  'You need a mechanical arm controller in Ally’s room and a robotic arm in Old Dog’s room to solve this puzzle.',
  'You should use the controller to move a mechanical arm in the Old Dog’s room. ',
  'Old Dog should guide you on what movements you should make. Follow his guidance, and you will solve the puzzle.',
  'The solution is top - square, bottom - rhombus.'
];

const getJoystick1Image = (button: string) => {
  switch (button) {
    case 'Up':
      return joystickUp;
    case 'Down':
      return joystickDown;
    case 'Left':
      return joystickLeft;
    case 'Right':
      return joystickRight;
    default:
      return joystick;
  }
};

const getJoystick2Image = (button: string) => {
  switch (button) {
    case 'Raise':
      return joystickUp;
    case 'Lower':
      return joystickDown;
    default:
      return joystick;
  }
};

export const Arcade = () => {
  const [{ isOldDog }] = useCurrentPlayer();
  const [pressedButton, setPressedButton] = useState('');
  const [zIndex, setZIndex] = useState({ top: 5, mid: 5, bottom: 5 });
  const [{ handX, handY, handZ, grabbed, wet }, { move }] = useArcade();
  const openModal = useStoreState(state => state.app.modalOpen);

  const modalName = `l4Arcade${isOldDog ? 'OldDog' : 'Ally'}`;

  const onDown = (button: string) => setPressedButton(button);
  const onUp = (button: string) => setPressedButton('');
  const onClick = (button: string) => move(button);

  const isModalOpen = openModal === modalName;

  const prevPosition = useRef(DEFAULT_POSITION);
  useEffect(() => {
    const robotMoved = handX !== prevPosition.current.handX || handY !== prevPosition.current.handY || handZ !== prevPosition.current.handZ;
    if (robotMoved) {
      sounds.robotMove(isModalOpen ? 0.2 : 0.1);
      prevPosition.current = { handX, handY, handZ };
    }
  }, [handX, handY, handZ, isModalOpen]);

  const handZPrevState = useRef<number>();
  useEffect(() => {
    if (handZPrevState.current !== handZ) { // act only on raise / lower
      const isGoingUp = (handZPrevState.current || 0) > 0;
      handZPrevState.current = handZ;

      if (isGoingUp && grabbed) {
        setTimeout(() => setZIndex({ top: 2, mid: 2, bottom: 2 }), 500);
      }
      if (!isGoingUp && grabbed) {
        if (handY === 2) {
          setZIndex({ top: 2, mid: 2, bottom: 5 });
        }
        if (handY === 1) {
          setZIndex({ top: 2, mid: 5, bottom: 2 });
        }
        if (handY === 0) {
          setZIndex({ top: 5, mid: 2, bottom: 2 });
        }
      }
    }
  }, [handZ, handY, grabbed]);

  const robotArmPosition = {
    left: 30.2 + 5.95 * handX + (handX === 1 ? 0.5 : 0) + (handX === 2 ? -0.65 : 0) + (handX === 4 ? -1.5 : 0),
    top: 12.0 + handY * 4 + handZ * 5
  };

  const probePosition = grabbed ? {
    left: robotArmPosition.left + 3.35,
    top: robotArmPosition.top + 11.9
  } : {
    left: 40.15,
    top: 29
  };

  const solvedRight = grabbed && wet && handX === 5 && handY === 0;
  const solvedLeft = grabbed && wet && handX === 0 && handY === 0;

  return (
    <GameItem
      name={modalName}
      imageSrc={isOldDog ? arcade : arcadeControls}
      imageClassName={style.arcadeImage}
      hints={isOldDog ? hintsOldDog : hintsAlly}
      iconStyle={isOldDog ? {
        top: '5vw',
        left: '45vw',
        width: '28vw',
        height: '23vw'
      } : {
        top: '0.5vw',
        left: '44vw',
        width: '23vw',
        height: '38vw'
      }}
    >
      {isOldDog ? (
        <>
          <img src={wet ? probeWet : probe} alt="Probe" className={style.probe} style={{ top: `${probePosition.top}vw`, left: `${probePosition.left}vw`}} onDragStart={ev => ev.preventDefault()} />
          <img src={probeSolutionTop} alt="Probe Solution Top" className={style.probe} style={{ top: `${probePosition.top}vw`, left: `${probePosition.left}vw`, opacity: solvedRight ? 1 : 0 }} onDragStart={ev => ev.preventDefault()} />
          <img src={probeSolutionBottom} alt="Probe Solution Bottom" className={style.probe} style={{ top: `${probePosition.top}vw`, left: `${probePosition.left}vw`, opacity: solvedLeft ? 1 : 0 }} onDragStart={ev => ev.preventDefault()} />
          <img src={arm} alt="Robot arm" className={style.robotArm} style={{ top: `${robotArmPosition.top}vw`, left: `${robotArmPosition.left}vw`}} onDragStart={ev => ev.preventDefault()} />
          <img src={armPart} alt="Robot arm Part" className={style.robotArmPart} style={{ top: `${robotArmPosition.top}vw`, left: `${robotArmPosition.left}vw`, opacity: grabbed ? 0 : 1 }} onDragStart={ev => ev.preventDefault()} />
          <img src={rowBtm} alt="Row Btm" className={style.row1} style={{ zIndex: zIndex.bottom }} onDragStart={ev => ev.preventDefault()} />
          <img src={rowMid} alt="Row Mid" className={style.row2} style={{ zIndex: zIndex.mid }} onDragStart={ev => ev.preventDefault()} />
          <img src={rowTop} alt="Row Top" className={style.row3} style={{ zIndex: zIndex.top }} onDragStart={ev => ev.preventDefault()} />
          <img src={glowLeft} alt="Glow Left" className={style.glowLeft} style={{ zIndex: solvedLeft ? 8 : 0 }} onDragStart={ev => ev.preventDefault()} />
          <img src={glowRight} alt="Glow Right" className={style.glowRight} style={{ zIndex: solvedRight ? 8 : 0 }} onDragStart={ev => ev.preventDefault()} />
        </>
      ) : (
        <>
          <CartoonButton src={buttonUpNormal} hoverSrc={buttonUpClick} activeSrc={buttonUpClick} style={{ left: '37.2vw', top: '28.2vw', width: '8.5vw' }} onDown={() => onDown('Up')} onUp={() => onUp('Up')} onClick={() => onClick('Up')} />
          <CartoonButton src={buttonDownNormal} hoverSrc={buttonDownClick} activeSrc={buttonDownClick} style={{ left: '32.5vw', top: '38.2vw', width: '8.5vw' }} onDown={() => onDown('Down')} onUp={() => onUp('Down')} onClick={() => onClick('Down')} />
          <CartoonButton src={buttonLeftNormal} hoverSrc={buttonLeftClick} activeSrc={buttonLeftClick} style={{ left: '28.8vw', top: '32.3vw', width: '8.5vw' }} onDown={() => onDown('Left')} onUp={() => onUp('Left')} onClick={() => onClick('Left')} />
          <CartoonButton src={buttonRightNormal} hoverSrc={buttonRightClick} activeSrc={buttonRightClick} style={{ left: '40.4vw', top: '33.2vw', width: '8.5vw' }} onDown={() => onDown('Right')} onUp={() => onUp('Right')} onClick={() => onClick('Right')} />
          <CartoonButton src={buttonRaiseNormal} hoverSrc={buttonRaiseClick} activeSrc={buttonRaiseClick} style={{ left: '52.85vw', top: '30.45vw', width: '8.5vw' }} onDown={() => onDown('Raise')} onUp={() => onUp('Raise')} onClick={() => onClick('Raise')} />
          <CartoonButton src={buttonLowerNormal} hoverSrc={buttonLowerClick} activeSrc={buttonLowerClick} style={{ left: '49.45vw', top: '39.55vw', width: '8.5vw' }} onDown={() => onDown('Lower')} onUp={() => onUp('Lower')} onClick={() => onClick('Lower')} />
          <img src={joystickCover} className={style.joystickCover} alt="Joystick Cover" onDragStart={ev => ev.preventDefault()} />
          <img src={getJoystick1Image(pressedButton)} className={style.joystick1} alt="Joystick 1" onDragStart={ev => ev.preventDefault()} />
          <img src={getJoystick2Image(pressedButton)} className={style.joystick2} alt="Joystick 2" onDragStart={ev => ev.preventDefault()} />
        </>
      )}
    </GameItem>
  );
};
