import React, { useCallback, useState } from 'react';

import { CustomDragLayer } from './CustomDragLayer';
import { Dropzone } from './Dropzone';
import { Item, itemImages } from './Item';
import { GameItem } from '../../../components';
import { useEquipmentState } from './equipmentState';
import { useScaleState } from '../Scales/scaleState';

import style from './Equipment.module.css';

import bg from './assets/bg.png';
import doorOpen from './assets/open.png';
import numberLight from './assets/number.png';

export const equipmentImages = [
  bg,
  doorOpen,
  numberLight,
  ...itemImages
];

const hints = [
  'To solve this puzzle, you need to solve the puzzle with scales first.',
  'The puzzle requires both the note in Old Dog’s room and a cabinet with lab equipment in Ally’s room.',
  'Read carefully the instruction written on a piece of paper on the wall in Old Dog’s room. Then, communicating with Ally, decide on the position of each piece of laboratory equipment on the shelf. ',
  'The correct order is 1-cylinder, 2-beaker, 3-mortar with a pestle, 4- flask, 5- funnel, 6-test tube, which gives a final answer: 3.'
];

export const Equipment = () => {
  const [{
    item1InSlot,
    item2InSlot,
    item3InSlot,
    item4InSlot,
    item5InSlot,
    item6InSlot,
    solved
  }, { putIn, putOut }] = useEquipmentState();
  const [{ solved: open }] = useScaleState();
  const [isDragging, setIsDragging] = useState(false);

  const onDrop = useCallback((itemId: number, slot: number) => {
    if (slot === 0) {
      putOut(itemId);
    } else {
      putIn(itemId, slot);
    }
  }, [putIn, putOut]);

  return (
    <GameItem
      name="l3Equipment"
      imageSrc={bg}
      hints={hints}
      iconStyle={{
        top: '3vw',
        left: '58vw',
        width: '31.5vw',
        height: '32vw'
      }}
    >
      {open ? (
        <>
          <img src={doorOpen} alt="Open Door" className={style.openDoor} onDragStart={ev => ev.preventDefault()} />
          <Dropzone slotId={0} isDragging={isDragging} />
          <Dropzone slotId={1} isDragging={isDragging} />
          <Dropzone slotId={2} isDragging={isDragging} />
          <Dropzone slotId={3} isDragging={isDragging} />
          <Dropzone slotId={4} isDragging={isDragging} />
          <Dropzone slotId={5} isDragging={isDragging} />
          <Dropzone slotId={6} isDragging={isDragging} />
          <CustomDragLayer />
          <Item itemId={1} onDrop={onDrop} inSlot={item1InSlot} onDragBegin={() => setIsDragging(true)} onDragEnd={() => setIsDragging(false)} />
          <Item itemId={2} onDrop={onDrop} inSlot={item2InSlot} onDragBegin={() => setIsDragging(true)} onDragEnd={() => setIsDragging(false)} />
          <Item itemId={3} onDrop={onDrop} inSlot={item3InSlot} onDragBegin={() => setIsDragging(true)} onDragEnd={() => setIsDragging(false)} />
          <Item itemId={4} onDrop={onDrop} inSlot={item4InSlot} onDragBegin={() => setIsDragging(true)} onDragEnd={() => setIsDragging(false)} />
          <Item itemId={5} onDrop={onDrop} inSlot={item5InSlot} onDragBegin={() => setIsDragging(true)} onDragEnd={() => setIsDragging(false)} />
          <Item itemId={6} onDrop={onDrop} inSlot={item6InSlot} onDragBegin={() => setIsDragging(true)} onDragEnd={() => setIsDragging(false)} />
          {solved ? (
            <img src={numberLight} alt="Number" className={style.numberLight} onDragStart={ev => ev.preventDefault()} />
          ) : <></>}
        </>
      ) : <></>}
    </GameItem>
  );
};
