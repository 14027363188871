import React, { memo } from 'react';
import classNames from 'classnames';

import style from './Computer.module.css';

const styles = {
  display: 'inline-block',
  // transform: 'rotate(7deg)',
  // WebkitTransform: 'rotate(7deg)',
  width: '5vw'
};

export interface FileDragPreviewProps {
  filename: string;
}

export const FileDragPreview: React.FC<FileDragPreviewProps> = memo(
  ({ filename }) => (
    <div style={styles}>
      <div className={classNames(style.file, style.fileDragging)}>{filename}</div>
    </div>
  )
);
