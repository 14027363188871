import { useState, useEffect } from 'react';
import moment from 'moment';

import { useGame } from './game';
import { sounds, speech } from '../components';
import { useCurrentPlayer } from '.';

/**
 * @param {number} time in seconds * 1000
 */
export function formatTimer(time: number) {
  const timer = Math.max(Math.floor(time / 1000), 0);

  // timer is in seconds
  const s = timer % 60;
  const m = ((timer - s) / 60) % 60;
  const h = (timer - s - m * 60) / 3600;

  return `${h}:${m < 10 ? '0' : ''}${m}:${s < 10 ? '0' : ''}${s}`;
}

type TimerMode = 'bomb' | 'bomb2' | 'normal' | 'none';

type Timer = {
  mode: TimerMode;
  timer: number;
  bombDefused: boolean;
}

type TimerResponse = [Timer];

const TIMER_DEFAULT: Timer = {
  mode: 'none',
  timer: 0,
  bombDefused: false
};

const MINUTES_5 = 5 * 60 * 1000;

// returns timer in seconds
export function useTimer(shouldTick?: boolean): TimerResponse {
  const [{ data }] = useGame();
  const [{ isOldDog }] = useCurrentPlayer();
  const [prevMode, setPrevMode] = useState(''); // important for the state machine stuff (to show the modal only on real state changes)
  const bombStart = data?.bombStartedAt || 0;
  const bombDefused = !!data?.bombDefused;
  const [timer, setTimerState] = useState<Timer>(TIMER_DEFAULT);

  useEffect(() => {
    const interval = setInterval(() => {
      const timeElapsedBomb = +moment() - bombStart;

      if (bombDefused) {
        return;
      }

      if (bombStart && timeElapsedBomb <= MINUTES_5) {
        setTimerState({ mode: 'bomb', timer: MINUTES_5 - timeElapsedBomb, bombDefused });
        setPrevMode('bomb');
        if (shouldTick) {
          sounds.tick(0.2);
        }
      } else if (bombStart && timeElapsedBomb <= MINUTES_5 * 2) {
        setTimerState({ mode: 'bomb2', timer: MINUTES_5 * 2 - timeElapsedBomb, bombDefused });
        if (shouldTick) {
          sounds.tick(0.2);
        }
        if (prevMode === 'bomb' && shouldTick) {
          speech.say(isOldDog ? 'l5OldDogGas5Minutes' : 'l5AllyGas5Minutes');
        }
        setPrevMode('bomb2');
      } else {
        setTimerState({ mode: 'normal', timer: 0, bombDefused });
        if (prevMode === 'bomb2' && shouldTick) {
          speech.say(isOldDog ? 'l5OldDogGas5Minutes' : 'l5AllyGas5Minutes');
        }
        setPrevMode('normal');
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [bombStart, shouldTick, prevMode, data, isOldDog, bombDefused]);

  return [{
    ...timer,
    bombDefused
  }];
}
