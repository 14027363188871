import { useCallback } from 'react';
import { db, useFirestoreQuery } from '../../../firebase';
import { useAccessCode } from '../../../state';

interface SafePayload {
  solved: boolean;
}

export const initialState: SafePayload = {
  solved: false
};

type SafeResponse = [SafePayload, {
  setSolved: () => void;
}];

export function useSafeState(): SafeResponse {
  const [{ code }] = useAccessCode();
  const stateDoc = db.collection('game')
    .doc(code)
    .collection('l5Safe')
    .doc('state');
  const { data } = useFirestoreQuery<SafePayload>(stateDoc);

  const state = { ...initialState, ...data };

  const setSolved = useCallback(() => {
    if (state.solved) {
      return;
    }
    stateDoc.set({ solved: true }, { merge: true });
  }, [state, stateDoc]);

  return [state, { setSolved }];
}
