import React, { FunctionComponent } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import classnames from 'classnames';
import { GameModal } from '../GameModal';

import style from './GameItem.module.css';
import { useCurrentPlayer } from '../../state';

interface GameItemProps {
  iconSrc?: string;
  imageSrc?: string;
  imageClassName?: string;
  canvas?: boolean;
  canvasColor?: string;
  debug?: boolean; // always show icon border
  debugOpen?: boolean; // open item right away
  name: string;
  description?: string;
  shortDescription?: string;
  shortDescriptionPosition?: 'top' | 'bottom';
  iconStyle?: React.CSSProperties;
  iconClassName?: string;
  destroyOnClose?: boolean;
  hints?: React.ReactNode[];
  disabled?: boolean;
  speech?: string;
  onClick?: () => void;
  onReset?: () => void; // reset puzzle, only for debugging
  onClose?: () => void;
  onOpen?: () => void;
}

export const GameItem: FunctionComponent<GameItemProps> = (props) => {
  const modalVisible = useStoreState(state => state.app.modalOpen) === props.name;
  const setModalVisible = useStoreActions((state: any) => state.app.setModalOpen);
  const [, { logAction }] = useCurrentPlayer();

  function onOpen() {
    if (!props.disabled) {
      setModalVisible(props.name);
      props.onOpen && props.onOpen();
    }
    props.onClick && props.onClick();
    logAction('open', props.name);
  }

  function onClose() {
    setModalVisible(null);
    logAction('close', props.name);
    props.onClose && props.onClose();
  }

  if (props.debugOpen) {
    setModalVisible(props.name);
  }

  return (
    <>
      {props.iconSrc ? (
        <img
          data-cy={props.name}
          src={props.iconSrc}
          alt={props.name}
          className={classnames(style.item, props.iconClassName, props.debug && style.showBorder)}
          onClick={onOpen}
          style={props.iconStyle}
        />
      ) : (
        <div
          data-cy={props.name}
          className={classnames(style.item, props.debug && style.showBorder)}
          onClick={onOpen}
          style={props.iconStyle}
        >
          {props.shortDescription ? (
            <div
              className={classnames(
                style.shortDescription,
                props.shortDescriptionPosition === 'bottom' && style.shortDescriptionBottom
              )}
            >
              {props.shortDescription}
            </div>
          ) : (<></>)}
        </div>
      )}
      <GameModal
        visible={modalVisible}
        onCloseModal={onClose}
        canvas={props.canvas}
        canvasColor={props.canvasColor}
        name={props.name}
        // description={props.description}
        imageSrc={props.imageSrc}
        imageClassName={props.imageClassName}
        destroyOnClose={props.destroyOnClose}
        hints={props.hints || []}
        onReset={props.onReset}
        speech={props.speech}
      >
        {props.children}
      </GameModal>
    </>
  );
};
