import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import style from './Credits.module.css';

export const level1Images = [];

interface CreditsProps {
  src: string;
  height: number;
  visible: boolean;
}

export const CreditsImage: React.FunctionComponent<CreditsProps> = (props) => {
  const [moving, setMoving] = useState(false);

  useEffect(() => {
    if (props.visible) {
      setTimeout(() => setMoving(true), 2000);
    }
  }, [props.visible]);

  return (
    <>
      <img
        src={props.src}
        alt="credits"
        style={{
          transform: moving ? `translateY(-${props.height}vw)` : '',
          display: props.visible ? 'block' : 'none'
        }}
        className={classNames(
          style.credits,
          'animate__animated',
          'animate__slower',
          'animate__fadeIn'
        )}
      />
    </>
  );
};
