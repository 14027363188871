import React from 'react';
import { useDrop } from 'react-dnd';

import style from './Synthesis.module.css';

export const BeakerDropzone: React.FC<{}> = () => {
  const [, drop] = useDrop({
    accept: 'beaker',
    drop: () => ({ type: 'beaker' }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  return (
    <div ref={drop} className={style.beakerDropzone} />
  );
};
