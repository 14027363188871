import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import { GameItem, sounds, speech } from '../../../components';
import { useVentilationState } from '../Ventilation/ventilationState';

import style from './Monkey.module.css';

import background from './assets/background.png';
import bars from './assets/bars.png';
import body from './assets/body.png';
import head from './assets/head.png';
import arm1 from './assets/arm1.png';
import arm2 from './assets/arm2.png';
import tail from './assets/tail.png';

export const monkeyImages = [
  background,
  bars,
  body,
  head,
  arm1,
  arm2,
  tail
];

export const Monkey = () => {
  const [screaming, setScreaming] = useState(false);
  const [{ pipesSolved: showArm }] = useVentilationState();

  const doScream = useCallback(() => {
    if (screaming) return;

    const i = Math.floor(Math.random() * 3) + 1;
    setScreaming(true);
    setTimeout(() => setScreaming(false), 4000);
    sounds[`monkey${i}`]();

    setTimeout(() => speech.say(showArm ? 'l5OldDogDoorFinal' : 'l5OldDogMonkey'), 1000);
  }, [screaming, showArm]);

  const hints = [
    showArm ? 'You have followed parrot\'s instructions correctly, so you can take another clue.' : 'The parrot will give you instructions on how to open safe. You haven\'t followed parrot\'s instructions yet, so there is nothing to do here... yet',
    'The solution to this puzzle will give you a correct combination to the safe',
    'Look at what the monkey is doing. Isn’t it making movements as if it was pressing buttons on the keypad?',
    'The monkey shows buttons in this order: 6, 4, 2, 9, and OK. Use this combination to open the safe.'
  ];

  return (
    <GameItem
      name="l5Monkey"
      imageSrc={background}
      hints={hints}
      iconStyle={{
        top: '14vw',
        left: '68vw',
        width: '24vw',
        height: '19vw'
      }}
    >
      <img src={arm2} className={style.arm2} alt="Arm 2" style={{ opacity: showArm ? 1 : 0 }} />
      <img src={arm1} className={style.arm1} alt="Arm 1" style={{ opacity: showArm ? 1 : 0 }} />
      <img src={head} className={classNames(style.head, showArm && style.showArm)} alt="Head" />
      <img src={body} className={classNames(style.body, showArm && style.showArm)} alt="Body" />
      <img src={tail} className={classNames(style.tail, showArm && style.showArm)} alt="Tail" />
      <img src={bars} className={style.bars} alt="Bars" onClick={() => doScream()} />
    </GameItem>
  );
};
