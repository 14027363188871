import React from 'react';
import classNames from 'classnames';

import style from './Parrot.module.css';

import bubble from './assets/bubble.png';

interface SpeechBubbleProps {
  visible: boolean;
  text: string;
}

export const ParrotSpeechBubble: React.FC<SpeechBubbleProps> = ({ visible, text }) => (
  <div className={classNames(style.speechBubbleContainer, visible && style.speechBubbleContainerVisible)}>
    <img src={bubble} alt="Bubble" className={style.speechBubble} />
    <div className={style.speechBubbleText}><span>{text}</span></div>
  </div>
);
