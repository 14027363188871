import React from 'react';
import classnames from 'classnames';
import { useDrop } from 'react-dnd';

import { useCurrentPlayer } from '../../../state';

import style from './Scales.module.css';

import scaleLeft from './assets/scaleLeft.png';
import scaleRight from './assets/scaleRight.png';

export const scaleDropzoneImages = [
  scaleLeft,
  scaleRight
];

interface ScaleDropzoneProps {
  offset: number;
  isDragging: boolean;
}

export const ScaleDropzone: React.FC<ScaleDropzoneProps> = ({ offset, isDragging }) => {
  const [{ isOldDog }] = useCurrentPlayer();

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'weight',
    drop: () => ({ type: 'scale' }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const isActive = canDrop && isOver;

  return (
    <>
      <div
        ref={drop}
        className={isOldDog ? style.scaleDropLeft : style.scaleDropRight}
        style={{ zIndex: isDragging ? 3 : 1 }}
      />
      <img
        src={isOldDog ? scaleLeft : scaleRight}
        alt="Half Scale"
        className={classnames(isOldDog ? style.scaleLeft : style.scaleRight, isActive && style.scaleActive)}
        style={{ transform: `translateY(${offset}vw)`}}
        onDragStart={ev => ev.preventDefault()}
      />
    </>
  );
};
