import React from 'react';
import classNames from 'classnames';
import { useDrop } from 'react-dnd';
import { capitalize } from 'lodash';

import style from './FoodWeight.module.css';

interface DropzoneProps {
  type: string;
  isDragging?: boolean;
}

export const Dropzone: React.FC<DropzoneProps> = (props) => {
  const [, drop] = useDrop({
    accept: 'food',
    drop: () => ({ type: 'food', slot: props.type }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  return (
    <div
      ref={drop}
      className={classNames(style.dropzone, style[`dropzone${capitalize(props.type)}`])}
      style={{ zIndex: props.isDragging ? 5 : 2 }}
    />
  );
};
