import React, { useState } from 'react';

import { CustomDragLayer } from './CustomDragLayer';
import { Dropzone } from './Dropzone';
import { Food, foodImages } from './Food';
import { GameItem, sounds } from '../../../components';

import style from './FoodWeight.module.css';

import bg from './assets/bg.png';
import tray from './assets/tray.png';

export const foodWeightImages = [
  bg,
  tray,
  ...foodImages
];

const hints = [
  'This is a part of a complex puzzle. To solve it, you will need fruits and vegetables with scales in Ally’s room and a snake in a terrarium in Old Dog’s room.',
  'You can see different vegetables and fruits. It will be helpful  to weigh some of them. But which ones? Talk with Old Dog and let him describe what the snake looks like. ',
  'It is difficult to say which veggies or fruits were eaten by snake, judging just by the shapes. But wait a minute, the plate says something about dotted snakes and snakes in stripes. This information will help you solve the puzzle.',
  'Knowing the weights of the fruits/vegetables eaten by the snake is not enough. You need to add the weight of the snake itself. Look again at the plate above the shelves. It should help.',
  'It is a male snake, so it weight 700 g. You need to add the weight of a pumpkin 800 g, onion 100 g, and grapes 50 g.',
  'The solution is 700 + 800 +100 +50 = 1650 '
];

const WEIGHTS = {
  avocado: 200,
  banana: 250,
  carrot: 100,
  grapes: 50,
  onion: 100,
  pomegranade: 200,
  potato: 150,
  pumpkin: 800,
  watermelon: 1000,
  zucchini: 300
};

export const FoodWeight = () => {
  const [foodOnWeight, setFoodOnWeight] = useState('');
  const [isDragging, setIsDragging] = useState(false);

  const weight = (WEIGHTS as any)[foodOnWeight] || 0;

  const onDrop = (name: string, slot: string) => {
    sounds.elementPutOut();
    if (slot === 'weight') {
      setFoodOnWeight(name);
    } else {
      setFoodOnWeight('');
    }
  };

  const isOnWeight = (name: string) => foodOnWeight === name;

  const onDragBegin = () => setIsDragging(true);
  const onDragEnd = () => setIsDragging(false);

  return (
    <GameItem
      name="l5FoodWeight"
      imageSrc={bg}
      hints={hints}
      iconStyle={{
        top: '14vw',
        left: '12vw',
        width: '23.5vw',
        height: '20vw'
      }}
    >
      <Dropzone isDragging={isDragging} type="shelf" />
      <Dropzone isDragging={isDragging} type="weight" />
      <CustomDragLayer />
      <Food onDragBegin={onDragBegin} onDragEnd={onDragEnd} name="pumpkin" onDrop={onDrop} isOnWeight={isOnWeight('pumpkin')} />
      <Food onDragBegin={onDragBegin} onDragEnd={onDragEnd} name="watermelon" onDrop={onDrop} isOnWeight={isOnWeight('watermelon')} />
      <Food onDragBegin={onDragBegin} onDragEnd={onDragEnd} name="carrot" onDrop={onDrop} isOnWeight={isOnWeight('carrot')} />
      <Food onDragBegin={onDragBegin} onDragEnd={onDragEnd} name="grapes" onDrop={onDrop} isOnWeight={isOnWeight('grapes')} />
      <Food onDragBegin={onDragBegin} onDragEnd={onDragEnd} name="onion" onDrop={onDrop} isOnWeight={isOnWeight('onion')} />
      <Food onDragBegin={onDragBegin} onDragEnd={onDragEnd} name="pomegranade" onDrop={onDrop} isOnWeight={isOnWeight('pomegranade')} />
      <Food onDragBegin={onDragBegin} onDragEnd={onDragEnd} name="zucchini" onDrop={onDrop} isOnWeight={isOnWeight('zucchini')} />
      <Food onDragBegin={onDragBegin} onDragEnd={onDragEnd} name="banana" onDrop={onDrop} isOnWeight={isOnWeight('banana')} />
      <img src={tray} alt="Tray" className={style.tray} onDragStart={ev => ev.preventDefault()} />
      <div className={style.screen}>
        <div className={style.weight}>{weight}</div>
        <span>g</span>
      </div>
    </GameItem>
  );
};
