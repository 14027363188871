import React from 'react';
import classNames from 'classnames';
import { useDrag, DragSourceMonitor } from 'react-dnd';
import { capitalize } from 'lodash';

import style from './FoodWeight.module.css';

import banana from './assets/banana.png';
import carrot from './assets/carrot.png';
import grapes from './assets/grapes.png';
import onion from './assets/onion.png';
import pomegranade from './assets/pomegranate.png';
import pumpkin from './assets/pumpkin.png';
import watermelon from './assets/watermelon.png';
import zucchini from './assets/zucchini.png';

export const foodImages = [
  banana,
  carrot,
  grapes,
  onion,
  pomegranade,
  pumpkin,
  watermelon,
  zucchini
];

const foods = {
  banana,
  carrot,
  grapes,
  onion,
  pomegranade,
  pumpkin,
  watermelon,
  zucchini
};

export function getFoodImg(type: string) {
  return (foods as any)[type] || onion;
}

interface FoodImageProps {
  name: string;
}

export const FoodImage: React.FC<FoodImageProps> = ({ name }) => {
  const img = getFoodImg(name);
  return (
    <div
      style={{ backgroundImage: `url(${img})` }}
      className={classNames(style.food, style[`food${capitalize(name)}`])}
    />
  );
};

interface FoodProps {
  name: string;
  isOnWeight: boolean;
  onDragBegin: () => void;
  onDragEnd: () => void;
  onDrop: (name: string, slot: string) => void;
}

export const Food: React.FC<FoodProps> = ({ name, isOnWeight, onDrop, onDragBegin, onDragEnd }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { name, type: 'food' },
    begin: () => onDragBegin(),
    end: (item: { name: string } | undefined, monitor: DragSourceMonitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onDrop(name, dropResult.slot);
      }
      onDragEnd();
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;

  return (
    <div
      ref={drag}
      style={{ opacity }}
      className={classNames(style.foodDrag, style[`foodDrag${capitalize(name)}`], isOnWeight && style.foodOnWeight)}
    >
      <FoodImage name={name} />
    </div>
  );
};
