import React from 'react';

import style from './Synthesis.module.css';
import { useSynthesisState } from './synthesisState';

export type Color = [number, number, number];

const DEFAULT: Color = [217, 163, 150];
// const WHITE: Color = [255, 255, 255];
const YELLOW: Color = [230, 224, 4];
const BLUE: Color = [57, 103, 213];
const PURPLE: Color = [192, 141, 224];
const GREEN: Color = [86, 130, 64];
const ORANGE: Color = [228, 133, 67];
const GREY: Color = [155, 155, 155];
const RED: Color = [211, 43, 43];

function mixColors(color1: Color, color2: Color): Color {
  const r = (color1[0] + color2[0]) / 2;
  const g = (color1[1] + color2[1]) / 2;
  const b = (color1[2] + color2[2]) / 2;
  return [r, g, b];
}

function toCSS(color: Color) {
  return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
}

function getColorFromString(color: string) {
  switch (color) {
    case 'Green':
      return GREEN;
    case 'Blue':
      return BLUE;
    case 'Purple':
      return PURPLE;
    case 'Yellow':
      return YELLOW;
    case 'Orange':
      return ORANGE;
    case 'Grey':
      return GREY;
    case 'Red':
      return RED;
    default:
      return PURPLE;
  }
}

function calculateColor(colors: string[]) {
  let color = DEFAULT;
  if (colors.length > 0) {
    color = getColorFromString(colors[0]);
  }
  colors.forEach((clr) => {
    color = mixColors(color, getColorFromString(clr));
  });
  if (colors.join('') === ['Purple', 'Blue', 'Blue', 'Yellow'].join('')) { // make sure the solution works well
    color = GREEN;
  }
  return toCSS(color);
}

export const ColorMarker = () => {
  const [{ addedColors }] = useSynthesisState();
  return (
    <div
      className={style.colorMarker}
      style={{ backgroundColor: calculateColor(addedColors) }}
    />
  );
};