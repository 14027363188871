import { useCallback } from 'react';
import { db, useFirestoreQuery } from '../../../firebase';
import { useAccessCode } from '../../../state';
import { sounds } from '../../../components';

interface ComputerPayload {
  snakeSolved: boolean;
  snakeWeight: string;
  soundAppOpen: boolean;
  playingSoundName: string;
  playingSoundDestination: string;
}

export const initialState: ComputerPayload = {
  snakeSolved: false,
  soundAppOpen: false,
  snakeWeight: '',
  playingSoundName: '',
  playingSoundDestination: ''
};

type ComputerResponse = [ComputerPayload, {
  onButtonPress: (button: string) => void;
  openSnake: () => void;
  openSound: () => void;
  closeSound: () => void;
  playSound: (filename: string, destination: string) => void;
}];

export function useComputerState(): ComputerResponse {
  const [{ code }] = useAccessCode();
  const stateDoc = db.collection('game')
    .doc(code)
    .collection('l5Computer')
    .doc('state');
  const { data } = useFirestoreQuery<ComputerPayload>(stateDoc);

  const state = { ...initialState, ...data };

  const onButtonPress = useCallback((button: string) => {
    if (button === 'CLR') {
      stateDoc.set({ snakeWeight: '' }, { merge: true });
      sounds.buttonBeep();
      return;
    }
    if (button === 'OK' && state.snakeWeight === '1650') {
      sounds.success();
      stateDoc.set({ snakeSolved: true }, { merge: true });
      return;
    }
    if (state.snakeWeight.length > 3 || button === 'OK') {
      sounds.keypadError();
      return;
    }
    sounds.buttonBeep();
    stateDoc.set({ snakeWeight: state.snakeWeight + button }, { merge: true });
  }, [state, stateDoc]);

  const openSnake = useCallback(() => {
    sounds.click();
    stateDoc.set({ snakeSolved: false, soundAppOpen: false }, { merge: true });
  }, [stateDoc]);

  const openSound = useCallback(() => {
    sounds.click();
    stateDoc.set({ soundAppOpen: true }, { merge: true });
  }, [stateDoc]);

  const closeSound = useCallback(() => {
    sounds.click();
    stateDoc.set({ soundAppOpen: false }, { merge: true });
  }, [stateDoc]);

  const playSound = useCallback((filename: string, destination: string) => {
    sounds.click();
    stateDoc.set({ playingSoundName: filename, playingSoundDestination: destination }, { merge: true });
    // reset state after a while to enable playing the same sound again
    setTimeout(() => {
      stateDoc.set({ playingSoundName: '', playingSoundDestination: '' }, { merge: true });
    }, 3000);
  }, [stateDoc]);

  return [state, { onButtonPress, openSnake, openSound, playSound, closeSound }];
}
