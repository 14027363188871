import React from 'react';
import classNames from 'classnames';

import { sounds } from '../GameSounds';

import style from './ClassicButton.module.css';

interface ClassicButtonProps {
  onClick?: () => void;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
  size?: 'large' | 'small';
  noBorder?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

export const ClassicButton: React.FC<ClassicButtonProps> = props => (
  // eslint-disable-next-line react/button-has-type
  <button
    type={props.type || 'button'}
    className={classNames(
      style.classicButton,
      props.className,
      props.size === 'small' && style.classicButtonSmall,
      props.noBorder && style.noBorder
    )}
    onClick={() => {
      sounds.clickQuiet();
      props.onClick && props.onClick();
    }}
    style={props.style}
    disabled={props.disabled}
  >
    {props.children}
  </button>
);
