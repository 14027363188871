import React, { useEffect, useState } from 'react';

import { useVentilationState } from './ventilationState';

import style from './Ventilation.module.css';

import sBlueDark from './assets/sBlueDark.png';
import sBlueLight from './assets/sBlueLight.png';
import sGreenDark from './assets/sGreenDark.png';
import sGreenLight from './assets/sGreenLight.png';
import iBlueDark from './assets/iBlueDark.png';
import iBlueLight from './assets/iBlueLight.png';
import iGreenDark from './assets/iGreenDark.png';
import iGreenLight from './assets/iGreenLight.png';
import lBlueDark from './assets/lBlueDark.png';
import lBlueLight from './assets/lBlueLight.png';
import lGreenDark from './assets/lGreenDark.png';
import lGreenLight from './assets/lGreenLight.png';
import tBlueDark from './assets/tBlueDark.png';
import tBlueLight from './assets/tBlueLight.png';
import tGreenDark from './assets/tGreenDark.png';
import tGreenLight from './assets/tGreenLight.png';

import lightLabels from './assets/lightLabels.png';
import lightNoLeaks from './assets/lightNoLeaks.png';
import lightConnected from './assets/lightConnected.png';

export const pipesImages = [
  sBlueDark,
  sBlueLight,
  sGreenDark,
  sGreenLight,
  iBlueDark,
  iBlueLight,
  iGreenDark,
  iGreenLight,
  lBlueDark,
  lBlueLight,
  lGreenDark,
  lGreenLight,
  tBlueDark,
  tBlueLight,
  tGreenDark,
  tGreenLight,
  lightLabels,
  lightNoLeaks,
  lightConnected
];

const getPipeImg = (type: 'I' | 'L' | 'E' | 'T', blue: boolean, light: boolean) => {
  if (type === 'I') {
    if (blue) {
      return light ? sBlueLight : sBlueDark;
    }
    return light ? sGreenLight : sGreenDark;
  }

  if (type === 'L') {
    if (blue) {
      return light ? lBlueLight : lBlueDark;
    }
    return light ? lGreenLight : lGreenDark;
  }

  if (type === 'E') {
    if (blue) {
      return light ? iBlueLight : iBlueDark;
    }
    return light ? iGreenLight : iGreenDark;
  }

  if (type === 'T') {
    if (blue) {
      return light ? tBlueLight : tBlueDark;
    }
    return light ? tGreenLight : tGreenDark;
  }

  return tGreenLight; // default, to shut TS up
};

export const Pipes = () => {
  const [animationEnabled, setAnimationEnabled] = useState(false);
  const [{ pipes, endReached, noLeaks }, { rotatePipe }] = useVentilationState();

  useEffect(() => {
    // make sure that the animation does not fire up when player returns to the box
    setTimeout(() => setAnimationEnabled(true), 100);
    return () => setAnimationEnabled(false);
  }, []);

  return (
    <div className={style.pipeGrid}>
      <img src={lightLabels} className={style.lightLabel} alt="light label" />
      <img src={lightNoLeaks} className={style.statusLight} alt="light no leaks" style={{ opacity: noLeaks ? 1 : 0 }} />
      <img src={lightConnected} className={style.statusLight} alt="light connected" style={{ opacity: endReached ? 1 : 0 }} />
      {pipes.map((row, i) => (
        <React.Fragment key={`fragment ${i}`}>
          {row.map(([type, blue, rotation, connected], j) => (
            <img
              src={getPipeImg(type, blue, connected)}
              className={style.pipePiece}
              alt={`Piece ${i} ${j}`}
              key={`Piece ${i} ${j}`}
              style={{
                top: `${13.4 + i * 5.76}vw`,
                left: `${38.1 + j * 5.76}vw`,
                transform: `rotate(${90 * rotation}deg)`,
                transition: animationEnabled ? 'transform 200ms ease-in-out' : 'none'
              }}
              onClick={() => rotatePipe(i, j)}
            />
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};
