import React from 'react';
import { useDrop } from 'react-dnd';

import style from './VialMachine.module.css';

interface VialDropzoneProps {
  slot: string;
}

export const VialDropzone: React.FC<VialDropzoneProps> = ({ slot }) => {
  const [, drop] = useDrop({
    accept: 'vial',
    drop: () => ({ type: 'slot', slot }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  return (
    <div ref={drop} className={style[`vialDropzone${slot}`]} />
  );
};
