import React from 'react';
import classNames from 'classnames';

import { useVialsState } from './vialsState';

import style from './VialMachine.module.css';
import { CartoonButton, sounds } from '../../../components';

import buttonBlue from './assets/vmButtonBlue.png';
import buttonRed from './assets/vmButtonRed.png';
import buttonGreen from './assets/vmButtonGreen.png';
import buttonPressed from './assets/vmButtonTransparent.png';

export const resetButtonImages = [
  buttonBlue,
  buttonRed,
  buttonGreen,
  buttonPressed
];

interface ResetButtonProps {
  slot: string;
}

const getSrc = (slot: string) => {
  if (slot === 'Ireland') return buttonBlue;
  if (slot === 'Poland') return buttonRed;
  return buttonGreen;
};

export const ResetButton: React.FC<ResetButtonProps> = ({ slot }) => {
  const [, { emptySlot }] = useVialsState();

  return (
    <CartoonButton
      src={getSrc(slot)}
      activeSrc={buttonPressed}
      hoverSrc={getSrc(slot)}
      className={classNames(style.resetButton, style[`resetButton${slot}`])}
      onClick={() => emptySlot(slot)}
      onDown={() => sounds.button1Down()}
      onUp={() => sounds.button1Up()}
    />
  )
};
