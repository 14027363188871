import { useCallback } from 'react';
import { db, useFirestoreQuery } from '../../../firebase';
import { useAccessCode } from '../../../state';
import { sounds } from '../../../components';

interface BlindMicePayload {
  solved: boolean;
  playing: boolean;
  arrow0: [number, number] | null;
  arrow1: [number, number] | null;
  arrow2: [number, number] | null;
  arrow3: [number, number] | null;
}

export const initialState: BlindMicePayload = {
  solved: false,
  playing: false,
  arrow0: null,
  arrow1: null,
  arrow2: null,
  arrow3: null
};

type BlindMiceResponse = [BlindMicePayload, {
  moveArrow: (i: number, position: [number, number] | null) => void;
  playPause: () => void;
  play: () => void;
  reset: () => void;
  setSolved: () => void;
}];

export function useBlindMiceState(): BlindMiceResponse {
  const [{ code }] = useAccessCode();
  const stateDoc = db.collection('game')
    .doc(code)
    .collection('l5BlindMice')
    .doc('state');
  const { data } = useFirestoreQuery<BlindMicePayload>(stateDoc);

  const state = { ...initialState, ...data };

  const moveArrow = useCallback((i: number, position: [number, number] | null) => {
    if (state.solved) {
      sounds.error();
      return;
    }
    sounds.putOnTable();
    const updatedDoc: any = {};
    updatedDoc[`arrow${i}`] = position;
    stateDoc.set(updatedDoc, { merge: true });
  }, [state, stateDoc]);

  const playPause = useCallback(() => {
    if (state.solved) {
      sounds.error();
      return;
    }
    stateDoc.set({
      playing: !state.playing
    }, { merge: true });
  }, [state, stateDoc]);

  const play = useCallback(() => {
    if (state.solved) {
      sounds.error();
      return;
    }
    stateDoc.set({ playing: true }, { merge: true });
  }, [state, stateDoc]);

  const reset = useCallback(() => {
    if (state.solved) {
      sounds.error();
      return;
    }
    stateDoc.set({ playing: false }, { merge: true });
  }, [state, stateDoc]);

  const setSolved = useCallback(() => {
    if (state.solved) {
      return;
    }
    stateDoc.set({ solved: true }, { merge: true });
    sounds.success();
  }, [state, stateDoc]);

  return [state, { moveArrow, playPause, setSolved, play, reset }];
}
