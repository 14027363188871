import React, { memo } from 'react';
import { WeightImage, Weight } from './Weight';

const styles = {
  display: 'inline-block',
  transform: 'rotate(7deg)',
  WebkitTransform: 'rotate(7deg)',
  width: '5vw'
};

export interface WeightDragPreviewProps {
  weight: Weight;
}

export const WeightDragPreview: React.FC<WeightDragPreviewProps> = memo(
  ({ weight }) => (
    <div style={styles}>
      <WeightImage weight={weight} />
    </div>
  )
);
