import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import style from './CutsceneImage.module.css';

export const level1Images = [];

interface CutsceneImageProps {
  src: string;
  animationIn?: string;
  animationOut?: string;
  animationSpeed?: 'slow' | 'slower' | 'normal' | 'fast' | 'faster';
  className?: string;
  zIndex?: number;
  visible: boolean;
  top: number;
  left: number;
  width: number;
}


export const CutsceneImage: React.FunctionComponent<CutsceneImageProps> = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const animationIn = props.animationIn || 'fadeIn';
  const animationOut = props.animationOut || 'fadeOut';

  useEffect(() => {
    if (props.visible) setIsVisible(true);
  }, [props.visible]);

  if (!isVisible) {
    return <></>;
  }

  return (
    <>
      <img
        src={props.src}
        alt="comic"
        style={{
          top: `${props.top}%`,
          left: `${props.left}%`,
          width: `${props.width}%`,
          zIndex: props.zIndex
        }}
        className={classNames(
          style.image,
          props.className,
          'animate__animated',
          `animate__${props.animationSpeed || 'faster'}`,
          `animate__${props.visible ? animationIn : animationOut}`
        )}
      />
    </>
  );
};
