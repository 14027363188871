import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { ErrorPage } from '../ErrorPage';
import { Loading } from '../Loading';
import { usePlayers, useCurrentPlayer, useAccessCode, useGame, canStartGame } from '../state';
import { ClassicButton, GameBoard, sounds } from '../components';
import style from './GamePreparationMenu.module.css';

import oldDog from './assets/oldDog.png';
import oldDogBackground from './assets/oldDogBackground.png';
import oldDogBeaker from './assets/oldDogBeaker.png';
import ally from './assets/ally.png';
import allyBackground from './assets/allyBackground.png';
import allyBeaker from './assets/allyBeaker.png';
import divider from './assets/divider.png';

import twoComputers from './assets/twoComputers.png';
import notes from './assets/notes.png';
import speaker from './assets/speaker.png';

export const prepImages = [
  oldDog,
  oldDogBackground,
  oldDogBeaker,
  ally,
  allyBackground,
  allyBeaker,
  divider,
  twoComputers,
  notes,
  speaker
];

export const GamePreparationMenu = () => {
  const { data: players, status, error } = usePlayers();
  const [{ data: game }, { setGameStart }] = useGame();
  const [{ currentPlayer, loading }, { setCurrentPlayer, changeLevel }] = useCurrentPlayer();
  const [step, setStep] = useState(-1);
  const [startingTheGame, setStartingTheGame] = useState(false);
  const [{ code }] = useAccessCode();
  const level = currentPlayer?.level;
  const oldDogSelected = currentPlayer.team === 'blue';
  const allySelected = currentPlayer.team === 'red';

  const canStart = canStartGame(currentPlayer, players);
  const isLoading = loading || status === 'loading' || !code;

  useEffect(() => {
    let cancelMusic: any = sounds.menuMusic(0.5);
    const interval = setInterval(() => {
      cancelMusic = sounds.menuMusic(0.5);
    }, 50000);
    return () => {
      clearInterval(interval);
      cancelMusic();
    };
  }, []);

  useEffect(() => {
    // make sure that the step 1 is open by default, if the team has been already chosen
    if (currentPlayer.team && step === -1) {
      setTimeout(() => setStep(1), 500);
    }
  }, [currentPlayer.team, step]);

  function startGame() {
    setStartingTheGame(true);
    setTimeout(() => {
      setCurrentPlayer({ started: true });
      if (!game?.startedAt) {
        setGameStart(); // start the timer for ALL PLAYERS
      }
      if (!level) {
        changeLevel(game?.level || '1-waiting');
      }
      setTimeout(() => window.scrollTo(0, 0), 500);
    }, 3000);
  }

  const setPlayer = ({ team }: { team: string }) => {
    setCurrentPlayer({ team });
  };

  if (error) return <ErrorPage />;
  if (isLoading) return <Loading />;

  return (
    <GameBoard className={classNames(style.gameBoardCharacters, oldDogSelected && style.oldDogSelected, allySelected && style.allySelected, startingTheGame && style.startingTheGame)}>
      <div className={style.characters}>
        <h2 className={classNames(style.chooseCharacterH, (allySelected || oldDogSelected) && style.characterSelected)}>Choose your detective</h2>
        <div
          className={classNames(style.oldDogWrapper, oldDogSelected && style.characterSelected, allySelected && style.otherCharacterSelected)}
          onClick={() => {
            setStep(1);
            setPlayer({ team: currentPlayer.team ? '' : 'blue' });
          }}
        >
          <img src={oldDog} className={style.oldDog} alt="Old Dog" />
          <img src={oldDogBackground} className={style.oldDogBackground} alt="Old Dog Background" />
          <img src={oldDogBeaker} className={style.oldDogBeaker} alt="Old Dog Beaker" />
          <h3 className={style.oldDogName}>Old Dog</h3>
        </div>
        <div
          className={classNames(style.allyWrapper, allySelected && style.characterSelected, oldDogSelected && style.otherCharacterSelected)}
          onClick={() => {
            setStep(1);
            setPlayer({ team: currentPlayer.team ? '' : 'red' });
          }}
        >
          <img src={ally} className={style.ally} alt="Ally" />
          <img src={allyBackground} className={style.allyBackground} alt="Ally Background" />
          <img src={allyBeaker} className={style.allyBeaker} alt="Ally Beaker" />
          <h3 className={style.allyName}>Ally</h3>
        </div>
        <img src={divider} className={style.divider} alt="Divider" />
      </div>

      <div className={classNames(style.steps, style.stepBlank, (step === 0 || step === -1) && style.stepVisible)} />

      <div className={classNames(style.steps, style.stepName, step === 1 && style.stepVisible)}>
        <div className={style.description}>
          <p>What's your name?</p>
          <input
            type="text"
            className={style.nameInput}
            onChange={ev => setCurrentPlayer({ name: ev.target.value })}
            value={currentPlayer.name}
          />
        </div>
        <ClassicButton
          noBorder
          className={style.nameContinue}
          onClick={() => {
            setStep(0);
            setTimeout(() => setStep(2), 1500);
          }}
        >
          Continue
        </ClassicButton>
      </div>

      <div className={classNames(style.steps, style.stepSound, step === 2 && style.stepVisible)}>
        <img src={speaker} alt="Speaker" className={style.speaker} onClick={() => sounds.mystery()} />
        <div className={style.description}>
          <p>This game requires sound to be on.</p>
          <p>Click on the icon above to play a test sample.</p>
        </div>
        <ClassicButton
          noBorder
          className={style.speakerContinue}
          onClick={() => {
            setStep(0);
            setTimeout(() => setStep(3), 1500);
          }}
        >
          Continue
        </ClassicButton>
      </div>

      <div className={classNames(style.steps, style.stepNotes, step === 3 && style.stepVisible)}>
        <img src={notes} alt="Notes" className={style.notes} />
        <div className={style.description}>
          <p>Make sure you have some pen and paper to hand.</p>
          <p>The game will be easier if you do take a few notes here and there.</p>
        </div>
        <ClassicButton
          noBorder
          className={style.continue}
          onClick={() => {
            setStep(0);
            setTimeout(() => setStep(4), 1500);
          }}
        >
          Continue
        </ClassicButton>
      </div>

      <div className={classNames(style.steps, style.stepDevices, step === 4 && style.stepVisible)}>
        <img src={twoComputers} alt="Two devices" className={style.twoComputers} />
        <div className={style.description}>
          <p>This game is for at least two players. If you're in the same room, you're all set!</p>
          <p>Otherwise, make sure to use a voice communication tool (eg. Skype, Zoom, FaceTime)</p>
        </div>
        <ClassicButton
          noBorder
          className={style.continue}
          onClick={() => {
            setStep(0);
            setTimeout(() => setStep(5), 1500);
          }}
        >
          Continue
        </ClassicButton>
      </div>

      <div className={classNames(style.steps, style.stepTeam, step === 5 && style.stepVisible)}>
        <div className={style.description}>
          <p>
            Your team should go to <a href="https://lab.elevenpuzzles.com">https://lab.elevenpuzzles.com</a><br />
            and enter the License Key: <br /><span>{code.toLocaleUpperCase()}</span>
            <button type="button" className={style.switch} onClick={() => navigator.clipboard.writeText(`Please go to https://lab.elevenpuzzles.com and enter the following License Key: ${code.toLocaleUpperCase()}`)}>[copy]</button>
          </p>
        </div>

        <div className={style.players}>
          <h3>Players online ({players?.length})</h3>
          <div className={style.playerList}>
            {players?.map(player => (
              <p key={player.id}>
                {player.name ? player.name : '<Unnamed player>'}{' '}
                {player.id === currentPlayer.id ? '(You)' : ''}
                {' '} as {player.team === 'blue' ? 'Old Dog' : 'Ally'}
                {player.id === currentPlayer.id ? <button type="button" className={style.switch} onClick={() => setPlayer({ team: currentPlayer.team === 'red' ? 'blue' : 'red' })}>[switch]</button> : <></>}
              </p>
            ))}
          </div>
        </div>

        <ClassicButton
          className={style.startGame}
          disabled={!canStart}
          size="small"
          onClick={() => {
            if (canStart) {
              startGame();
            }
          }}
        >
          Start game
        </ClassicButton>

        {!canStart ? (
          <p className={style.disabledDescription}>
            One more player has to choose {currentPlayer.team === 'blue' ? 'Ally' : 'Old Dog'}
          </p>
        ) : <></>}
      </div>
    </GameBoard>
  );
};
