import pic1 from './assets/pic1.png';
import pic2 from './assets/pic2.png';
import pic3 from './assets/pic3.png';
import pic4 from './assets/pic4.png';
import txt1 from './assets/txt1.png';
import txt3 from './assets/txt3.png';
import txt4 from './assets/txt4.png';
import txt5 from './assets/txt5.png';
import txt6 from './assets/txt6.png';
import txt7 from './assets/txt7.png';

const WIDTH = 2800;

export const CUTSCENE4 = [
  { type: 'sound', src: 'bubblingSalts', delay: 500, volume: 0.6 },
  { type: 'image', src: pic1, delay: 2000, top: 0, left: 0, width: (564 / WIDTH) * 100, animationSpeed: 'slow' },
  { type: 'image', src: txt1, delay: 2500, top: -2, left: -2, width: (695 / WIDTH) * 100, zIndex: 1 },
  { type: 'sound', src: 'voice26', delay: 500 },
  { type: 'image', src: pic2, delay: 1000, top: 0, left: 21, width: (660 / WIDTH) * 100, animationSpeed: 'slow' },
  { type: 'image', src: txt3, delay: 2000, top: 67, left: 19, width: (771 / WIDTH) * 100, zIndex: 1 },
  { type: 'sound', src: 'inferiorityComplex', delay: 500 },
  { type: 'sound', src: 'crackle', delay: 3000 },
  { type: 'image', src: pic3, delay: 1000, top: 0, left: 46, width: (755 / WIDTH) * 100, animationSpeed: 'slow' },
  { type: 'image', src: txt4, delay: 1000, top: 3, left: 48, width: (589 / WIDTH) * 100 },
  { type: 'sound', src: 'voice18', delay: 1000 },
  { type: 'image', src: pic4, delay: 1000, top: 0, left: 74, width: (726 / WIDTH) * 100 },
  { type: 'sound', src: 'crackle2', delay: 1500 },
  { type: 'image', src: txt5, delay: 200, top: -1, left: 73, width: (331 / WIDTH) * 100 },
  { type: 'sound', src: 'whatElseWouldYouCallThat', delay: 500 },
  { type: 'image', src: txt6, delay: 200, top: 7, left: 73, width: (668 / WIDTH) * 100 },
  { type: 'image', src: txt7, delay: 2000, top: 66, left: 74, width: (771 / WIDTH) * 100 }
];
