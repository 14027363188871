import React from 'react';
import classNames from 'classnames';
import { useDrag, DragSourceMonitor } from 'react-dnd';

import style from './Microscope.module.css';

import sampleBlue from './assets/sampleBlue.png';
import sampleGreen from './assets/sampleGreen.png';
import sampleRed from './assets/sampleRed.png';
import sampleBlack from './assets/sampleGrey.png';
import sampleYellow from './assets/sampleYellow.png';
import samplePink from './assets/samplePink.png';
import sampleOrange from './assets/sampleOrange.png';

import sampleSmallBlue from './assets/sampleSmallBlue.png';
import sampleSmallGreen from './assets/sampleSmallGreen.png';
import sampleSmallRed from './assets/sampleSmallRed.png';
import sampleSmallBlack from './assets/sampleSmallGrey.png';
import sampleSmallYellow from './assets/sampleSmallYellow.png';
import sampleSmallPink from './assets/sampleSmallPink.png';
import sampleSmallOrange from './assets/sampleSmallOrange.png';

export const sampleImages = [
  sampleBlue,
  sampleGreen,
  sampleRed,
  sampleBlack,
  sampleYellow,
  samplePink,
  sampleOrange,
  sampleSmallBlue,
  sampleSmallGreen,
  sampleSmallRed,
  sampleSmallBlack,
  sampleSmallYellow,
  sampleSmallPink,
  sampleSmallOrange
];

export function getSampleImg(name: string) {
  switch (name) {
    case 'Blue':
      return sampleBlue;
    case 'Green':
      return sampleGreen;
    case 'Red':
      return sampleRed;
    case 'Black':
      return sampleBlack;
    case 'Yellow':
      return sampleYellow;
    case 'Pink':
      return samplePink;
    case 'Orange':
      return sampleOrange;
    default:
      return sampleBlue;
  }
};

export function getSampleSmallImg(name: string) {
  switch (name) {
    case 'Blue':
      return sampleSmallBlue;
    case 'Green':
      return sampleSmallGreen;
    case 'Red':
      return sampleSmallRed;
    case 'Black':
      return sampleSmallBlack;
    case 'Yellow':
      return sampleSmallYellow;
    case 'Pink':
      return sampleSmallPink;
    case 'Orange':
      return sampleSmallOrange;
    default:
      return sampleSmallBlue;
  }
};

interface SampleImageProps {
  name: string;
  small: boolean;
}

export const SampleImage: React.FC<SampleImageProps> = ({ name, small }) => {
  const sampleImg = small ? getSampleSmallImg(name) : getSampleImg(name);
  return (
    <div
      style={{ backgroundImage: `url(${sampleImg})` }}
      className={small ? style.sampleSmall : classNames(style.sample, style[`sample${name}`])}
    />
  );
};

interface SampleProps {
  name: string;
  onDrag: () => void;
  onDrop: (sampleName: string, where: string) => void;
  inserted: boolean;
}

export const Sample: React.FC<SampleProps> = ({ inserted, name, onDrop, onDrag }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { name, type: 'sample' },
    begin: () => onDrag(),
    end: (item: { name: string } | undefined, monitor: DragSourceMonitor) => {
      const dropResult = monitor.getDropResult() || {};
      onDrop(name, dropResult.where);
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  // const canBeVisible = !inserted;
  const opacity = isDragging ? 0 : 1;

  return (
    <div ref={drag} style={{ opacity }} className={inserted ? style.sampleSmallDrag : classNames(style.sampleDrag, style[`sampleDrag${name}`])}>
      <SampleImage name={name} small={inserted} />
    </div>
  );
};
