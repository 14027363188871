import React, { useState, useEffect, useCallback } from 'react';

import { GameItem, sounds } from '../../../components';
import { useZebrafishState } from './zebrafishState';
import { controlImages } from './Control';

import style from './Tank.module.css';

import background from './assets/tank.png';
import thermo from './assets/thermometer.png';
import thermoBg from './assets/thermometer_bg.png';
import redLine from './assets/red_line.png';
import fishTailLeft from './assets/fishTail.png';
import fishTailRight from './assets/fishTail2.png';
import doNotTap from './assets/doNotTap.png';
import cloudy from './assets/cloudy.png';

import love from './assets/love.png';

import rh1 from './assets/rh1.png';
import rh2 from './assets/rh2.png';
import rv1 from './assets/rv1.png';
import rv2 from './assets/rv2.png';
import rv4 from './assets/rv4.png';
import rv5 from './assets/rv5.png';
import lh2 from './assets/lh2.png';
import lh4 from './assets/lh4.png';
import lv3 from './assets/lv3.png';
import lv4 from './assets/lv4.png';
import lv5 from './assets/lv5.png';

export const zebrafishImages = [
  background,
  thermo,
  thermoBg,
  redLine,
  fishTailLeft,
  fishTailRight,
  doNotTap,
  cloudy,
  love,
  rh1,
  rh2,
  rv1,
  rv2,
  rv4,
  rv5,
  lh2,
  lh4,
  lv3,
  lv4,
  lv5,
  ...controlImages
];

const hints = [
  'To solve the puzzle, you will need an aquarium with fish in Ally’s room and a device with hot and cold taps in Old Dog’s room.',
  'First, you need to adjust the temperature in the aquarium, which is best for fish mating. To do it, you need to collaborate with Old Dog.',
  'When the temperature is set, you’re ready to go. The tape on the aquarium says that you shouldn’t tap on the glass because you can startle the animals…but what will happen if you do it?',
  'Talk with Old Dog whether he has some important information on the device with taps.',
  'When you tap on the aquarium, you notice that each time you tap, two fish form pairs. But, wait, why always one of them has horizontal and the other vertical stripes? ',
  'Count horizontal stripes, which will give you the number of the line Old Dog should look at. When you count the vertical stripes, it will provide you with the number of a column Old Dog should look at.',
  <>
    When you tap at the glas, the following pairs will form: <br />
    horizontal 1 - vertical 5<br />
    vertical 4 - horizontal 2<br />
    vertical 2 - horizontal 2<br />
    horizontal 2 - vertical 3<br />
    vertical 5 - horizontal 4<br />
    Give this information to Old Dog. He should know what to do with it.
  </>,
  'The solution is E-I-G-H-T (eight).'
];

const FISH = [
  { img: rh1, dir: 'right', mating: [{ i: 1, loc: [30, 32] }] },
  { img: rh2, dir: 'right', mating: [{ i: 4, loc: [14, 74] }] },
  // { img: rh3, dir: 'right', mating: [] },
  // { img: rh4, dir: 'right', mating: [] },
  // { img: rh5, dir: 'right', mating: [] },
  { img: rv1, dir: 'right', mating: [] },
  { img: rv2, dir: 'right', mating: [{ i: 3, loc: [60, 54] }] },
  // { img: rv3, dir: 'right', mating: [] },
  { img: rv4, dir: 'right', mating: [{ i: 2, loc: [14, 74] }] },
  { img: rv5, dir: 'right', mating: [{ i: 5, loc: [50, 54] }] },
  // { img: lh1, dir: 'left', mating: [] },
  { img: lh2, dir: 'left', mating: [{ i: 3, loc: [70, 54] }, { i: 2, loc: [24, 74] }] },
  // { img: lh3, dir: 'left', mating: [] },
  { img: lh4, dir: 'left', mating: [{ i: 5, loc: [60, 54] }] },
  // { img: lh5, dir: 'left', mating: [] },
  // { img: lv1, dir: 'left', mating: [] },
  // { img: lv2, dir: 'left', mating: [{ i: 4, loc: [24, 74] }] },
  { img: lv3, dir: 'left', mating: [{ i: 4, loc: [24, 74] }] },
  { img: lv4, dir: 'left', mating: [] },
  { img: lv5, dir: 'left', mating: [{ i: 1, loc: [40, 32] }] }
];

const calculateCurrentTemp = (coldValue: number, hotValue: number) => {
  let temp = 0;
  temp += hotValue * 3;
  temp -= coldValue * 2;
  return temp;
};

const getMatingLocations = (mating: boolean, tapI: number) => FISH.filter((fish) => {
  if (!mating) {
    return false;
  }
  return !!fish.mating.find(m => m.i === tapI);
}).map(fish => fish.mating.find(m => m.i === tapI)?.loc) as [number, number][] || [];

const generateFishLocations = (mating: boolean, tapI: number) => {
  const locations: [number, number][] = [];

  // use mating locations to make sure that other fish aren't generated too close
  const matingLocations = getMatingLocations(mating, tapI);

  for (let i = 0; i < FISH.length; i++) {
    let newLocation: [number, number];
    let distance: number;
    do {
      newLocation = [Math.random() * 80, Math.random() * 80];
      // eslint-disable-next-line no-loop-func
      distance = [...locations, ...matingLocations].reduce((prev, curr) => {
        const distanceX = Math.abs(newLocation[0] - curr[0]);
        const distanceY = Math.abs(newLocation[1] - curr[1]);
        const distanceCurr = distanceX + distanceY;
        const distancePrev = prev[0] + prev[1];
        if (distanceCurr < distancePrev) {
          return [distanceX, distanceY];
        }
        return prev;
      }, [Infinity, Infinity]).reduce((p, c) => p + c, 0);
    } while (distance < 15);

    if (mating) {
      const matingLocation = FISH[i].mating.find(m => m.i === tapI)?.loc as [number, number];
      if (matingLocation) {
        newLocation = matingLocation;
      }
    }

    locations.push(newLocation);
  }

  return locations;
};

export const Tank = () => {
  const [locations, setLocations] = useState(generateFishLocations(false, 0));
  const [{ coldValue, hotValue }] = useZebrafishState();
  const [tapI, setTapI] = useState(+new Date());
  const temp = calculateCurrentTemp(coldValue, hotValue);

  const mating = hotValue === 2 && coldValue === 2;

  const loveLocation = getMatingLocations(mating, tapI)[0] || [];

  useEffect(() => {
    // make sure that the cycle starts with 0
    if (mating === true) {
      setTapI(0);
    }
  }, [mating]);

  useEffect(() => {
    setLocations(generateFishLocations(mating, tapI));
  }, [coldValue, hotValue, mating, tapI]);

  const tapOnGlass = useCallback(() => {
    sounds.tapOnGlass();
    setTapI((tapI + 1) % 6);
  }, [tapI]);

  return (
    <GameItem
      name="l5Tank"
      imageSrc={background}
      hints={hints}
      iconStyle={{
        top: '24.8vw',
        left: '78vw',
        width: '22vw',
        height: '31vw'
      }}
    >
      <img src={thermoBg} alt="Thermo BG" className={style.thermoBg} onDragStart={ev => ev.preventDefault()} />
      <img src={redLine} alt="Red line" className={style.redLine} style={{ transform: `translateY(${-temp * 0.88}vw)` }} onDragStart={ev => ev.preventDefault()} />
      <img src={doNotTap} alt="Do not tap" className={style.doNotTap} onDragStart={ev => ev.preventDefault()} />
      <img src={cloudy} alt="Cloudy" className={style.cloudy} onDragStart={ev => ev.preventDefault()} />
      <div className={style.tankInside}>
        {locations.map(([x, y], i) => (
          <div
            key={`fish${i}`}
            className={FISH[i].dir === 'right' ? style.fishRight : style.fishLeft}
            style={{ left: `${x}%`, top: `${y}%`, animationDelay: `${i * 800}ms` }}
          >
            <img src={FISH[i].img} alt="Fish body" className={style.fishBody} />
            <img
              src={FISH[i].dir === 'right' ? fishTailRight : fishTailLeft}
              alt="Fish tail"
              className={FISH[i].dir === 'right' ? style.fishTailRight : style.fishTailLeft}
              style={{
                animationDelay: `${400 * i}ms`,
                animationDuration: `${mating && (i % 5 < 3) ? 300 : 1000}ms`
              }}
            />
          </div>
        ))}
        {loveLocation.length > 0 ? (
          <img key={loveLocation.join('-')} src={love} alt="Love" className={style.love} style={{ top: `${loveLocation[1] + 3}%`, left: `${loveLocation[0] + 9.5}%` }} />
        ) : <></>}
      </div>
      <img src={thermo} alt="Thermo" className={style.thermo} />``
      <div className={style.tapOnGlassContainer} onClick={() => tapOnGlass()} />
    </GameItem>
  );
};
