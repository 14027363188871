import React from 'react';
import classnames from 'classnames';
import { useVentilationState, GRID, Piece } from './ventilationState';

import style from './Ventilation.module.css';

import brick1x2 from './assets/brick1x2.png';
import brick1x3 from './assets/brick1x3.png';
import brick2x1 from './assets/brick2x1.png';
import brick3x1 from './assets/brick3x1.png';

export const sliderImages = [
  brick1x2,
  brick1x3,
  brick2x1,
  brick3x1
];

const elementWidth = 100 / GRID[0];
const elementHeight = 100 / GRID[1];

const getBrickImg = (piece: Piece) => {
  if (piece.o === 'h' && piece.w === 2) return brick1x2;
  if (piece.o === 'h' && piece.w === 3) return brick1x3;
  if (piece.o === 'v' && piece.h === 2) return brick2x1;
  if (piece.o === 'v' && piece.h === 3) return brick3x1;
  return brick3x1;
};

export const Slider = () => {
  const [{ pieces }, { togglePiece }] = useVentilationState();

  return (
    <div className={style.grid}>
      {pieces.map((piece, i) => (
        <img
          src={getBrickImg(piece)}
          alt={`Brick ${i}`}
          className={classnames(style.piece, style[`piece${piece.o.toUpperCase()}`])}
          style={{
            width: `${elementWidth * piece.w + 0}%`,
            left: `${(elementWidth * piece.x - 1)}%`,
            top: `${(elementHeight * piece.y) + 0}%`
          }}
          onClick={() => togglePiece(i)}
        />
      ))}
    </div>
  );
};
