import React from 'react';

import { Input, Form } from 'antd';
import WaitingRoom from '../WaitingRoom';
import { ClassicButton, GameBoard } from '../components';
import { useAccessCode, useDebugMode } from '../state';

import style from './AccessCode.module.css';

import logo from './assets/logo.png';

const AccessCode = () => {
  useDebugMode(); // enable debug mode if necessary
  const [{ loading, code }, { setCode }] = useAccessCode();

  if (code) {
    return <WaitingRoom />;
  }

  return (
    <GameBoard>
      <div className={style.accessCodeWrap}>
        <img src={logo} className={style.logo} alt="Parallel Lab Logo" />

        <h3>Enter the license key:</h3>

        <Form
          layout="horizontal"
          onFinish={({ accessCode }) => {
            setCode(accessCode.toLowerCase());
          }}
          initialValues={{ accessCode: code }}
        >
          <Form.Item
            className={style.accessCodeInput}
            name="accessCode"
            rules={[{
              required: true,
              message: 'Please input the License Key'
            }]}
          >
            <Input size="large" autoFocus autoComplete="off" />
          </Form.Item>

          <ClassicButton className={style.button} noBorder size="large" type="submit" disabled={loading}>{loading ? 'Loading...' : 'Continue'}</ClassicButton>

          <p className={style.noKey}>Don't have the key? Visit our <a href="https://elevenpuzzles.com/parallel-lab">website</a></p>
          {/* <a href="https://elevenpuzzles.com" className={style.EPlogo}><img src={EPlogo} alt="Eleven Puzzles Logo" /></a> */}
        </Form>
      </div>
    </GameBoard>
  );
};

export default AccessCode;
