import { db, useFirestoreQuery } from '../../../firebase';
import { useAccessCode } from '../../../state';
import { sounds } from '../../../components';

interface MobilePayload {
  unlocked: boolean;
}

export const initialState: MobilePayload = {
  unlocked: false
};

type MobileResponse = [MobilePayload, {
  onUnlocked: () => void;
}];

export function useMobileState(): MobileResponse {
  const [{ code }] = useAccessCode();
  const stateDoc = db.collection('game')
    .doc(code)
    .collection('l2Mobile')
    .doc('state');
  const { data } = useFirestoreQuery<MobilePayload>(stateDoc);

  const state = data || initialState;

  function onUnlocked() {
    sounds.success();
    stateDoc.set({
      unlocked: true
    });
  }

  return [state, { onUnlocked }];
}
