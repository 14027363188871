import React from 'react';
import { useComputerState } from './computerState';

import head from './assets/head.png';
import speaker from './assets/speaker.png';

import style from './Computer.module.css';

export const desktopImages = [
  head,
  speaker
];

export const DesktopInterface = () => {
  const [, { openSnake, openSound }] = useComputerState();

  return (
    <div className={style.screen}>
      <div className={style.shortcut1} onClick={() => openSnake()}>
        <img src={head} alt="Head" className={style.icon} />
        <p>Weightmate</p>
      </div>
      <div className={style.shortcut2} onClick={() => openSound()}>
        <img src={speaker} alt="Speaker" className={style.icon} />
        <p>Sound System</p>
      </div>
    </div>
  );
};
