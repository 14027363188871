import { useCallback } from 'react';
import { db, useFirestoreQuery } from '../../../firebase';
import { useAccessCode } from '../../../state';
import { sounds } from '../../../components';

const solution = ['Purple', 'Blue', 'Blue', 'Yellow'];

interface SynthesisPayload {
  solved: boolean;
  addedColors: string[];
}

export const initialState: SynthesisPayload = {
  solved: false,
  addedColors: []
};

type SynthesisResponse = [SynthesisPayload, {
  addColor: (color: string) => void;
  reset: () => void;
}];

export function useSynthesisState(): SynthesisResponse {
  const [{ code }] = useAccessCode();
  const stateDoc = db.collection('game')
    .doc(code)
    .collection('l3Synthesis')
    .doc('state');
  const { data } = useFirestoreQuery<SynthesisPayload>(stateDoc);

  const state = { ...initialState, ...data };

  const addColor = useCallback((color: string) => {
    if (state.solved) {
      sounds.error();
      return;
    }
    const addedColors = [...state.addedColors, color];
    stateDoc.set({ addedColors }, { merge: true });

    sounds.potion();

    if (addedColors.join('') === solution.join('')) {
      stateDoc.set({ solved: true }, { merge: true });
    }
  }, [stateDoc, state]);

  const reset = useCallback(() => {
    if (state.solved) {
      sounds.error();
      return;
    }
    stateDoc.set({ addedColors: [] }, { merge: true });
  }, [stateDoc, state]);

  return [state, { addColor, reset }];
}
