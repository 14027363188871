import { useCallback } from 'react';
import moment from 'moment';
import { db, useFirestoreQuery, UseFirestoreQueryResponse } from '../firebase';
import { useAccessCode } from './accessCode';

export type Game = {
  // in milliseconds timestamp
  startedAt: number;

  // when players solve bomb and enter the final stage
  bombArmed: boolean;
  bombDefused: boolean;
  bombStartedAt: number;

  // use for informational purposes only
  // should be used to determine where a player joining mid-game shuold end up
  level: string;

  level1Start?: number;
  level1End?: number;
  level2Start?: number;
  level2End?: number;
  level3Start?: number;
  level3End?: number;
  level4Start?: number;
  level4End?: number;
  level5Start?: number;
  level5End?: number;

  totalTime?: number;
  playTime?: number;

  // what hints they used?
  hints?: {
    [key: string]: number;
  };

  penalties?: number; // a penalty when cutting wrong bomb wires
}

function setStartDate(code: string) {
  db.collection('game')
    .doc(code)
    .set({
      startedAt: +moment()
    }, { merge: true });
}

function setBombArmed(code: string) {
  db.collection('game')
    .doc(code)
    .set({
      bombArmed: true
    }, { merge: true });
}

function setHints(code: string, itemName: string, hintNumber: number) {
  db.collection('game')
    .doc(code)
    .set({
      hints: {
        [itemName]: hintNumber
      }
    }, { merge: true });
}

function bombDefused(code: string) {
  db.collection('game')
    .doc(code)
    .set({
      bombDefused: true
    }, { merge: true });
}

type UseGameResponse = [
  UseFirestoreQueryResponse<Game>, {
    setGameStart: () => void;
    setBombStart: () => void;
    setBombDefused: () => void;
    logHint: (itemName: string, hintNumber: number) => void;
  }
]

export function useGame(): UseGameResponse {
  const [{ code }] = useAccessCode();
  const response = useFirestoreQuery<Game>(
    db.collection('game')
      .doc(code || '-')
  );
  const setGameStart = useCallback(() => setStartDate(code), [code]);
  const setBombStart = useCallback(() => setBombArmed(code), [code]);
  const setBombDefused = useCallback(() => bombDefused(code), [code]);
  const logHint = useCallback((itemName: string, hintNumber: number) => {
    if ((response.data?.hints?.[itemName] || 0) < hintNumber) {
      setHints(code, itemName, hintNumber);
    }
  }, [code, response.data]);

  return [response, { setGameStart, setBombStart, logHint, setBombDefused }];
}
