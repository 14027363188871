import React from 'react';

export const voices = {
  l2Phone: {
    file: './sounds/gustav/combination.mp3',
    text: 'Oh no! Have you forgotten the combination? One of the numbers is EIGHT!',
    delay: 8000,
    person: 'phone'
  },
  l2PhoneNotFound: {
    file: './sounds/phoneUnreachable.mp3',
    text: 'We\'re sorry. You have reached a number that has beed disconnected or is no longer in service.',
    delay: 8000,
    person: 'phone'
  },
  l5GustavFunny: {
    file: './sounds/gustav/poolTable.mp3',
    text: <>What's green, fuzzy, and if it falls out of tree, it'll kill you? <br />A pool table!</>,
    delay: 10000,
    person: 'speaker'
  },
  l5GustavTest: {
    file: './sounds/gustav/whoGoesThere.mp3',
    text: 'Who goes there?',
    delay: 3000,
    person: 'speaker'
  },
  l5GustavName: {
    file: './sounds/gustav/iAmGustav.mp3',
    text: 'I. Am. GUSTAV!',
    delay: 5000,
    person: 'speaker'
  },
  l5GustavWarning: {
    file: './sounds/gustav/doNotTouch.mp3',
    text: 'Attention! Do not touch that!',
    delay: 5000,
    person: 'speaker'
  },
  l5GustavShadow: {
    file: './sounds/gustav/shadow.mp3',
    text: 'I am merely my father\'s SHADOW...',
    delay: 4000,
    person: 'speaker'
  },
  l5GustavTimeToGO: {
    file: './sounds/gustav/timeToGo.mp3',
    text: 'Hey Christopher, time to go!',
    delay: 5000,
    person: 'speaker'
  },
  l5AllyFather: {
    file: './sounds/ally/gentlemanOnThisPainting.mp3',
    text: 'Gentleman on this painting looks familiar...',
    delay: 5000,
    person: 'ally'
  },
  l5AllyGas5Minutes: {
    file: './sounds/ally/thisGasNotDeadly.mp3',
    text: 'This gas isn\'t as deadly as it initially seemed... We might have a bit more time!',
    delay: 8000,
    person: 'ally'
  },
  l5OldDogGas5Minutes: {
    file: './sounds/old-dog/gas.mp3',
    text: 'This gas is scratching my throat. I need a drink! But apart from that, we might have a bit more time.',
    delay: 9000,
    person: 'stefan'
  },
  l5AllyNoVentilation: {
    file: './sounds/ally/noNeedToTouchThat.mp3',
    text: 'No need to touch that. I don\'t want to break it!',
    delay: 5000,
    person: 'ally'
  },
  l5OldDogNoVentilation: {
    file: './sounds/old-dog/better-not-touch.mp3',
    text: 'Better not to touch it. I\'d probably break it',
    delay: 5000,
    person: 'stefan'
  },
  l5AllyAlert: {
    file: './sounds/ally/ohNoWhatIsThis.mp3',
    text: 'Oh no, no! What is this?! What\'s happening?',
    delay: 5000,
    person: 'ally'
  },
  l5OldDogAlert: {
    file: './sounds/old-dog/messed-this-up.mp3',
    text: 'Oh, oh! We messed this up!',
    delay: 4000,
    person: 'stefan'
  },
  l5AllyVentilationFixed: {
    file: './sounds/ally/thatWasClose.mp3',
    text: 'That was close! Quick thinking saved us!',
    delay: 5000,
    person: 'ally'
  },
  l5OldDogVentilationFixed: {
    file: './sounds/old-dog/were-good-for-now.mp3',
    text: 'We\'re good for now. What a mess, I need a drink!',
    delay: 5000,
    person: 'stefan'
  },
  l5Alert: {
    file: './sounds/automatedMessage.mp3',
    text: 'INVALID CODE ENTERED. ADMINISTERING DEADLY GAS.',
    delay: 5000,
    person: 'speaker'
  },
  l5AllyDoor: {
    file: './sounds/ally/wereClose.mp3',
    text: 'We\'re close, let\'s not leave yet!',
    delay: 4000,
    person: 'ally'
  },
  l5OldDogDoor: {
    file: './sounds/old-dog/yeah-real-funny.mp3',
    text: 'Yeah, real funny. We shouldn\'t leave yet!',
    delay: 5000,
    person: 'stefan'
  },
  l5AllyDoorClosed: {
    file: './sounds/ally/ohNoTheDoorIsClosed.mp3',
    text: 'Oh no, the door is closed!',
    delay: 5000,
    person: 'ally'
  },
  l5OldDogMonkey: {
    file: './sounds/old-dog/its-a-monkey.mp3',
    text: 'Better not to touch it. It\'s a monkey!',
    delay: 5000,
    person: 'stefan'
  },
  l5OldDogDoorClosed: {
    file: './sounds/old-dog/well-its-shut.mp3',
    text: 'Well it\'s shut. What now?',
    delay: 5000,
    person: 'stefan'
  },
  l5AllyDoorFinal: {
    file: './sounds/ally/invalidCode.mp3',
    text: 'Wait! The alarm said "Invalid code entered". So, there has to be a VALID code to the safe!',
    delay: 10000,
    person: 'ally'
  },
  l5OldDogDoorFinal: {
    file: './sounds/old-dog/wait-this-monkey-funky.mp3',
    text: 'Wait... This monkey is acting funky. What\'s all that about?',
    delay: 5000,
    person: 'stefan'
  },
  l5OldDogDocumentsCollected: {
    file: './sounds/old-dog/ally-collected-docs.mp3',
    text: 'Ally collected the documents. Time to leave this place. Police should be arriving by now, they\'ll finish the job.',
    delay: 9000,
    person: 'stefan'
  },
  l5AllyDocumentsCollected: {
    file: './sounds/ally/documents.mp3',
    text: 'These documents... They\'re exactly what we\'re here for. We should leave, I don\'t want to spend another second in this place.',
    delay: 10000,
    person: 'ally'
  }
};
