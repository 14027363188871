import { useCallback } from 'react';
import { sounds } from '../../../components';
import { db, useFirestoreQuery } from '../../../firebase';
import { useAccessCode } from '../../../state';

interface DiscsPayload {
  B: number;
  TL: number;
  TR: number;
}

export const initialState: DiscsPayload = {
  B: 0,
  TL: 0,
  TR: 0
};

type DiscsResponse = [DiscsPayload, {
  rotate: (disc: string) => void;
}];

const playTurningSound = (disc: string) => {
  if (disc === 'B') {
    sounds.discTurn1();
  } else if (disc === 'TL') {
    sounds.discTurn2();
  } else {
    sounds.discTurn3();
  }
};

export function useDiscsState(): DiscsResponse {
  const [{ code }] = useAccessCode();
  const stateDoc = db.collection('game')
    .doc(code)
    .collection('l3Discs')
    .doc('state');
  const { data } = useFirestoreQuery<DiscsPayload>(stateDoc);
  const state = { ...initialState, ...data };

  const rotate = useCallback((disc: string) => {
    stateDoc.set({ [disc]: (state as any)[disc] + 1 }, { merge: true });
    playTurningSound(disc);
  }, [stateDoc, state]);

  return [state, { rotate }];
}