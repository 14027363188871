import React, { useState, useCallback } from 'react';
import classNames from 'classnames';

import style from './CartoonButton.module.css';

interface CartoonButtonProps {
  src: string;
  hoverSrc: string;
  activeSrc: string;
  onClick?: () => void;
  onUp?: () => void;
  onDown?: () => void;
  style?: React.CSSProperties;
  className?: string;
}

export const CartoonButton = (props: CartoonButtonProps) => {
  const [state, setState] = useState('');

  const onUp = useCallback(() => {
    if (!state) return;
    setState('');
    if (state === 'down') {
      props.onUp && props.onUp();
    }
  }, [state, props]);

  const onDown = useCallback(() => {
    setState('down');
    props.onDown && props.onDown();
  }, [props]);

  const onHover = useCallback(() => {
    setState('hover');
  }, []);

  const onClick = useCallback(() => {
    props.onClick && props.onClick();
  }, [props]);

  const src = (() => {
    switch (state) {
      case 'hover':
        return props.hoverSrc;
      case 'down':
        return props.activeSrc;
      default:
        return props.src;
    }
  })();

  return (
    <img
      className={classNames(style.button, props.className)}
      style={{ ...props.style }}
      onMouseDown={() => onDown()}
      onMouseUp={() => onUp()}
      onMouseEnter={() => onHover()}
      onMouseLeave={() => onUp()}
      onTouchStart={() => onDown()}
      onTouchCancel={() => onUp()}
      onTouchEnd={() => onUp()}
      onClick={() => onClick()}
      onDragStart={ev => ev.preventDefault()} // Fix Firefox drag problem
      alt="button"
      src={src}
    />
  );
};
