import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

import { useTimer, formatTimer } from '../../state';

import style from './GameBoard.module.css';
import timerBg from './assets/timerGas.png';

export const Timer: FunctionComponent<{}> = () => {
  const [{ mode, timer, bombDefused }] = useTimer(true);
  const isBombActivated = mode === 'bomb' || mode === 'bomb2';

  if (!isBombActivated || bombDefused) return <></>;

  return (
    <div
      className={classnames(style.timerContainer)}
      style={{ backgroundImage: `url(${timerBg})` }}
    >
      <p className={classnames(style.timer)}>
        {timer ? formatTimer(timer) : '-'}
      </p>
    </div>
  );
};
