import React from 'react';

import { CustomDragLayer } from './CustomDragLayer';
import { Dropzone } from './Dropzone';
import { Arrow, arrowImages } from './Arrow';
import { mouseImages, MouseOverlay } from './MouseOverlay';
import { GameItem } from '../../../components';
import { useCurrentPlayer } from '../../../state';
import { useBlindMiceState } from './miceState';

import blindMiceBackground from './assets/bg.png';

export const blindMiceImages = [
  blindMiceBackground,
  ...arrowImages,
  ...mouseImages
];

const hintsOldDog = [
  'To solve the puzzle, you\'ll need a labyrinth with two blind mice in Old Dog\'s and Ally\'s room.',
  'Collaborating with Ally, each of you should place their arrows on the proper places in the labyrinth so that lefty and righty reach the cheese.',
  <>
    The arrows should be placed (counting form from the left top): <br />
    first up arrow - second column and fourth line, <br />
    second up arrow - third column and second line, <br />
    first left arrow - fourth column and fourth line, <br />
    second left arrow - fifth column and fourth line.
  </>
];

const hintsAlly = [
  'To solve the puzzle, you\'ll need a labyrinth with two blind mice in Old Dog\'s and Ally\'s room.',
  'Collaborating with Old Dog, each of you should place their arrows on the proper places in the labyrinth so that lefty and righty reach the cheese.',
  <>
    The arrows should be placed (counting form from the left top): <br />
    first up arrow - second column and fourth line, <br />
    second up arrow - third column and second line, <br />
    first left arrow - fourth column and fourth line, <br />
    second left arrow - fifth column and fourth line.
  </>
];

// [x, y]
// [top, left] = [0, 0]
const GRID = [
  [0, 0], [1, 0], [2, 0], [3, 0], [4, 0],
  [0, 1], [1, 1], [2, 1], [3, 1], [4, 1], [5, 1],
  [0, 2], [1, 2], [3, 2], [4, 2], [5, 2],
  [0, 3], [1, 3], [2, 3], [3, 3], [4, 3],
  [0, 4], [1, 4], [2, 4], [3, 4], [4, 4], [5, 4],
  [0, 5], [1, 5], [2, 5], [3, 5], [4, 5], [5, 5]
];

export const BlindMice = () => {
  const [{ isOldDog }] = useCurrentPlayer();
  const [{ arrow0, arrow1, arrow2, arrow3, playing, solved }, { moveArrow }] = useBlindMiceState();

  return (
    <GameItem
      name={isOldDog ? 'l5BlindMiceOldDog' : 'l5BlindMiceAlly'}
      hints={isOldDog ? hintsOldDog : hintsAlly}
      imageSrc={blindMiceBackground}
      iconStyle={isOldDog ? {
        top: '29vw',
        left: '69vw',
        width: '30vw',
        height: '26vw'
      } : {
        top: '27vw',
        left: '0vw',
        width: '22vw',
        height: '25vw'
      }}
    >
      <CustomDragLayer />
      <Arrow i={0} direction="left" onDrop={moveArrow} position={arrow0} isBlueArrow={isOldDog} canDrag={!playing && !solved} />
      <Arrow i={1} direction="left" onDrop={moveArrow} position={arrow1} isBlueArrow={isOldDog} canDrag={!playing && !solved} />
      <Arrow i={2} direction="up" onDrop={moveArrow} position={arrow2} isBlueArrow={!isOldDog} canDrag={!playing && !solved} />
      <Arrow i={3} direction="up" onDrop={moveArrow} position={arrow3} isBlueArrow={!isOldDog} canDrag={!playing && !solved} />
      {GRID.map(([x, y]) => <Dropzone key={`${x}-${y}`} x={x} y={y} />)}
      <Dropzone x={0} y={0} base={true} />
      <MouseOverlay />
    </GameItem>
  );
};
