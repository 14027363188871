/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { db, useFirestoreQuery } from '../../../firebase';
import { useAccessCode, useCurrentPlayer, useGame } from '../../../state';
import { sounds } from '../../../components';

export const GRID = [6, 6];

export interface Piece {
  x: number;
  y: number;
  w: number;
  h: number;
  v?: boolean;
  o: 'h' | 'v'; // orientation
}

// [type, blue, rotation, connected]
type PipePiece = ['I' | 'L' | 'E' | 'T', boolean, number, boolean];

const PIPES_DEFAULT: PipePiece[][] = [
  [['L', true, 2, false], ['T', false, 0, false], ['L', false, 0, false], ['E', true, 0, false]],
  [['E', false, 0, false], ['T', true, 0, false], ['L', true, 0, false], ['L', false, 0, false]],
  [['L', true, 0, false], ['T', true, 0, false], ['T', false, 0, false], ['L', true, 0, false]],
  [['L', false, 0, false], ['I', false, 0, false], ['T', true, 0, false], ['T', false, 0, false]]
];

interface VentilationData {
  caseOpenA: boolean;
  caseOpenB: boolean;
  gridSolved: boolean;
  pieces: Piece[];
  pipe00: PipePiece;
  pipe01: PipePiece;
  pipe02: PipePiece;
  pipe03: PipePiece;
  pipe10: PipePiece;
  pipe11: PipePiece;
  pipe12: PipePiece;
  pipe13: PipePiece;
  pipe20: PipePiece;
  pipe21: PipePiece;
  pipe22: PipePiece;
  pipe23: PipePiece;
  pipe30: PipePiece;
  pipe31: PipePiece;
  pipe32: PipePiece;
  pipe33: PipePiece;
  pipesSolved: boolean;
  endReached: boolean;
  allPiecesConnected: boolean;
  connectedPiecesCount: number;
  noLeaks: boolean;
}

interface VentilationPayload {
  caseOpenA: boolean;
  caseOpenB: boolean;
  gridSolved: boolean;
  pieces: Piece[];
  pipes: PipePiece[][];
  pipesSolved: boolean;
  endReached: boolean;
  allPiecesConnected: boolean;
  noLeaks: boolean;
}

const getPipeConnections = ([type, , rotation]: PipePiece) => {
  if (type === 'I') {
    return rotation % 2 ? ['left', 'right'] : ['top', 'bottom'];
  }
  if (type === 'L') {
    switch (rotation % 4) {
      case 0:
        return ['top', 'right'];
      case 1:
        return ['right', 'bottom'];
      case 2:
        return ['bottom', 'left'];
      default:
        return ['left', 'top'];
    }
  }
  if (type === 'E') {
    switch (rotation % 4) {
      case 1:
        return ['top'];
      case 2:
        return ['right'];
      case 3:
        return ['bottom'];
      default:
        return ['left'];
    }
  }
  if (type === 'T') {
    switch (rotation % 4) {
      case 0:
        return ['top', 'right', 'bottom'];
      case 1:
        return ['right', 'bottom', 'left'];
      case 2:
        return ['bottom', 'left', 'top'];
      default:
        return ['left', 'top', 'right'];
    }
  }
  return [];
};

const validatePipeSolution = (pieces: PipePiece[][], start: [number, number], end: [number, number]) => {
  const updatedPieces = _.cloneDeep(pieces);
  updatedPieces.forEach(row => row.forEach((piece) => {
    piece[3] = false;
  }));

  // perform BFS search
  // build a tree
  const coordinatesToCheck = [[0, 0, -1, 1]];
  const visitedNodes: string[] = [];

  let coordinateToCheck = coordinatesToCheck.pop();
  let endReached = false;

  while (coordinateToCheck) {
    const [i, j, fromI, fromJ] = coordinateToCheck;
    const connections = getPipeConnections(updatedPieces[i][j]);
    visitedNodes.push(`${i}-${j}-${fromI}-${fromJ}`);

    // 1. check if connected
    let isConnected = false;
    connections.forEach((connection) => {
      if (connection === 'left' && ((j - 1 >= 0 && getPipeConnections(updatedPieces[i][j - 1]).indexOf('right') !== -1 && updatedPieces[i][j - 1][3]) || _.isEqual([i, j - 1], start))) {
        isConnected = true;
      }
      if (connection === 'right' && j + 1 <= 3 && getPipeConnections(updatedPieces[i][j + 1]).indexOf('left') !== -1 && updatedPieces[i][j + 1][3]) {
        isConnected = true;
      }
      if (connection === 'top' && ((i - 1 >= 0 && getPipeConnections(updatedPieces[i - 1][j]).indexOf('bottom') !== -1 && updatedPieces[i - 1][j][3]) || _.isEqual([i - 1, j], start))) {
        isConnected = true;
      }
      if (connection === 'bottom' && i + 1 <= 3 && getPipeConnections(updatedPieces[i + 1][j]).indexOf('top') !== -1 && updatedPieces[i + 1][j][3]) {
        isConnected = true;
      }
    });

    // 2. add connected nodes
    if (isConnected) {
      updatedPieces[i][j][3] = true;

      // eslint-disable-next-line no-loop-func
      connections.forEach((connection) => {
        let nextCoordinate;
        if (connection === 'left' && j - 1 >= 0) {
          nextCoordinate = [i, j - 1];
        }
        if (connection === 'right' && j + 1 <= 3) {
          nextCoordinate = [i, j + 1];
        }
        if (connection === 'top' && i - 1 >= 0) {
          nextCoordinate = [i - 1, j];
        }
        if (connection === 'bottom' && i + 1 <= 3) {
          nextCoordinate = [i + 1, j];
        }
        if (connection === 'right' && _.isEqual([i, j + 1], end)) {
          endReached = true;
        }
        if (connection === 'bottom' && _.isEqual([i + 1, j], end)) {
          endReached = true;
        }

        if (nextCoordinate && visitedNodes.indexOf(`${nextCoordinate[0]}-${nextCoordinate[1]}-${i}-${j}`) === -1) {
          coordinatesToCheck.push([...nextCoordinate, i, j]);
        }
      });
    }

    coordinateToCheck = coordinatesToCheck.pop();
  }

  let allPiecesConnected = true;
  let connectedPiecesCount = 0;
  let noLeaks = true;
  updatedPieces.forEach((row, i) => row.forEach((piece, j) => {
    const connections = getPipeConnections(piece);
    if (!piece[3]) {
      allPiecesConnected = false;
      return; // don't analyze disconnected pieces
    }
    connectedPiecesCount++;

    // const connections = getPipeConnections(piece);
    connections.forEach((connection) => {
      const outOfBoundsLeft = j - 1 < 0;
      const outOfBoundsRight = j + 1 > 3;
      const outOfBoundsTop = i - 1 < 0;
      const outOfBoundsBottom = i + 1 > 3;

      if (connection === 'left') {
        if (outOfBoundsLeft) {
          noLeaks = false;
        } else if (getPipeConnections(updatedPieces[i][j - 1]).indexOf('right') === -1) {
          noLeaks = false;
        }
      }
      if (connection === 'right') {
        if (outOfBoundsRight) {
          noLeaks = false;
        } else if (getPipeConnections(updatedPieces[i][j + 1]).indexOf('left') === -1) {
          noLeaks = false;
        }
      }
      if (connection === 'top') {
        if (outOfBoundsTop) {
          if (j > 0) { // ignore OOB end
            noLeaks = false;
          }
        } else if (getPipeConnections(updatedPieces[i - 1][j]).indexOf('bottom') === -1) {
          noLeaks = false;
        }
      }
      if (connection === 'bottom') {
        if (outOfBoundsBottom) {
          if (j < 3) { // ignore OOB end
            noLeaks = false;
          }
        } else if (getPipeConnections(updatedPieces[i + 1][j]).indexOf('top') === -1) {
          noLeaks = false;
        }
      }
    });
  }));

  return {
    pieces: updatedPieces,
    allPiecesConnected,
    endReached,
    noLeaks: noLeaks && connectedPiecesCount > 0,
    connectedPiecesCount,
    solved: endReached && noLeaks
  };
};

export const initialState: VentilationData = {
  gridSolved: false,
  pieces: [
    { x: 2, y: 5, w: 3, h: 1, o: 'h' },
    { x: 1, y: 3, w: 3, h: 1, o: 'h' },
    { x: 2, y: 1, w: 2, h: 1, o: 'h' },
    { x: 2, y: 0, w: 3, h: 1, o: 'h' },
    { x: 3, y: 4, w: 2, h: 1, o: 'h' },
    { x: 1, y: 0, w: 1, h: 2, o: 'v' },
    { x: 4, y: 1, w: 1, h: 3, o: 'v' },
    { x: 0, y: 3, w: 1, h: 3, o: 'v' },
    { x: 5, y: 0, w: 1, h: 2, o: 'v' }
  ],
  pipe00: PIPES_DEFAULT[0][0],
  pipe01: PIPES_DEFAULT[0][1],
  pipe02: PIPES_DEFAULT[0][2],
  pipe03: PIPES_DEFAULT[0][3],
  pipe10: PIPES_DEFAULT[1][0],
  pipe11: PIPES_DEFAULT[1][1],
  pipe12: PIPES_DEFAULT[1][2],
  pipe13: PIPES_DEFAULT[1][3],
  pipe20: PIPES_DEFAULT[2][0],
  pipe21: PIPES_DEFAULT[2][1],
  pipe22: PIPES_DEFAULT[2][2],
  pipe23: PIPES_DEFAULT[2][3],
  pipe30: PIPES_DEFAULT[3][0],
  pipe31: PIPES_DEFAULT[3][1],
  pipe32: PIPES_DEFAULT[3][2],
  pipe33: PIPES_DEFAULT[3][3],
  pipesSolved: false,
  caseOpenA: false,
  caseOpenB: false,
  endReached: false,
  allPiecesConnected: false,
  connectedPiecesCount: 0,
  noLeaks: false
};

type VentilationResponse = [VentilationPayload, {
  togglePiece: (i: number) => void;
  rotatePipe: (i: number, j: number) => void;
}];

export const checkIfSolved = (pieces: Piece[]) => {
  for (let i = 0; i < pieces.length; i++) {
    const piece = pieces[i];
    if (piece.x <= 2 && piece.x + piece.w >= 4) {
      return false;
    }
  }
  return true;
};

export function useVentilationState(): VentilationResponse {
  const [{ isOldDog }] = useCurrentPlayer();
  const [{ code }] = useAccessCode();
  const [, { setBombDefused }] = useGame();
  const stateDoc = db.collection('game')
    .doc(code)
    .collection('l5Ventilation')
    .doc('state');
  const { data } = useFirestoreQuery<VentilationData>(stateDoc);

  const start: [number, number] = [-1, 0];
  const end: [number, number] = [4, 3];

  const state: any = { ...initialState, ...data };
  const { pieces } = state;
  const pipes = [
    [state.pipe00, state.pipe01, state.pipe02, state.pipe03],
    [state.pipe10, state.pipe11, state.pipe12, state.pipe13],
    [state.pipe20, state.pipe21, state.pipe22, state.pipe23],
    [state.pipe30, state.pipe31, state.pipe32, state.pipe33]
  ];
  const { pieces: pipesWithConnections } = validatePipeSolution(pipes, start, end);
  state.pipes = pipesWithConnections;

  const isCollision = (x: number, y: number) => {
    for (const piece of pieces) {
      if (x >= piece.x && x <= piece.x + piece.w - 1 && y >= piece.y && y <= piece.y + piece.h - 1) {
        return true;
      }
    }
    return false;
  };

  const probeUp = (piece: Piece) => {
    for (let y = piece.y - 1; y >= 0; y--) {
      if (isCollision(piece.x, y)) {
        return y + 1;
      }
    }
    return 0;
  };

  const probeDown = (piece: Piece) => {
    for (let y = piece.y + piece.h; y <= GRID[1]; y++) {
      if (isCollision(piece.x, y)) {
        return y - piece.h;
      }
    }
    return GRID[1] - piece.h;
  };

  const probeLeft = (piece: Piece) => {
    for (let x = piece.x - 1; x >= 0; x--) {
      if (isCollision(x, piece.y)) {
        return x + 1;
      }
    }
    return 0;
  };

  const probeRight = (piece: Piece) => {
    for (let x = piece.x + piece.w; x <= GRID[0]; x++) {
      if (isCollision(x, piece.y)) {
        return x - piece.w;
      }
    }
    return GRID[0] - piece.w;
  };

  const togglePiece = (i: number) => {
    if (isOldDog && pieces[i].o === 'h') {
      sounds.knock();
      return;
    }
    if (!isOldDog && pieces[i].o === 'v') {
      sounds.knock();
      return;
    }
    sounds.weapon();
    const updatedPieces = [...pieces];
    const piece = updatedPieces[i];
    if (piece.o === 'v') {
      const yUp = probeUp(piece);
      const yDown = probeDown(piece);
      piece.y = piece.y === yUp ? yDown : yUp;
    } else {
      const xLeft = probeLeft(piece);
      const xRight = probeRight(piece);
      piece.x = piece.x === xLeft ? xRight : xLeft;
      if (checkIfSolved(updatedPieces)) {
        sounds.success();
        setTimeout(() => {
          stateDoc.set({
            gridSolved: true
          }, { merge: true });
        }, 1000);
      }
    }

    stateDoc.set({
      pieces: updatedPieces
    }, { merge: true });
  };

  const rotatePipe = (i: number, j: number) => {
    if (state.pipesSolved) {
      sounds.error();
      return;
    }

    const isBlue = pipes[i][j][1];
    if (isBlue !== isOldDog) {
      sounds.error();
      return;
    }

    sounds.weapon();
    const updatedPipes = _.cloneDeep(pipes);
    updatedPipes[i][j][2] += 1;
    const solution = validatePipeSolution(updatedPipes, start, end);
    if (solution.solved) {
      sounds.success();
    }

    if (solution.solved && state.pipesSolved) {
      setBombDefused();
    }
    stateDoc.set({
      [`pipe${i}${j}`]: updatedPipes[i][j],
      allPiecesConnected: solution.allPiecesConnected,
      connectedPiecesCount: solution.connectedPiecesCount,
      endReached: solution.endReached,
      noLeaks: solution.noLeaks,
      pipesSolved: solution.solved
    }, { merge: true });
  };

  return [state, { togglePiece, rotatePipe }];
}
