import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { GameItem } from '../../../components';
import { CustomDragLayer } from './CustomDragLayer';
import { useMicroscope } from './microscopeState';
import { Sample, sampleImages } from './Sample';
import { DropzoneMicroscope } from './DropzoneMicroscope';
import { DropzoneOut } from './DropzoneOut';

import style from './Microscope.module.css';

import bg from './assets/bg.png';

import virusBlue from './assets/virus1.png';
import virusRed from './assets/virus2.png';
import virusBlack from './assets/virus3.png';
import virusGreen from './assets/virus4.png';
import virusOrange from './assets/virus5.png';
import virusYellow from './assets/virus6.png';
import virusPink from './assets/virus7.png';
import dragTutorial from './assets/dragTutorial.png';

export const microscopeImages = [
  bg,
  virusBlue,
  virusGreen,
  virusRed,
  virusBlack,
  virusYellow,
  virusPink,
  virusOrange,
  dragTutorial,
  ...sampleImages
];

const hints = [
  'To solve this puzzle, you will need a microscope in Old Dog’s room and a scientific paper in Ally’s room.',
  'Communicate with Ally. Maybe she has some images of microorganisms that you can find under the microscope? Place each slide under the microscope.',
  'The slides have different colors. Maybe they may need to be put in the correct order. The scientific paper in Ally’s room will help you.',
  'The order is first - blue, second - green, third - red, fourth - yellow.'
];

export const Microscope = () => {
  const [{ insertedVirus }, { insertVirus }] = useMicroscope();
  const [isDragging, setIsDragging] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [tutorialVisible, setTutorialVisible] = useState(false);

  const onDrag = () => setIsDragging(true);

  const onDrop = (sample: string, where: string) => {
    if (where === 'microscope') {
      insertVirus(sample);
    } else if (where === 'out') {
      insertVirus('');
    }
    setIsDragging(false);
  };

  useEffect(() => {
    if (!modalVisible) return;
    if (!insertedVirus) {
      const timeout = setTimeout(() => setTutorialVisible(true), 5000);
      // eslint-disable-next-line consistent-return
      return () => clearTimeout(timeout);
    }
    setTutorialVisible(false);
  }, [modalVisible, insertedVirus]);

  return (
    <GameItem
      name="l4Microscope"
      imageSrc={bg}
      hints={hints}
      onOpen={() => setModalVisible(true)}
      onClose={() => setModalVisible(false)}
      iconStyle={{
        top: '21vw',
        left: '0vw',
        width: '20vw',
        height: '33vw'
      }}
    >
      <CustomDragLayer />
      <Sample name="Blue" onDrop={onDrop} onDrag={onDrag} inserted={insertedVirus === 'Blue'} />
      <Sample name="Green" onDrop={onDrop} onDrag={onDrag} inserted={insertedVirus === 'Green'} />
      <Sample name="Black" onDrop={onDrop} onDrag={onDrag} inserted={insertedVirus === 'Black'} />
      <Sample name="Red" onDrop={onDrop} onDrag={onDrag} inserted={insertedVirus === 'Red'} />
      <Sample name="Pink" onDrop={onDrop} onDrag={onDrag} inserted={insertedVirus === 'Pink'} />
      <Sample name="Yellow" onDrop={onDrop} onDrag={onDrag} inserted={insertedVirus === 'Yellow'} />
      <Sample name="Orange" onDrop={onDrop} onDrag={onDrag} inserted={insertedVirus === 'Orange'} />
      <DropzoneMicroscope isDragging={isDragging} />
      <DropzoneOut isDragging={isDragging} />

      <img src={virusBlue} alt="Virus Blue" className={style.virus} style={{ opacity: insertedVirus === 'Blue' ? 1 : 0 }} />
      <img src={virusGreen} alt="Virus Green" className={style.virus} style={{ opacity: insertedVirus === 'Green' ? 1 : 0 }} />
      <img src={virusRed} alt="Virus Red" className={style.virus} style={{ opacity: insertedVirus === 'Red' ? 1 : 0 }} />
      <img src={virusBlack} alt="Virus Black" className={style.virus} style={{ opacity: insertedVirus === 'Black' ? 1 : 0 }} />
      <img src={virusYellow} alt="Virus Yellow" className={style.virus} style={{ opacity: insertedVirus === 'Yellow' ? 1 : 0 }} />
      <img src={virusPink} alt="Virus Pink" className={style.virus} style={{ opacity: insertedVirus === 'Pink' ? 1 : 0 }} />
      <img src={virusOrange} alt="Virus Orange" className={style.virus} style={{ opacity: insertedVirus === 'Orange' ? 1 : 0 }} />

      <img src={dragTutorial} alt="Drag Tutorial" className={classNames(style.dragTutorial, 'animate__animated animate__pulse animate__infinite')} style={{ opacity: tutorialVisible ? 1 : 0 }} />
    </GameItem>
  );
};
