import React from 'react';
import { useDrag, DragSourceMonitor } from 'react-dnd';

import style from './PhTable.module.css';

import paper from './assets/paper.png';
import paperBlue from './assets/paperBlue.png';
import paperGreen from './assets/paperGreen.png';
import paperOrange from './assets/paperOrange.png';
import paperViolet from './assets/paperViolet.png';
import paperYellow from './assets/paperYellow.png';
import paperRed from './assets/paperRed.png';

export const paperImages = [
  paper,
  paperBlue,
  paperGreen,
  paperOrange,
  paperViolet,
  paperYellow,
  paperRed
];

export function getPaperImg(name: string) {
  switch (name) {
    case 'normal':
      return paper;
    case 'blue':
      return paperBlue;
    case 'green':
      return paperGreen;
    case 'orange':
      return paperOrange;
    case 'violet':
      return paperViolet;
    case 'red':
      return paperRed;
    case 'yellow':
      return paperYellow;
    default:
      return paper;
  }
}

interface PaperImageProps {
  name: string;
}

export const PaperImage: React.FC<PaperImageProps> = ({ name }) => {
  const paperImg = getPaperImg(name);
  return (
    <div
      style={{ backgroundImage: `url(${paperImg})` }}
      className={style.paper}
    />
  );
};

interface PaperProps {
  name: string;
  onDrop: (bottleNumber: number) => void;
}

export const Paper: React.FC<PaperProps> = ({ name, onDrop }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { name, type: 'paper' },
    end: (item: { name: string } | undefined, monitor: DragSourceMonitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onDrop(dropResult?.bottleNumber);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;

  return (
    <div ref={drag} style={{ opacity }} className={style.paperDrag}>
      <PaperImage name={name} />
    </div>
  );
};
