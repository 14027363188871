import React from 'react';
import classNames from 'classnames';

import { useScaleState, calculateTotalWeight } from './scaleState';
import { useCurrentPlayer } from '../../../state';

import style from './Scales.module.css';

import marker from './assets/marker.png';
import markerRed from './assets/markerRed.png';
import digitalScale from './assets/digitalScale.png';
import digitalScaleRed from './assets/digitalScaleRed.png';
import textUnbalanced from './assets/textUnbalanced.png';
import textBalanced from './assets/textBalanced.png';
import textOutOfBounds from './assets/textOutOfBounds.png';

export const screenImages = [];

export const Screen = () => {
  const [{ isOldDog }] = useCurrentPlayer();
  const [{ weightsOnScale, errored, solved }] = useScaleState();
  const totalWeight = calculateTotalWeight(weightsOnScale);

  const markerOffset = (((11.35 - 1.15) / 5) * totalWeight);

  const text = (() => {
    if (errored) return textOutOfBounds;
    if (solved) return textBalanced;
    return textUnbalanced;
  })();

  return (
    <div className={classNames(style.screen, isOldDog && style.screenOldDog, errored && style.screenErrored)}>
      <img src={text} className={style.statusText} alt="Status" />
      <img src={errored ? markerRed : marker} className={style.marker} alt="Marker" style={{ transform: `translateX(${markerOffset}vw)` }} />
      <img src={errored ? digitalScaleRed : digitalScale} alt="Digital Scale" className={style.digitalScale} />
    </div>
  );
};
