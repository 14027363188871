import React from 'react';

import { GameItem, CartoonButton } from '../../../components';
import { useZebrafishState } from './zebrafishState';

import style from './Control.module.css';

import background from './assets/control.png';
import tapCold from './assets/tapCold.png';
import tapHot from './assets/tapHot.png';
import arrowLeft from './assets/turnArrowLeft.png';
import arrowRight from './assets/turnArrowRight.png';
import arrowLeftHover from './assets/turnArrowHoverLeft.png';
import arrowRightHover from './assets/turnArrowHoverRight.png';
import arrowLeftClick from './assets/turnArrowClickLeft.png';
import arrowRightClick from './assets/turnArrowClickRight.png';

export const controlImages = [
  background,
  tapCold,
  tapHot,
  arrowLeft,
  arrowRight,
  arrowLeftHover,
  arrowRightHover,
  arrowLeftClick,
  arrowRightClick
];

const hints = [
  'To solve the puzzle, you\'ll need a device with hot and cold taps in Old Dog\'s room and an aquarium with fish in Ally\'s room.',
  'Ask Ally if there are any requirements for mating for the fish.',
  'Set the best temperature for fish mating using hot and cold taps. Collaborate with Ally.',
  'Look closely at the device - there are zebras on one plate. What a coincidence Ally can see zebrafish in the aquarium! Hmm, there must be a connection.',
  'Collaborate with Ally. She will tell you pairs of numbers (horizontal/vertical), which you can use on the grid with letters and numbers to give you a letter.',
  'The solution is E-I-G-H-T (eight).'
];

export const Control = () => {
  const [{ coldValue, hotValue }, { turn }] = useZebrafishState();

  return (
    <GameItem
      name="l5Control"
      imageSrc={background}
      hints={hints}
      iconStyle={{
        top: '29.8vw',
        left: '0vw',
        width: '17vw',
        height: '24vw'
      }}
    >
      <CartoonButton className={style.tapColdLeft} src={arrowLeft} hoverSrc={arrowLeftHover} activeSrc={arrowLeftClick} onClick={() => turn('left', 'cold')} />
      <CartoonButton className={style.tapColdRight} src={arrowRight} hoverSrc={arrowRightHover} activeSrc={arrowRightClick} onClick={() => turn('right', 'cold')} />
      <CartoonButton className={style.tapHotLeft} src={arrowLeft} hoverSrc={arrowLeftHover} activeSrc={arrowLeftClick} onClick={() => turn('left', 'hot')} />
      <CartoonButton className={style.tapHotRight} src={arrowRight} hoverSrc={arrowRightHover} activeSrc={arrowRightClick} onClick={() => turn('right', 'hot')} />
      <img src={tapCold} alt="Tap Cold" className={style.tapCold} style={{ transform: `rotate(${coldValue * 90}deg)` }} />
      <img src={tapHot} alt="Tap Hot" className={style.tapHot} style={{ transform: `rotate(${hotValue * 90}deg)` }} />
    </GameItem>
  );
};
