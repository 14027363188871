import React from 'react';
import classNames from 'classnames';
import { useDrag, DragSourceMonitor } from 'react-dnd';

import { sounds } from '../../../components';

import style from './VialMachine.module.css';

import vial from './assets/vmVial.png';

export const vialImages = [
  vial
];

interface VialImageProps {
  vialNumber: string;
}

export const VialImage: React.FC<VialImageProps> = ({ vialNumber }) => (
  <div
    style={{ backgroundImage: `url(${vial})` }}
    className={classNames(style.vial, style[`vial${vialNumber}`])}
  />
);

interface VialProps {
  vialNumber: string;
  onDrop: (vialNumber: string, slot: string) => void;
}

export const Vial: React.FC<VialProps> = ({ onDrop, vialNumber }) => {
  const [{ isDragging }, drag] = useDrag({
    begin: () => {
      sounds.tapOnGlass();
    },
    item: { name: `Vial ${vialNumber}`, type: 'vial', vialNumber },
    end: (item: { name: string } | undefined, monitor: DragSourceMonitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onDrop(vialNumber, dropResult.slot);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;

  return (
    <div
      ref={drag}
      style={{ opacity }}
      className={classNames(style.vialDrag, style[`vialDrag${vialNumber}`])}
    >
      <VialImage vialNumber={vialNumber} />
    </div>
  );
};
