import React, { useState, useEffect } from 'react';

import { GameBoard, GameItem, GameLock, sounds } from '../../components';
import { useCurrentPlayer, useGame } from '../../state';
import { Mobile, mobileImages } from './Mobile';
import { Monitor, COLORS, INTERVAL, NOISE_INTERVAL, monitorImages } from './Monitor';
import { Loading } from '../../Loading';

import style from './Level2.module.css';

import reception from './assets/ally/bg.png';
import computerRoom from './assets/oldDog/bg.png';
import symbols from './assets/symbols.png';
import newspaper from './assets/newspaper.png';
import lock from './assets/lock.png';
import phonebook from './assets/phonebook.png';
import books from './assets/books.png';
import battleship1 from './assets/battleship1.png';
import monitorRed from './assets/oldDog/light_tv_red.png';
import monitorBlue from './assets/oldDog/light_tv_blue.png';
import monitorGreen from './assets/oldDog/light_tv_green.png';
import monitorYellow from './assets/oldDog/light_tv_yellow.png';
import lightMobile from './assets/oldDog/light_mobile.png';
import lightMonitor from './assets/oldDog/light_monitor.png';
import framedNewspaper from './assets/framedNewspaper.png';

export const level2Images = [
  reception,
  computerRoom,
  symbols,
  newspaper,
  lock,
  phonebook,
  books,
  battleship1,
  monitorRed,
  monitorBlue,
  monitorGreen,
  monitorYellow,
  lightMobile,
  lightMonitor,
  framedNewspaper,
  ...mobileImages,
  ...monitorImages
];

const lockSolutions = [
  { combination: '3845', correct: true }
];

const hintsBattleshipsOldDog = [
  'The puzzle requires both the note in Old Dog’s room and the Battleship game found in Ally’s room. ',
  'What are these names on the note? Maybe they suggest which letter Ally should look at?',
  'This puzzle requires cooperation to be solved. You should pass Ally the information: D2, G4, G8, E5.',
  'When Ally crosses out hit ships, they should form a number. This number is 4.'
];

const hintsSquareSymboldAlly = [
  'Solving the puzzle requires a SUDOKU puzzle in the framed newspaper in Old Dog’s room.',
  'After solving the SUDOKU, Old Dog should give you numbers in cells with black triangles (each in a different corner of the cell).',
  'A cell with a triangle in the upper left corner gives 1, a cell with a triangle in the lower-left corner gives 3,  a cell with a triangle in the upper right corner gives 3, and a cell with a triangle in the lower right corner gives 2. Now do the math, and you will get the solution.',
  'When you calculate 3+3-2+1, you get 5.'
];

const hintsLock = [
  'To unlock the padlock, you need to solve all puzzles in this room. They will all require cooperation.',
  'The drawings above the numbers will tell you where each puzzle\'s solution should go.',
  'The drawings indicate solutions to the following puzzles: book → a bookshelf in Ally\'s level, phone → a phone and a phonebook, four vertical squares → a battleship puzzle, big square with a black triangle → sudoku.',
  'The solution is: 3845.'
];

const hintsBooksAlly = [
  'The puzzle requires both the books on shelves in Ally\'s room and the blinking monitor, which Old Dog can find in his room.',
  'To solve the puzzle, you need cooperation. First, you should ask Old Dog to look closely at the monitor to see which colors and in what order are blinking.',
  'Old Dog should pass you the sequence: red, blue, green, yellow, and yellow. Now, look closely at the books. Are there any books in such colors? Is there anything written on them?',
  'The color sequence and the books with letters on them should give you answer: red - T, blue - H, green - R,  yellow - E, yellow - E - THREE. Thus, the solution is number 3.'
];

const hintsBattleshipsAlly = [
  'The puzzle requires both the Battleship game in Ally’s room and the note in Old Dog’s room.',
  'It might be helpful to pass the information on the most common lock combination to Old Dog.',
  'You need cooperation to solve this puzzle. Ask Old Dog for hit coordinates. Will they sink any ship?',
  'Old Dog should pass you the coordinates: D2, G4, G8, E5. You should cross them out. Remember, the rules of this game are changed. One hit sinks the ship!',
  'You should see a number formed when you cross out the hit ships. This number is 4.'
];

const hintsFramedNewspaper = [
  'This newspaper has a puzzle that could be worth completing and provides information for a puzzle in Ally’s room.',
  'You should solve the SUDOKU puzzle by completing each row and column correctly. Remember that no number can be repeated in any row or column.',
  'Do the black triangles in some cells of the SUDOKU puzzle matter? Maybe it’s helpful to pass that information to Ally.',
  'A cell with a triangle in the upper left corner gives 1, a cell with a triangle in the lower left corner gives 3,  a cell with a triangle in the upper right corner gives 3, and a cell with a triangle in the lower right corner gives 2. To solve the puzzle, pass this information to Ally.'
];

const hintsPhonebookAlly = [
  'This phone book provides information for a puzzle in Old Dog’s room.',
  'It might be helpful to ask Old Dog the name of the person that should be called. ',
  'In the phone book find a number to call Gustav. You can notice that there is only one Gustav there. Maybe it’s worth passing this number to Old Dog?',
  'The number you should pass to Old Dog is 956-75-44.'
];

const getMonitorImage = (color: string, noiseVisible: boolean) => {
  if (noiseVisible) {
    return '';
  }

  switch (color) {
    case 'red':
      return monitorRed;
    case 'blue':
      return monitorBlue;
    case 'green':
      return monitorGreen;
    case 'yellow':
      return monitorYellow;
    default:
      return '';
  }
};

export const Level2 = () => {
  const [{ isOldDog, loading }, { changeLevel }] = useCurrentPlayer();
  const [{ data }] = useGame();
  const [colorState, setColorState] = useState({ num: 5, noiseVisible: false });
  const color = COLORS[colorState.num];
  const backgroundImage = isOldDog ? computerRoom : reception;
  const monitorLight = getMonitorImage(color, colorState.noiseVisible);
  const levelCompletedByOtherPlayer = !!data?.level2End;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setColorState({
        num: colorState.noiseVisible ? (colorState.num + 1) % COLORS.length : colorState.num,
        noiseVisible: !colorState.noiseVisible
      });
    }, colorState.noiseVisible ? NOISE_INTERVAL : INTERVAL);
    return () => clearTimeout(timeout);
  }, [colorState]);

  if (loading) {
    return <Loading />;
  }

  return (
    <GameBoard backgroundImage={backgroundImage}>
      {isOldDog ? (
        <>
          <GameItem
            iconStyle={{ left: '69.3vw', top: '0vw', width: '11.7vw', height: '12vw' }}
            imageSrc={framedNewspaper}
            canvas={true}
            canvasColor="#ff0000"
            name="l2FramedNewspaper"
            hints={hintsFramedNewspaper}
          />
          <GameItem
            iconStyle={{ left: '28.3vw', top: '34.5vw', width: '18vw', height: '11vw' }}
            imageSrc={battleship1}
            canvas={true}
            canvasColor="#ff0000"
            name="l2BattleshipOldDog"
            hints={hintsBattleshipsOldDog}
          />
          <Mobile />
          <Monitor />
          {monitorLight && <img src={monitorLight} alt="Monitor" className={style.monitor} />}
          <img src={lightMonitor} className={style.additionalLight} alt="Monitor light" />
          <img src={lightMobile} className={style.additionalLight} alt="Mobile light" />
        </>
      ) : (
        <>
          <GameItem
            iconStyle={{ left: '33vw', top: '0vw', width: '22vw', height: '10vw' }}
            imageSrc={symbols}
            canvas={true}
            canvasColor="#ff0000"
            name="l2SquareSymbols"
            hints={hintsSquareSymboldAlly}
          />
          <GameItem
            iconStyle={{ left: '28vw', top: '16.2vw', width: '20vw', height: '8vw' }}
            imageSrc={newspaper}
            canvasColor="#ff0000"
            canvas={true}
            name="l2Newspaper"
            hints={hintsBattleshipsAlly}
          />
          <GameItem
            iconStyle={{ left: '54vw', top: '23.8vw', width: '22vw', height: '13vw' }}
            imageSrc={phonebook}
            canvas={true}
            canvasColor="#ff0000"
            name="l2Phonebook"
            hints={hintsPhonebookAlly}
          />
          <GameItem
            iconStyle={{ left: '69vw', top: '0vw', width: '31vw', height: '26vw' }}
            imageSrc={books}
            canvas={true}
            canvasColor="#ff0000"
            hints={hintsBooksAlly}
            name="l2Books"
          />
        </>
      )}
      <GameLock
        type="numbersWarm"
        iconStyle={isOldDog
          ? { left: '83.7vw', top: '6vw', width: '15vw', height: '25vw' }
          : { left: '4.7vw', top: '4vw', width: '19vw', height: '26vw' }}
        imageSrc={lock}
        leftStart={39.1}
        leftStep={6}
        name="l2Lock"
        numberContainerStyle={{ top: '31vw' }}
        solutions={lockSolutions}
        hints={hintsLock}
        submitStyle={{
          position: 'absolute',
          right: '15vw',
          top: '32vw'
        }}
        onSolved={() => {
          sounds.success2();
          setTimeout(() => changeLevel('3-waiting'), 500);
        }}
        solution="3845"
        showSolution={levelCompletedByOtherPlayer}
      />
    </GameBoard>
  );
};
