import React, { useEffect } from 'react';
import { StoreProvider, useStoreActions } from 'easy-peasy';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { withResizeDetector } from 'react-resize-detector';
import { Provider, ErrorBoundary } from '@rollbar/react';

import { rollbarConfig } from './config';
import AccessCode from './menu/AccessCode';
import store from './store';
import { TouchRipple } from './components';
import { ErrorPage } from './ErrorPage';
import './App.css';
import 'animate.css';

const App = ({ width, height }: { width: number; height: number }) => {
  const setSize = useStoreActions((state: any) => state.resize.set);

  useEffect(() => {
    setSize({ height, width });
  }, [height, width, setSize]);

  return (
    <div className="App">
      <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
        <AccessCode />
      </DndProvider>
    </div>
  );
};

const AppWithResize = withResizeDetector(App);

export default () => (
  <Provider config={rollbarConfig}>
    <ErrorBoundary fallbackUI={ErrorPage}>
      <StoreProvider store={store}>
        <TouchRipple>
          <AppWithResize />
        </TouchRipple>
      </StoreProvider>
    </ErrorBoundary>
  </Provider>
);
