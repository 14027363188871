import React from 'react';
import { useDrag, DragSourceMonitor } from 'react-dnd';

import style from './Computer.module.css';

interface FileProps {
  filename: string;
  onDrop: (filename: string, destination: string) => void;
}

export const File: React.FC<FileProps> = ({ filename, onDrop }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { name: `File ${filename}`, type: 'file', filename },
    end: (item: { name: string } | undefined, monitor: DragSourceMonitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onDrop(filename, dropResult.destination);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;

  return (
    <div
      ref={drag}
      className={style.file}
      style={{ opacity }}
    >
      {filename}
    </div>
  );
};
