import React from 'react';
import classNames from 'classnames';
import { useDrag, DragSourceMonitor } from 'react-dnd';

import style from './BlindMice.module.css';

import arrowUp from './assets/arrow_blue_up.png';
import arrowLeft from './assets/arrow_blue_left.png';
import arrowUpDark from './assets/arrow_white_up.png';
import arrowLeftDark from './assets/arrow_white_left.png';

export const arrowImages = [
  arrowUp,
  arrowLeft,
  arrowUpDark,
  arrowLeftDark
];

export function getArrowImg(direction: string) {
  switch (direction) {
    case 'up':
      return arrowUp;
    case 'left':
      return arrowLeft;
    default:
      return arrowUp;
  }
}

export function getDarkArrowImg(direction: string) {
  switch (direction) {
    case 'up':
      return arrowUpDark;
    case 'left':
      return arrowLeftDark;
    default:
      return arrowUpDark;
  }
}

interface ArrowImageProps {
  direction: string;
  dark: boolean;
}

export const ArrowImage: React.FC<ArrowImageProps> = ({ direction, dark }) => {
  const img = dark ? getDarkArrowImg(direction) : getArrowImg(direction);
  return (
    <div
      style={{ backgroundImage: `url(${img})` }}
      className={classNames(style.arrow, style[`arrow${direction}`])}
    />
  );
};

interface ArrowProps {
  i: number;
  direction: string;
  isBlueArrow: boolean;
  canDrag: boolean;
  position: [number, number] | null;
  onDrop: (i: number, position: [number, number] | null) => void;
}

export const Arrow: React.FC<ArrowProps> = ({ i, onDrop, position, direction, isBlueArrow, canDrag }) => {
  const [{ isDragging }, drag] = useDrag({
    canDrag,
    item: { name: `Arrow ${direction} ${i}`, type: 'arrow', direction, position },
    end: (item: { name: string } | undefined, monitor: DragSourceMonitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        const { base, x, y } = dropResult;
        onDrop(i, base ? null : [x, y]);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;
  const positionStyle = position ? {
    top: `${9.25 + 4.09 * position[1]}vw`,
    left: `${37.11 + 4.09 * position[0]}vw`
  } : {};

  return (
    <div
      ref={isBlueArrow ? drag : undefined}
      style={{ opacity, ...positionStyle }}
      className={classNames(style.arrowDrag, style[`arrowDrag${i}`], isBlueArrow || style.arrowDragBlocked, canDrag || style.arrowDragBlocked)}
    >
      <ArrowImage direction={direction} dark={!isBlueArrow} />
    </div>
  );
};
