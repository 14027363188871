import React from 'react';

import { GameItem } from '../../../components';
import { useCurrentPlayer } from '../../../state';

import officePaperLeft from './assets/tornPaperLeft.png';
import officePaperRight from './assets/tornPaperRight.png';

export const tornPaperImages = [
  officePaperLeft,
  officePaperRight
];

const oldDogHints = [
  'This is only half of a paper. To solve the puzzle, you need to find another half. Maybe Ally can help?',
  'Combine Old Dog’s left and Ally’s right side of the note and read the message aloud.',
  'You should be able to use the code using the console on the wall. Don’t forget about the correct order.',
  'Combining two sides of a torn note, reading from the end, should give you the code: 0385.'
];

const allyHints = [
  'This is only half of a paper. To solve the puzzle, you need to find another half. Maybe Old Dog can help?',
  'Combine Old Dog’s left and Ally’s right side of the note and read the message aloud.',
  'You should be able to use the code using the console on the wall. Don’t forget about the correct order.',
  'Combining two sides of a torn note, reading from the end, should give you the code: 0385.'
];

export const TornPaper = () => {
  const [{ isOldDog }] = useCurrentPlayer();

  if (isOldDog) {
    return (
      <GameItem
        name="l1TornPaperLeft"
        imageSrc={officePaperLeft}
        hints={oldDogHints}
        canvas={true}
        canvasColor="#ff0000"
        iconStyle={{
          top: '35vw',
          left: '29vw',
          width: '19vw',
          height: '14vw'
        }}
      />
    );
  }

  return (
    <GameItem
      name="l1TornPaperRight"
      imageSrc={officePaperRight}
      hints={allyHints}
      canvas={true}
      canvasColor="#ff0000"
      iconStyle={{
        top: '33vw',
        left: '28vw',
        width: '15vw',
        height: '13vw'
      }}
    />
  );
};
