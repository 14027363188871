import { useCallback } from 'react';
import { db, useFirestoreQuery } from '../../../firebase';
import { useAccessCode } from '../../../state';
import { sounds } from '../../../components';

const CORRECT_COMBINATION = [false, true, true, false, true, false, false, true, false, false, true, false];

interface BiohazardPayload {
  current: number;
  bacteriaVisible: boolean;
  button1Pressed: boolean;
  button2Pressed: boolean;
  button3Pressed: boolean;
  button4Pressed: boolean;
  button5Pressed: boolean;
  button6Pressed: boolean;
  button7Pressed: boolean;
  button8Pressed: boolean;
  button9Pressed: boolean;
  button10Pressed: boolean;
  button11Pressed: boolean;
  button12Pressed: boolean;
}

export const initialState: BiohazardPayload = {
  current: 0,
  bacteriaVisible: false,
  button1Pressed: false,
  button2Pressed: false,
  button3Pressed: false,
  button4Pressed: false,
  button5Pressed: false,
  button6Pressed: false,
  button7Pressed: false,
  button8Pressed: false,
  button9Pressed: false,
  button10Pressed: false,
  button11Pressed: false,
  button12Pressed: false
};

type BiohazardResponse = [BiohazardPayload, {
  pressButton: (button: number) => void;
  increaseCurrent: () => void;
  decreaseCurrent: () => void;
  setBacteriaVisible: () => void;
}];

export function useBiohazard(): BiohazardResponse {
  const [{ code }] = useAccessCode();
  const stateDoc = db.collection('game')
    .doc(code)
    .collection('l4Biohazard')
    .doc('state');
  const { data } = useFirestoreQuery<BiohazardPayload>(stateDoc);

  const state = { ...initialState, ...data };

  const increaseCurrent = useCallback(() => {
    const current = state.current;
    if (current < 0 || current >= 9) {
      return;
    }
    stateDoc.set({ current: current + 1 }, { merge: true });
    sounds.tick();
  }, [stateDoc, state]);

  const decreaseCurrent = useCallback(() => {
    const current = state.current;
    if (current <= 0 || current > 9) {
      return;
    }
    stateDoc.set({ current: current - 1 }, { merge: true });
    sounds.tick();
  }, [stateDoc, state]);

  const setBacteriaVisible = useCallback(() => {
    stateDoc.set({ bacteriaVisible: true }, { merge: true });
    sounds.bzzt();
  }, [stateDoc]);

  const pressButton = useCallback(async(button: number) => {
    if (state.bacteriaVisible) {
      sounds.error();
      return;
    }
    sounds.beep3();
    const buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const pressedButtonsCount = buttons.map(b => (state as any)[`button${b}Pressed`]).filter(b => !!b).length + 1;
    const pressedButtons = buttons.map((b) => {
      if (b === button) return true;
      return (state as any)[`button${b}Pressed`];
    });

    if (pressedButtonsCount >= 5) {
      // validate the solution
      const isSolutionValid = pressedButtons.reduce((prev, current, index) => {
        return prev && current === CORRECT_COMBINATION[index];
      }, true);
      if (isSolutionValid) {
        stateDoc.set({
          [`button${button}Pressed`]: true,
          bacteriaVisible: true
        }, { merge: true });
        sounds.bzzt();
      } else {
        stateDoc.set({
          button1Pressed: false,
          button2Pressed: false,
          button3Pressed: false,
          button4Pressed: false,
          button5Pressed: false,
          button6Pressed: false,
          button7Pressed: false,
          button8Pressed: false,
          button9Pressed: false,
          button10Pressed: false,
          button11Pressed: false,
          button12Pressed: false
        }, { merge: true });
      }
    } else {
      stateDoc.set({ [`button${button}Pressed`]: true }, { merge: true });
    }
  }, [state, stateDoc]);

  return [state, { increaseCurrent, decreaseCurrent, setBacteriaVisible, pressButton }];
}
