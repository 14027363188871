import React from 'react';
import { useDrop } from 'react-dnd';

import style from './Microscope.module.css';

interface DropzoneMicroscopeProps {
  isDragging: boolean;
}

export const DropzoneMicroscope: React.FC<DropzoneMicroscopeProps> = ({ isDragging }) => {
  const [, drop] = useDrop({
    accept: 'sample',
    drop: () => ({
      where: 'microscope'
    }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  return (
    <div ref={drop} className={style.dropzone} style={{ zIndex: isDragging ? 3 : 1 }} />
  );
};
