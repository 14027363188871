import page1_pic1 from './assets/page1_pic1.png';
import page1_pic2 from './assets/page1_pic2.png';
import page1_pic3 from './assets/page1_pic3.png';
import page1_pic4 from './assets/page1_pic4.png';
import page1_txt1 from './assets/page1_txt1.png';
import page1_txt2 from './assets/page1_txt2.png';
import page1_txt3 from './assets/page1_txt3.png';
import page1_txt4 from './assets/page1_txt4.png';
import page2_pic1 from './assets/page2_pic1.png';
import page2_pic2 from './assets/page2_pic2.png';
import page2_pic3 from './assets/page2_pic3.png';
import page2_pic4 from './assets/page2_pic4.png';
import page2_pic5 from './assets/page2_pic5.png';
import page2_pic6 from './assets/page2_pic6.png';
import page2_txt1 from './assets/page2_txt1.png';
import page2_txt2 from './assets/page2_txt2.png';
import page2_txt3 from './assets/page2_txt3.png';
import page2_txt4 from './assets/page2_txt4.png';
import page2_txt5 from './assets/page2_txt5.png';
import page2_txt6 from './assets/page2_txt6.png';
import page2_txt7 from './assets/page2_txt7.png';

const WIDTH = 2800;

export const CUTSCENE2 = [
  { type: 'sound', src: 'spooky', delay: 0, volume: 0.8 },
  { type: 'image', src: page1_pic1, delay: 2000, top: 0, left: 0, width: (614 / WIDTH) * 100, animationSpeed: 'slower' },
  { type: 'image', src: page1_pic2, delay: 4000, top: 0, left: 23, width: (526 / WIDTH) * 100, animationSpeed: 'slower' },
  { type: 'image', src: page1_txt1, delay: 1000, top: 11, left: 25, width: (434 / WIDTH) * 100, animationSpeed: 'slower' },
  { type: 'sound', src: 'thisLooksOminous', delay: 500 },
  { type: 'image', src: page1_pic3, delay: 1500, top: 0, left: 43, width: (1600 / WIDTH) * 100, animationSpeed: 'slow' },
  { type: 'image', src: page1_txt2, delay: 2000, top: -2, left: 36, width: (787 / WIDTH) * 100 },
  { type: 'sound', src: 'voice12', delay: 0 },
  { type: 'image', src: page1_pic4, delay: 2000, top: 39, left: 43.4, width: (1584 / WIDTH) * 100 },
  { type: 'image', src: page1_txt3, delay: 2000, top: -2, left: 82, width: (621 / WIDTH) * 100 },
  { type: 'sound', src: 'voice32', delay: 0 },
  { type: 'image', src: page1_txt4, delay: 2000, top: 68, left: 78, width: (663 / WIDTH) * 100, animationIn: 'bounceInRight' },
  { type: 'sound', src: 'split', delay: 300 },
  { type: 'page-end', delay: 4000 },
  { type: 'sound', src: 'paper', delay: 400 },
  { type: 'image', src: page2_pic1, delay: 0, top: 0, left: 0, width: (931 / WIDTH) * 100 },
  { type: 'image', src: page2_pic3, delay: 2000, top: 0, left: 35.5, width: (1801 / WIDTH) * 100 },
  { type: 'image', src: page2_txt2, delay: 100, top: -2, left: 69, width: (779 / WIDTH) * 100 },
  { type: 'sound', src: 'voice31', delay: 0 },
  { type: 'image', src: page2_pic2, delay: 2000, top: 32, left: 0, width: (772 / WIDTH) * 100 },
  { type: 'image', src: page2_txt1, delay: 500, top: 20, left: -1, width: (820 / WIDTH) * 100 },
  { type: 'sound', src: 'voice13', delay: 0 },
  { type: 'image', src: page2_pic4, delay: 2000, top: 32, left: 28.9, width: (516 / WIDTH) * 100 },
  { type: 'image', src: page2_txt3, delay: 500, top: 37, left: 31, width: (334 / WIDTH) * 100 },
  { type: 'sound', src: 'ummmOk', delay: 0 },
  { type: 'image', src: page2_pic5, delay: 2000, top: 32, left: 48.5, width: (920 / WIDTH) * 100 },
  { type: 'image', src: page2_txt4, delay: 500, top: 13, left: 36, width: (859 / WIDTH) * 100 },
  { type: 'sound', src: 'voice21', delay: 0 },
  { type: 'image', src: page2_txt5, delay: 2000, top: 70, left: 38, width: (621 / WIDTH) * 100 },
  { type: 'image', src: page2_txt6, delay: 2000, top: 71, left: 68, width: (370 / WIDTH) * 100 },
  { type: 'image', src: page2_pic6, delay: 2000, top: 34, left: 83.2, width: (468 / WIDTH) * 100 },
  { type: 'image', src: page2_txt7, delay: 500, top: 27, left: 80, width: (604 / WIDTH) * 100 },
  { type: 'sound', src: 'famousLastWords', delay: 0 }
];
