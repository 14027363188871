import React, { useState } from 'react';
import classNames from 'classnames';

import { GameItem, sounds } from '../../../components';
import { CustomDragLayer } from './CustomDragLayer';
import { Paper, paperImages } from './Paper';
import { BottleDropzone, bottleDropzoneImages } from './BottleDropzone';

import style from './PhTable.module.css';

import table from './assets/table.png';
import drag from './assets/drag.png';

export const phTableImages = [
  table,
  drag,
  ...paperImages,
  ...bottleDropzoneImages
];

const hints = [
  'To solve this puzzle, you need the bottles filled with various liquids in Ally’s room and the pH scale in Old Dog’s room.',
  'Ask Old Dog if there is any information on the pH scale.',
  'Knowing that the more acidic, the better, and red is at the end of the acidic scale, check the pH of the liquids in six bottles.',
  'The answer is 8.'
];

function getPaperName(checkedBottleNumber?: number) {
  switch (checkedBottleNumber) {
    case 0:
      return 'blue';
    case 2:
      return 'violet';
    case 4:
      return 'green';
    case 6:
      return 'orange';
    case 8:
      return 'red'; // solution
    case 9:
      return 'yellow';
    default:
      return 'normal';
  }
}

export const PhTable = () => {
  const [checkedBottle, setCheckedBottle] = useState<undefined|number>();

  function onDrop(bottleNumber: number) {
    sounds.waterDrop();
    setCheckedBottle(bottleNumber);
  }

  return (
    <GameItem
      name="l3PhTable"
      hints={hints}
      imageSrc={table}
      iconStyle={{
        top: '30vw',
        left: '73vw',
        width: '24vw',
        height: '24vw'
      }}
    >
      <CustomDragLayer />
      <Paper name={getPaperName(checkedBottle)} onDrop={onDrop} />
      <img src={drag} className={classNames(style.drag)} alt="Drag" onDragStart={ev => ev.preventDefault()} />
      <BottleDropzone bottleNumber={0} />
      <BottleDropzone bottleNumber={2} />
      <BottleDropzone bottleNumber={4} />
      <BottleDropzone bottleNumber={6} />
      <BottleDropzone bottleNumber={8} />
      <BottleDropzone bottleNumber={9} />
    </GameItem>
  );
};
