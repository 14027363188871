import pic1 from './assets/pic1.png';
import pic2 from './assets/pic2.png';
import pic3 from './assets/pic3.png';
import pic4 from './assets/pic4.png';
import pic5 from './assets/pic5.png';

import txt1 from './assets/txt_1.png';
import txt2 from './assets/txt_2.png';
import txt3 from './assets/txt_3.png';
import txt4 from './assets/txt_4.png';
import txt5 from './assets/txt_5.png';
import txt6 from './assets/txt_6.png';
import txt7 from './assets/txt_7.png';

const WIDTH = 2800;

export const CUTSCENE3 = [
  { type: 'sound', src: 'gobletOfWisdom', delay: 0, volume: 0.2 },
  { type: 'image', src: pic1, delay: 2500, top: 3, left: 0, width: (498 / WIDTH) * 100, visible: false, animationSpeed: 'slow' },
  { type: 'sound', src: 'unlock2', delay: 1000 },
  { type: 'sound', src: 'unlock1', delay: 800 },
  { type: 'image', src: pic2, delay: 1000, top: 3, left: 19, width: (615 / WIDTH) * 100, visible: false },
  { type: 'sound', src: 'chain1', delay: 200 },
  { type: 'sound', src: 'openDoor', delay: 1500 },
  { type: 'image', src: txt1, delay: 1500, top: 0, left: 16.5, width: (787 / WIDTH) * 100, visible: false, zIndex: 2 },
  { type: 'sound', src: 'whatDoYouThinkTheyreDoingHere', delay: 500 },
  { type: 'image', src: pic3, delay: 2000, top: 0, left: 40.5, width: (1039 / WIDTH) * 100, visible: false, animationIn: 'bounceInRight', animationSpeed: 'fast' },
  { type: 'sound', src: 'zoneEnd', delay: 0, volume: 0.4 },
  { type: 'image', src: txt2, delay: 0, top: 51, left: 29.3, width: (288 / WIDTH) * 100, visible: false },
  { type: 'sound', src: 'voice22', delay: 0 },
  { type: 'image', src: pic4, delay: 1350, top: 41, left: 42, width: (963 / WIDTH) * 100, visible: false },
  { type: 'image', src: txt3, delay: 500, top: 38, left: 40.5, width: (749 / WIDTH) * 100, visible: false },
  { type: 'sound', src: 'clearlyAtLeastTwoPeople', delay: 300 },
  { type: 'image', src: pic5, delay: 2450, top: 1, left: 77.5, width: (628 / WIDTH) * 100, visible: false },
  { type: 'image', src: txt4, delay: 1000, top: 1, left: 73.5, width: (787 / WIDTH) * 100, visible: false },
  { type: 'sound', src: 'voice20', delay: 0 },
  { type: 'image', src: txt5, delay: 3000, top: 16, left: 77.5, width: (437 / WIDTH) * 100, visible: false },
  { type: 'image', src: txt6, delay: 500, top: 25, left: 77.5, width: (512 / WIDTH) * 100, visible: false },
  { type: 'image', src: txt7, delay: 800, top: 70, left: 77.5, width: (663 / WIDTH) * 100, visible: false },

  // { type: 'sound', src: 'footsteps', delay: 0 },
];
