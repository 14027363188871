import { useCallback } from 'react';
import { db, useFirestoreQuery } from '../../../firebase';
import { useAccessCode, useCurrentPlayer } from '../../../state';
import { sounds } from '../../../components';
import { WEIGHTS_OLD_DOG, WEIGHTS_ALLY, Weight } from './Weight';

interface ScalePayload {
  solved: boolean;
  errored: boolean; // triggered when outside bounds
  weightsOnScale: string[]; // ids
}

export const initialState: ScalePayload = {
  solved: false,
  errored: false,
  weightsOnScale: []
};

type ScaleResponse = [ScalePayload, {
  isOnScale: (weight: Weight) => boolean;
  addWeight: (weight: Weight) => void;
  removeWeight: (weight: Weight) => void;
}];

export const calculateTotalWeight = (weightsOnScale: string[]) => [
  ...WEIGHTS_OLD_DOG.filter(w => weightsOnScale.indexOf(w.id) !== -1),
  ...WEIGHTS_ALLY.filter(w => weightsOnScale.indexOf(w.id) !== -1)
].reduce((p, c) => p + c.weight, 0) + 2;

const isWeightWithinBounds = (weightsOnScale: string[]) => {
  const weight = calculateTotalWeight(weightsOnScale);
  return weight <= 5 && weight >= -5;
};

export function useScaleState(): ScaleResponse {
  const [{ code }] = useAccessCode();
  const [, { logAction }] = useCurrentPlayer();
  const stateDoc = db.collection('game')
    .doc(code)
    .collection('l3Scale')
    .doc('state');
  const { data } = useFirestoreQuery<ScalePayload>(stateDoc);

  const state = { ...initialState, ...data };

  const isOnScale = useCallback((weight: Weight) => !!state.weightsOnScale.find(weightId => weightId === weight.id), [state]);

  const addWeight = useCallback((weight: Weight) => {
    logAction('l3Scale-addWeight', `${weight}`, state);
    if (state.solved || state.errored) {
      sounds.error();
      return;
    }
    sounds.weight();

    const weightsOnScale = [...state.weightsOnScale, weight.id];
    const incorrectWeight = !isWeightWithinBounds(weightsOnScale);
    const errored = incorrectWeight || state.errored;
    const solved = !errored && calculateTotalWeight(weightsOnScale) === 0;

    if (solved) {
      sounds.success();
    }

    stateDoc.set({ weightsOnScale, errored, solved }, { merge: true });
  }, [state, stateDoc, logAction]);

  const removeWeight = useCallback((weight: Weight) => {
    logAction('l3Scale-removeWeight', `${weight}`, state);
    if (state.solved) {
      sounds.error();
      return;
    }
    sounds.weight();

    const weightsOnScale = [...state.weightsOnScale.filter(weightId => weight.id !== weightId)];
    let errored = !isWeightWithinBounds(weightsOnScale) || state.errored;
    if (weightsOnScale.length === 0) {
      errored = false;
    }
    stateDoc.set({ weightsOnScale, errored }, { merge: true });
  }, [state, stateDoc, logAction]);

  return [state, { isOnScale, addWeight, removeWeight }];
}
