import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';

import { sounds, voices } from '..';

import style from './SpeechBubble.module.css';

import stefan from './img/oldDog.png';
import ally from './img/ally.png';
import phone from './img/phone.png';
import speaker from './img/speaker.png';

export const speechBubbleImages = [stefan, ally, speaker, phone];

const getAvatarImage = (person: 'stefan' | 'ally' | 'phone' | 'speaker') => {
  switch (person) {
    case 'stefan':
      return stefan;
    case 'ally':
      return ally;
    case 'phone':
      return phone;
    case 'speaker':
      return speaker;
    default:
      return ally;
  }
};

interface SpeechBubbleProps {
  phrase: string;
  delay: number;
  person: 'stefan' | 'ally' | 'phone' | 'speaker';
  onClose: () => void;
}

export const SpeechBubble = ({ phrase, delay, person, onClose }: SpeechBubbleProps) => {
  const [{ visible }, setState] = useState({ visible: true });
  const avatar = getAvatarImage(person);

  useEffect(() => {
    const timeouts = [
      setTimeout(() => setState({ visible: false }), delay - 750),
      setTimeout(() => onClose(), delay)
    ];
    return () => timeouts.forEach(clearTimeout);
  }, [delay, onClose]);

  return (
    <div
      className={classnames(
        style.speechBubbleContainer,
        style.withDarkOverlay,
        'animate__animated',
        visible ? 'animate__fadeInUp' : 'animate__fadeOutDown',
        'animate__faster'
      )}
      onClick={() => {
        setState({ visible: false });
        setTimeout(() => onClose(), 750);
      }}
    >
      <div className={style.avatar}>
        <img src={avatar} alt="Avatar" />
      </div>
      <div className={style.speechContent}>
        {phrase || 'I don\'t know what to say...'}
      </div>
    </div>
  );
};

let cancelSpeaking: any;

export const speech = {
  say: (phrase: string) => {
    setTimeout(() => {
      if (cancelSpeaking) return;

      const content = (voices as any)[phrase as any];
      if (!content) {
        console.error('Phrase not found', phrase);
        return;
      }

      const div = document.createElement('div');
      document.body.appendChild(div);
      ReactDOM.render(<SpeechBubble
        phrase={content.text}
        delay={content.delay}
        person={content.person}
        onClose={() => {
          ReactDOM.unmountComponentAtNode(div);
          cancelSpeaking && cancelSpeaking();
          cancelSpeaking = null;
        }}
      />, div);

      setTimeout(() => {
        try {
          cancelSpeaking = (sounds as any)[phrase](1);
        } catch {
          console.error('Audio phrase not found');
        }
      }, 1);
    }, 10);
  }
};
