import React from 'react';
import { ClassicButton } from './components';

import style from './ErrorPage.module.css';

export const ErrorPage = () => (
  <div className={style.errorPage}>
    <h1>Sorry, the game crashed</h1>
    <p>Please try reloading the game.</p>
    <p><ClassicButton className={style.button} size="small" onClick={() => window.location.reload()}>Reload the game</ClassicButton></p>
    <p>If the problem persists, contact us at <a href="mailto:contact@elevenpuzzles.com">contact@elevenpuzzles.com</a></p>
  </div>
);
