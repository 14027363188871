import React, { memo } from 'react';
import { FoodImage } from './Food';

const styles = {
  display: 'inline-block',
  transform: 'rotate(7deg)',
  WebkitTransform: 'rotate(7deg)',
  width: '5vw'
};

export interface FoodDragPreviewProps {
  name: string;
}

export const FoodDragPreview: React.FC<FoodDragPreviewProps> = memo(
  ({ name }) => (
    <div style={styles}>
      <FoodImage name={name} />
    </div>
  )
);
