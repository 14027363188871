import React from 'react';
import classNames from 'classnames';
import { useDrag, DragSourceMonitor } from 'react-dnd';

import style from './Equipment.module.css';

import item1 from './assets/glass1.png';
import item2 from './assets/glass2.png';
import item3 from './assets/glass3.png';
import item4 from './assets/glass4.png';
import item5 from './assets/glass5.png';
import item6 from './assets/glass6.png';

export const itemImages = [
  item1,
  item2,
  item3,
  item4,
  item5,
  item6
];

export function getItemImg(itemId: number) {
  switch (itemId) {
    case 1:
      return item1;
    case 2:
      return item2;
    case 3:
      return item3;
    case 4:
      return item4;
    case 5:
      return item5;
    case 6:
      return item6;
    default:
      return item1;
  }
}

interface ItemImageProps {
  itemId: number;
}

export const ItemImage: React.FC<ItemImageProps> = ({ itemId }) => {
  const img = getItemImg(itemId);
  return (
    <div
      style={{ backgroundImage: `url(${img})` }}
      className={classNames(style.item, style[`item${itemId}`])}
    />
  );
};

interface ItemProps {
  itemId: number;
  inSlot: number;
  onDrop: (itemId: number, slot: number) => void;
  onDragBegin?: () => void;
  onDragEnd?: () => void;
}

export const Item: React.FC<ItemProps> = ({ inSlot, onDrop, itemId, onDragBegin, onDragEnd }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { name: `Item ${itemId}`, type: 'item', itemId },
    begin: () => onDragBegin && onDragBegin(),
    end: (item: { name: string } | undefined, monitor: DragSourceMonitor) => {
      onDragEnd && onDragEnd();
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onDrop(itemId, dropResult.slot);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;

  return (
    <div
      ref={drag}
      style={{ opacity }}
      className={classNames(style.itemDrag, style[`itemDrag${itemId}`], style[`itemInSlot${inSlot}`])}
    >
      <ItemImage itemId={itemId} />
    </div>
  );
};
