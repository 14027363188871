import React, { useCallback, useEffect, useState } from 'react';

import { CustomDragLayer } from './CustomDragLayer';
import { BeakerDropzone } from './BeakerDropzone';
import { Beaker, beakerImages } from './Beaker';
import { ColorMarker } from './ColorMarker';
import { useSynthesisState } from './synthesisState';
import { GameItem, ClassicButton, sounds } from '../../../components';

import style from './Synthesis.module.css';

import bg from './assets/bg.png';
import front from './assets/board_fg.png';
import smoke from './assets/smoke3.png';
import solutionNumber from './assets/number.png';
import bottleFront from './assets/bottleFront.png';
import fire1 from './assets/fire1.png';
import fire2 from './assets/fire2.png';
import fire3 from './assets/fire3.png';
import fire4 from './assets/fire4.png';
import fireLight from './assets/fire_light.png';

export const synthesisImages = [
  bg,
  front,
  smoke,
  solutionNumber,
  bottleFront,
  fire1,
  fire2,
  fire3,
  fire4,
  fireLight,
  ...beakerImages
];

const hints = [
  'To solve this puzzle, you will need probes in various colors in Old Dog\'s room and a note on the Super-brain catalyst in Ally\'s room.',
  'Ask Ally to read the instructions and add the ingredients in the correct order.',
  'What can the last ingredient be? Which color can turn blue into green? If you don\'t know, search the internet.',
  'You add purple, then two times blue, and finally yellow. This gives number 7.'
];

export const SynthesisTable = () => {
  const [smokeState, setSmokeState] = useState(0);
  const [solutionVisible, setSolutionVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [{ solved, addedColors }, { addColor, reset }] = useSynthesisState();

  const showResetButton = addedColors.length > 0 && !solutionVisible && !smokeState;

  const onDrop = useCallback((color: string) => {
    addColor(color);
  }, [addColor]);

  useEffect(() => {
    if (!solved) {
      return;
    }
    sounds.boiling();
    setTimeout(() => setSmokeState(1), 9500);
    setTimeout(() => setSmokeState(2), 9900);
    setTimeout(() => {
      sounds.smokePuff();
    }, 9500);
    setTimeout(() => setSolutionVisible(true), 9800);
  }, [solved]);

  // make sure that the fire sound is fired
  useEffect(() => {
    let interval: any;
    let cancelFireSound: any;
    if (modalVisible) {
      cancelFireSound = sounds.fire(0.05);
      interval = setInterval(() => {
        cancelFireSound = sounds.fire(0.05);
      }, 11801);
    }
    return () => {
      clearInterval(interval);
      cancelFireSound && cancelFireSound();
    };
  }, [modalVisible]);

  return (
    <GameItem
      name="l3Synthesis"
      imageSrc={bg}
      hints={hints}
      onOpen={() => setModalVisible(true)}
      onClose={() => setModalVisible(false)}
      iconStyle={{
        top: '15vw',
        left: '6vw',
        width: '39.5vw',
        height: '24vw'
      }}
    >
      <CustomDragLayer />
      <BeakerDropzone />
      <Beaker color="Yellow" onDrop={onDrop} />
      <Beaker color="Purple" onDrop={onDrop} />
      <Beaker color="Green" onDrop={onDrop} />
      <Beaker color="Blue" onDrop={onDrop} />
      <Beaker color="Red" onDrop={onDrop} />
      <Beaker color="Orange" onDrop={onDrop} />
      <Beaker color="Grey" onDrop={onDrop} />
      <img src={front} alt="Front" className={style.front} onDragStart={ev => ev.preventDefault()} />
      <ColorMarker />
      <ClassicButton
        onClick={reset}
        style={{ top: '24vw', left: '20vw', display: solved ? 'none' : '', opacity: showResetButton ? 1 : 0 }}
        size="small"
      >
        Reset
      </ClassicButton>
      <img src={solutionNumber} alt="Seven" className={style.solutionNumber} style={{ opacity: solutionVisible ? 1 : 0 }} />
      <img
        src={smoke}
        alt="Smoke"
        className={style.smoke}
        style={{
          opacity: smokeState === 1 ? 1 : 0,
          transform: `translateY(-${smokeState * 2}vw)`
        }}
      />
      <img src={fire1} alt="Fire1" className={style.fire1} />
      <img src={fire2} alt="Fire2" className={style.fire2} />
      <img src={fire3} alt="Fire3" className={style.fire3} />
      <img src={fire4} alt="Fire4" className={style.fire4} />
      <img src={fireLight} alt="Fire Light" className={style.fireLight} />
      <img src={bottleFront} alt="Bottle Front" className={style.bottleFront} />
    </GameItem>
  );
};
