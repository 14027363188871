import React from 'react';
import classnames from 'classnames';
import { useDrop } from 'react-dnd';

import style from './PhTable.module.css';

import bottle0 from './assets/bottle0.png';
import bottle2 from './assets/bottle2.png';
import bottle4 from './assets/bottle4.png';
import bottle6 from './assets/bottle6.png';
import bottle8 from './assets/bottle8.png';
import bottle9 from './assets/bottle9.png';

export const bottleDropzoneImages = [
  bottle0,
  bottle2,
  bottle4,
  bottle6,
  bottle8,
  bottle9
];

interface PaperDropzoneProps {
  bottleNumber: number;
}

function getBottleImg(bottleNumber: number) {
  switch (bottleNumber) {
    case 0:
      return bottle0;
    case 2:
      return bottle2;
    case 4:
      return bottle4;
    case 6:
      return bottle6;
    case 8:
      return bottle8;
    case 9:
      return bottle9;
    default:
      return bottle0;
  }
}

export const BottleDropzone: React.FC<PaperDropzoneProps> = ({ bottleNumber }) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'paper',
    drop: () => ({ bottleNumber }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const isActive = canDrop && isOver;

  return (
    <div ref={drop} className={classnames(style.bottleDrop, style[`bottle${bottleNumber}`])}>
      <img src={getBottleImg(bottleNumber)} alt={`Bottle ${bottleNumber}`} className={classnames(style.bottle, isActive && style.bottleActive)} onDragStart={ev => ev.preventDefault()} />
    </div>
  );
};
