import React from 'react';

import { GameItem } from '../../../components';
import { Disc } from './Disc';

import style from './Discs.module.css';

import background from './assets/discBackground.png';
import cap from './assets/cap.png';

export const discsImages = [
  background,
  cap
];

const hints = [
  'This is a part of a complex puzzle. To solve it, you will need a poster of flags of the world, a periodic table, and three wheels in Old Dog\'s room, and a device with funnels and boxes filled with powders in Ally\'s room.',
  'First, you need to solve the maps and periodic table puzzle in Old Dog\'s room and the device with funnels in Ally\'s room.',
  'You have three icons from the device with funnels in Ally\'s room. But on which wheel should each one go? There are three colors, each on one wheel. Maybe the same colors can be found on Ally\'s device with funnels?',
  'The solution is blue wheel - magnifying glass, green wheel - 3 neck round bottom flask, red wheel - glove. When you set it correctly, it gives the number 4.'
];

export const Discs = () => (
  <GameItem
    name="l3Discs"
    imageSrc={background}
    hints={hints}
    iconStyle={{
      top: '38vw',
      left: '76vw',
      width: '19.5vw',
      height: '16vw'
    }}
  >
    <Disc location="B" />
    <Disc location="TL" />
    <Disc location="TR" />
    <img src={cap} alt="Cap" className={style.cap} onDragStart={ev => ev.preventDefault()} />
  </GameItem>
);
