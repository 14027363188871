import React, { useState } from 'react';
import { useStoreActions } from 'easy-peasy';
import { Button, message } from 'antd';

import { sounds } from '../../components';
import { GameItem } from '../GameItem';
import { NumberSpinner } from './NumberSpinner';

import style from './GameLock.module.css';
import lockNumbers from './lockNumbersPixel.png';
import lockNumbersWarm from './lockNumbersWarm.png';
import lockNumbersCold from './lockNumbersCold.png';
import colors from './colorsPixel.png';
import letters from './letters.png';
import { useCurrentPlayer } from '../../state';

export const gameLockImages = [
  lockNumbers,
  colors,
  letters
];

export interface Solution {
  combination: string;
  correct: boolean;
  message?: string;
}

interface GameLockProps {
  type?: 'numbers' | 'colors' | 'letters' | 'numbersWarm' | 'numbersCold';
  initialValue?: [number, number, number, number];
  name: string;
  shortDescription?: string;
  shortDescriptionPosition?: 'top' | 'bottom';
  description?: string;
  iconSrc?: string;
  iconStyle?: React.CSSProperties;
  imageSrc: string;
  numberContainerStyle?: React.CSSProperties;
  solutions: Solution[];
  submitText?: string;
  onSolved: () => void;
  disabled?: boolean;
  hints?: React.ReactNode[];
  onClick?: () => void;
  speech?: string;
  debug?: boolean; // always show icon border
  debugOpen?: boolean;
  leftStart: number;
  leftStep: number;
  submitStyle?: React.CSSProperties;
  solution?: string | React.ReactNode;
  showSolution?: boolean;
}

export const GameLock = (props: GameLockProps) => {
  const setModalVisible = useStoreActions((state: any) => state.app.setModalOpen);
  const [value, setValue] = useState(props.initialValue || [0, 0, 0, 0]);
  const [, { logAction }] = useCurrentPlayer();

  let spinnerImg = lockNumbers;
  if (props.type === 'colors') {
    spinnerImg = colors;
  } else if (props.type === 'letters') {
    spinnerImg = letters;
  } else if (props.type === 'numbersWarm') {
    spinnerImg = lockNumbersWarm;
  } else if (props.type === 'numbersCold') {
    spinnerImg = lockNumbersCold;
  }

  const onUp = (i: number) => {
    const newValue = [...value];
    newValue[i] -= 1;
    setValue(newValue);
    sounds.lockTurn();
  };

  const onDown = (i: number) => {
    const newValue = [...value];
    newValue[i] += 1;
    setValue(newValue);
    sounds.lockTurn();
  };

  const onSubmit = () => {
    const submittedSolution = value.map(v => (v + 100000) % 10).join('');
    for (const solution of props.solutions) {
      if (solution.combination === submittedSolution && solution.correct) {
        solution.message && message.success(solution.message, 10);
        props.onSolved();
        setModalVisible(null);
        logAction('lock-solved', props.name);
        return;
      }

      if (solution.combination === submittedSolution) {
        sounds.error();
        message.error(solution.message);
        logAction('lock-error-predicted', props.name, { combination: solution.combination, correct: solution.correct });
        return;
      }
    }
    logAction('lock-error', props.name, { combination: submittedSolution });
    sounds.error();
  };

  return (
    <GameItem
      iconSrc={props.iconSrc}
      iconStyle={props.iconStyle}
      canvas={false}
      name={props.name}
      description={props.description}
      hints={props.hints}
      onClick={props.onClick}
      speech={props.speech}
      debug={props.debug}
      debugOpen={props.debugOpen}
      shortDescription={props.shortDescription}
      shortDescriptionPosition={props.shortDescriptionPosition}
    >
      <div className={style.lockImageContainer}>
        <img
          className={style.lockImage}
          src={props.imageSrc}
          alt="Lock"
          onDragStart={ev => ev.preventDefault()}
        />
      </div>
      <div className={style.numberContainer} style={props.numberContainerStyle}>
        <NumberSpinner i={0} leftStart={props.leftStart} leftStep={props.leftStep} value={value[0]} onUp={onUp} onDown={onDown} img={spinnerImg} disabled={props.disabled} />
        <NumberSpinner i={1} leftStart={props.leftStart} leftStep={props.leftStep} value={value[1]} onUp={onUp} onDown={onDown} img={spinnerImg} disabled={props.disabled} />
        <NumberSpinner i={2} leftStart={props.leftStart} leftStep={props.leftStep} value={value[2]} onUp={onUp} onDown={onDown} img={spinnerImg} disabled={props.disabled} />
        <NumberSpinner i={3} leftStart={props.leftStart} leftStep={props.leftStep} value={value[3]} onUp={onUp} onDown={onDown} img={spinnerImg} disabled={props.disabled} />
      </div>
      {!props.disabled && (
        <div className={style.buttonContainer} style={props.submitStyle}>
          <Button
            size="large"
            className={style.submitButton}
            onClick={onSubmit}
          >
            {props.submitText || 'Open'}
          </Button>
        </div>
      )}
      {props.showSolution && (
        <div className={style.solution}>
          Correct combination: {props.solution}
        </div>
      )}
    </GameItem>
  );
};
