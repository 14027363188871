import { useCallback } from 'react';
import { db, useFirestoreQuery } from '../../../firebase';
import { useAccessCode } from '../../../state';
import { sounds } from '../../../components';

interface ArcadePayload {
  handX: number; // 6
  handY: number; // 3
  handZ: number; // 2
  grabbed: boolean;
  wet: boolean;
}

export const DEFAULT_POSITION = {
  handX: 2,
  handY: 2,
  handZ: 0 // 0 = raised
};

export const initialState: ArcadePayload = {
  ...DEFAULT_POSITION,
  grabbed: false,
  wet: false
};

type ArcadeResponse = [ArcadePayload, {
  move: (direction: string) => void;
}];

const canGoLeft = ({ handX, handY, handZ }: { handX: number; handY: number; handZ: number}) => {
  // grabber down
  if (handZ > 0) {
    return false;
  }

  // mid wall block
  if (handY < 2 && (handX === 1 || handX === 3 || handX === 5)) {
    return false;
  }

  // left wall
  if (handX === 0) {
    return false;
  }

  return true;
};

const canGoRight = ({ handX, handY, handZ }: { handX: number; handY: number; handZ: number}) => {
  // grabber down
  if (handZ > 0) {
    return false;
  }

  // mid wall block
  if (handY < 2 && (handX === 0 || handX === 2 || handX === 4)) {
    return false;
  }

  // right wall
  if (handX === 5) {
    return false;
  }

  return true;
};

const canGoUp = ({ handY, handZ }: { handX: number; handY: number; handZ: number}) => {
  // grabber down
  if (handZ > 0) {
    return false;
  }

  // top wall block
  if (handY === 0) {
    return false;
  }

  return true;
};

const canGoDown = ({ handY, handZ }: { handX: number; handY: number; handZ: number}) => {
  // grabber down
  if (handZ > 0) {
    return false;
  }

  // bottom wall block
  if (handY === 2) {
    return false;
  }

  return true;
};

const canLower = ({ handX, handY, handZ }: { handX: number; handY: number; handZ: number}) => {
  // grabber already down
  if (handZ > 0) {
    return false;
  }

  // left and right wall
  if (handX === 0 || handX === 5) {
    return false;
  }

  // right box
  if ((handX === 3 || handX === 4) && handY > 0) {
    return false;
  }

  return true;
};

export function useArcade(): ArcadeResponse {
  const [{ code }] = useAccessCode();
  const stateDoc = db.collection('game')
    .doc(code)
    .collection('l4Arcade')
    .doc('state');
  const { data } = useFirestoreQuery<ArcadePayload>(stateDoc);

  const state = { ...initialState, ...data };

  const move = useCallback((direction: string) => {
    if (direction === 'Raise' && state.handZ === 1) {
      stateDoc.set({ handZ: 0 }, { merge: true });
    } else if (direction === 'Lower' && canLower(state)) {
      stateDoc.set({
        handZ: 1,
        grabbed: state.grabbed || (state.handX === 1 && state.handY === 0),
        wet: state.wet || (state.grabbed && state.handX === 3 && state.handY === 0)
      }, { merge: true });
    } else if (direction === 'Left' && canGoLeft(state)) {
      stateDoc.set({ handX: state.handX - 1 }, { merge: true });
    } else if (direction === 'Right' && canGoRight(state)) {
      stateDoc.set({ handX: state.handX + 1 }, { merge: true });
    } else if (direction === 'Up' && canGoUp(state)) {
      stateDoc.set({ handY: state.handY - 1 }, { merge: true });
    } else if (direction === 'Down' && canGoDown(state)) {
      stateDoc.set({ handY: state.handY + 1 }, { merge: true });
    } else {
      sounds.robotMoveError(0.2);
    }
  }, [stateDoc, state]);

  return [state, { move }];
}
