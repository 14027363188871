import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Draggable from 'react-draggable';

import { CartoonButton, sounds } from '../../../components';

import style from './UnicelledOrganisms.module.css';

import drag from './assets/drag.png';
import key from './assets/key.png';
import rotate from './assets/rotate.png';

export const keyImages = [
  drag,
  key,
  rotate
];

export const KeyImage: React.FC = () => (
  <div
    style={{ backgroundImage: `url(${key})` }}
    className={classNames(style.key)}
  />
);

export const Key: React.FC = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [dragDistance, setDragDistance] = useState(0);
  const [tutorialVisible, setTutorialVisible] = useState(false);

  const doRotate = () => {
    sounds.rotate();
    setRotation(rotation + 1);
  };

  useEffect(() => {
    if (position.x === 0 && position.y === 0) {
      const timeout = setTimeout(() => setTutorialVisible(true), 2000);
      return () => clearTimeout(timeout);
    }
  }, [position]);

  return (
    <Draggable
      key="key"
      position={position}
      onStart={(_, data) => {
        sounds.keyPutDown();
        setTutorialVisible(false);
        setDragDistance(0);
        setPosition({ x: data.x, y: data.y });
      }}
      onDrag={(_, data) => {
        const distance = Math.sqrt((data.x - position.x) ** 2 + (data.y - position.y) ** 2);
        setDragDistance(dragDistance + distance);
        setPosition({ x: data.x, y: data.y });
      }}
      onStop={(_, data) => {
        if (dragDistance >= 15) {
          sounds.keyPutDown2();
        }
        setPosition({ x: data.x, y: data.y });
      }}
    >
      <div className={style.keyDrag}>
        <img src={drag} alt="Drag Tutorial" className={classNames(style.dragTutorial, 'animate__animated animate__pulse animate__infinite')} style={{ opacity: tutorialVisible ? 1 : 0 }} />
        <div style={{ transform: `rotate(${rotation * 45}deg)` }} className={style.rotateWrapper}>
          <CartoonButton
            src={rotate}
            activeSrc={rotate}
            hoverSrc={rotate}
            className={style.rotate}
            onUp={() => {
              if (dragDistance < 15) {
                doRotate();
              }
            }}
          />
          <KeyImage />
        </div>
      </div>
    </Draggable>
  );
};
