import React, { memo } from 'react';
import { PaperImage } from './Paper';

const styles = {
  display: 'inline-block',
  transform: 'rotate(7deg)',
  WebkitTransform: 'rotate(7deg)',
  width: '5vw'
};

export interface PaperDragPreviewProps {
  name: string;
}

export const PaperDragPreview: React.FC<PaperDragPreviewProps> = memo(
  ({ name }) => (
    <div style={styles}>
      <PaperImage name={name} />
    </div>
  )
);
