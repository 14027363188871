import { db, useFirestoreQuery } from '../../../firebase';
import { useAccessCode, useCurrentPlayer } from '../../../state';
import { sounds } from '../../../components';

interface CodePayload {
  code: string;
  solved: boolean;
}

export const initialState: CodePayload = {
  code: '',
  solved: false
};

type KeypadResponse = [CodePayload, {
  onButtonPress: (button: string) => void;
  onSubmit: () => void;
}];

export function useKeypad(): KeypadResponse {
  const [{ code }] = useAccessCode();
  const [, { logAction }] = useCurrentPlayer();
  const stateDoc = db.collection('game')
    .doc(code)
    .collection('l1Keypad')
    .doc('state');
  const { data } = useFirestoreQuery<CodePayload>(stateDoc);

  const state = {...initialState, ...data};

  function onButtonPress(key: string) {
    if (state.code.length >= 4 || state.solved) {
      sounds.keypadError();
      return;
    }
    sounds.buttonBeep();
    stateDoc.set({ code: state.code + key, solved: state.solved });
  }

  function onSubmit() {
    if (state.solved) {
      sounds.keypadError();
      return;
    }

    if (state.code === '0385') {
      sounds.success();
      setTimeout(() => stateDoc.set({ solved: true }, { merge: true }), 1000);
      logAction('lock-solved', 'l1Lock');
    } else {
      sounds.error();
      stateDoc.set({ solved: false, code: '' });
      logAction('lock-error', 'l1Lock', {
        combination: state.code
      });
    }
  }

  return [state, { onButtonPress, onSubmit }];
}
