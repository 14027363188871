import React, { useState, useCallback } from 'react';
import { GameItem, sounds } from '../../../components';

import style from './Snake.module.css';

import background from './assets/bg.png';
import head from './assets/head.png';
import neck from './assets/neck.png';
import tail1 from './assets/tale1.png';
import tail2 from './assets/tale2.png';
import tongue from './assets/tongue4.png';

export const snakeImages = [
  background,
  head,
  neck,
  tail1,
  tail2,
  tongue
];

const hints = [
  'This is a complex puzzle. To solve it, you will need a snake in a terrarium and computer in Old Dog’s room, and fruits and vegetables with scales in Ally’s room.',
  'The snake has eaten something. What can it be? Maybe Ally can help?',
  'Describe the appearance of the snake in detail to Ally. All information on the shapes and patterns you see may be helpful.',
  'The solution is 1650.'
];

export const Snake = () => {
  const [hissing, setHissing] = useState(false);

  const doHiss = useCallback(() => {
    if (hissing) return;
    setHissing(true);
    setTimeout(() => setHissing(false), 200);
    sounds.snake1();
  }, [hissing]);

  return (
    <GameItem
      name="l5Snake"
      imageSrc={background}
      hints={hints}
      iconStyle={{
        top: '6vw',
        left: '57vw',
        width: '15vw',
        height: '24vw'
      }}
    >
      <div className={style.headWrapper}>
        <img src={tongue} className={style.tongue} alt="tongue" onDragStart={ev => ev.preventDefault()} />
        <img src={head} className={style.head} alt="Head" onDragStart={ev => ev.preventDefault()} />
        <img src={neck} className={style.neck} alt="neck" onDragStart={ev => ev.preventDefault()} />
      </div>
      <div className={style.tailWrapper}>
        <img src={tail1} className={style.tail1} alt="tail1" onDragStart={ev => ev.preventDefault()} />
        <img src={tail2} className={style.tail2} alt="tail2" onDragStart={ev => ev.preventDefault()} />
      </div>
      <div className={style.snakeClickContainer} onClick={() => doHiss()} />
    </GameItem>
  );
};
