import React, { useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';

import { ErrorPage } from './ErrorPage';
import { Loading } from './Loading';
import { AssetLoader } from './AssetLoader';
import { Cutscene } from './cutscenes';
import { usePlayers, useCurrentPlayer, useAccessCode, useDebugMode } from './state';
import { Level1, Level2, Level3, Level4, Level5, Level6 } from './levels';
import { GamePreparationMenu } from './menu/GamePreparationMenu';

const MainPage = () => {
  const { status, error } = usePlayers();
  const [{ currentPlayer, loading }] = useCurrentPlayer();
  const setModalVisible = useStoreActions((state: any) => state.app.setModalOpen);
  const [{ code }] = useAccessCode();
  const [{ debugMode }] = useDebugMode();
  const level = currentPlayer?.level;

  const isLoading = loading || status === 'loading' || !code;

  // make sure that modal is closed when changing a level
  useEffect(() => {
    setModalVisible(null);
  }, [level, setModalVisible]);

  if (error) return <ErrorPage />;
  if (isLoading) return <Loading />;

  switch ((currentPlayer.started || debugMode) ? `${level}` : 'default') {
    case '1-waiting':
      return <AssetLoader key="cutscene-1" cutscene="1"><Cutscene nextLevel="1" /></AssetLoader>;
    case '1':
      return <AssetLoader key="level-1" level="1"><Level1 /></AssetLoader>;
    case '2-waiting':
      return <AssetLoader key="cutscene-2" cutscene="2"><Cutscene nextLevel="2" /></AssetLoader>;
    case '2':
      return <AssetLoader key="level-2" level="2"><Level2 /></AssetLoader>;
    case '3-waiting':
      return <AssetLoader key="cutscene-3" cutscene="3"><Cutscene nextLevel="3" /></AssetLoader>;
    case '3':
      return <AssetLoader key="level-3" level="3"><Level3 /></AssetLoader>;
    case '4-waiting':
      return <AssetLoader key="cutscene-4" cutscene="4"><Cutscene nextLevel="4" /></AssetLoader>;
    case '4':
      return <AssetLoader key="level-4" level="4"><Level4 /></AssetLoader>;
    case '5-waiting':
      return <AssetLoader key="cutscene-5" cutscene="5"><Cutscene nextLevel="5" /></AssetLoader>;
    case '5':
      return <AssetLoader key="level-5" level="5"><Level5 /></AssetLoader>;
    case '6-waiting':
      return <AssetLoader key="cutscene-6" cutscene="6"><Cutscene nextLevel="6" /></AssetLoader>;
    case '6':
      return <AssetLoader key="level-6" level="6"><Level6 /></AssetLoader>; // summary, game completed!
    default:
      return <AssetLoader key="prep" level="prep"><GamePreparationMenu /></AssetLoader>;
  }
};

export default MainPage;
