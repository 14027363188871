import React from 'react';
import classnames from 'classnames';

import style from './Controls.module.css';

import hint from './hintLight.png';
import clear from './clearLight.png';
import undo from './undoLight.png';
import reset from './resetLight.png';
import speech from './speechLight.png';
import close from './closeLight.png';

export const controlsImages = [hint, clear, undo, reset, speech];

interface Controls {
  clear?: boolean;
  onClear?: () => void;
  undo?: boolean;
  onUndo?: () => void;
  reset?: boolean;
  onReset?: () => void;
  hint?: boolean;
  onHint?: () => void;
  speech?: boolean;
  onSpeech?: () => void;
  close?: boolean;
  onClose?: () => void;
}

export const Controls = (props: Controls) => (
  <div className={style.container}>
    {props.close && (
      <div className={classnames(style.close, style.control)} onClick={props.onClose}>
        <img className={classnames(style.icon, style.hintIcon)} src={close} alt="Close" />
        <span className={style.close}>Close</span>
      </div>
    )}
    {props.hint && (
      <div className={classnames(style.hint, style.control)} onClick={props.onHint}>
        <img className={classnames(style.icon, style.hintIcon)} src={hint} alt="Hints" />
        <span className={style.close}>Hints</span>
      </div>
    )}
    {props.speech && (
      <div className={classnames(style.hint, style.control)} onClick={props.onSpeech}>
        <img className={classnames(style.icon)} src={speech} alt="Speech" />
        <span className={style.close}>Play</span>
      </div>
    )}
    {props.undo && (
      <div className={classnames(style.undo, style.control)} onClick={props.onUndo}>
        <img className={style.icon} src={undo} alt="Undo" />
        <span className={style.close}>Undo</span>
      </div>
    )}
    {props.clear && (
      <div className={classnames(style.clear, style.control)} onClick={props.onClear}>
        <img className={style.icon} src={clear} alt="Clear" />
        <span className={style.close}>Clear</span>
      </div>
    )}
    {props.reset && (
      <div className={classnames(style.reset, style.control)} onClick={props.onReset}>
        <img className={style.icon} src={reset} alt="Reset" />
        <span className={style.close}>Reset</span>
      </div>
    )}
  </div>
);
