import React from 'react';
import classNames from 'classnames';
import { useDrop } from 'react-dnd';

import style from './Equipment.module.css';

interface DropzoneProps {
  slotId: number;
  isDragging: boolean;
}

export const Dropzone: React.FC<DropzoneProps> = (props) => {
  const [, drop] = useDrop({
    accept: 'item',
    drop: () => ({ type: 'item', slot: props.slotId }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  return (
    <div
      ref={drop}
      className={classNames(style.dropzone, style[`dropzone${props.slotId}`])}
      style={{ zIndex: props.isDragging ? 3 : 1 }}
    />
  );
};
