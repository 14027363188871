import React, { memo } from 'react';
import { VialImage } from './Vial';

const styles = {
  display: 'inline-block',
  transform: 'rotate(-7deg)',
  WebkitTransform: 'rotate(-7deg)',
  width: '5vw',
  zIndex: 2
};

export interface VialDragPreviewProps {
  vialNumber: string;
}

export const VialDragPreview: React.FC<VialDragPreviewProps> = memo(
  ({ vialNumber }) => (
    <div style={styles}>
      <VialImage vialNumber={vialNumber} />
    </div>
  )
);
