import React from 'react';
import classNames from 'classnames';
import { useDrag, DragSourceMonitor } from 'react-dnd';

import style from './Scales.module.css';

import weight2 from './assets/weight2.png';
import weight3 from './assets/weight3.png';
import weight6 from './assets/weight6.png';
import weight8 from './assets/weight8.png';
import weight9 from './assets/weight9.png';

export const weightImages = [
  weight2,
  weight3,
  weight6,
  weight8,
  weight9
];

export interface Weight {
  i: number;
  id: string;
  weight: number;
  img: string;
}

export const WEIGHTS_OLD_DOG: Weight[] = [{
  i: 0,
  id: '0|-3',
  weight: -3,
  img: weight3
}, {
  i: 1,
  id: '1|-3',
  weight: -3,
  img: weight3
}, {
  i: 2,
  id: '2|-6',
  weight: -6,
  img: weight6
}, {
  i: 3,
  id: '3|-9',
  weight: -9,
  img: weight9
}];
export const WEIGHTS_ALLY: Weight[] = [{
  i: 4,
  id: '4|+2',
  weight: 2,
  img: weight2
}, {
  i: 5,
  id: '5|+3',
  weight: 3,
  img: weight3
}, {
  i: 6,
  id: '6|+3',
  weight: 3,
  img: weight3
}, {
  i: 7,
  id: '7|+8',
  weight: 8,
  img: weight8
}];

interface WeightImageProps {
  weight: Weight;
}

export const WeightImage: React.FC<WeightImageProps> = ({ weight }) => (
  <div
    style={{ backgroundImage: `url(${weight.img})` }}
    className={classNames(style.weight, style[`weight${weight.i}`])}
  />
);

interface WeightProps {
  isOnScale: boolean;
  weight: Weight;
  offset: number;
  onBegin: () => void;
  onEnd: () => void;
  onDrop: (weight: Weight, destination: 'scale' | 'placeholder') => void;
}

export const Weight: React.FC<WeightProps> = ({ onBegin, onEnd, weight, onDrop, offset, isOnScale }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { name: `Weight ${weight}`, type: 'weight', weight },
    begin: () => onBegin(),
    end: (item: { name: string } | undefined, monitor: DragSourceMonitor) => {
      onEnd();
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onDrop(weight, dropResult.type);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;
  const transformOffset = isOnScale ? offset : 0;

  return (
    <div
      ref={drag}
      style={{ opacity, transform: `translateY(${transformOffset}vw)` }}
      className={classNames(style.weightDrag, style[`weightDrag${weight.i}`], isOnScale && style.weightOnScale)}
    >
      <WeightImage weight={weight} />
    </div>
  );
};
