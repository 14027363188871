/* eslint no-param-reassign: "off" */
// https://easy-peasy.now.sh/docs/quick-start.html
import { createStore } from 'easy-peasy';
import { appState, AppState } from './state/app';
import * as canvas from './levels/canvasState';
import * as resize from './levels/resizeState';

export interface StoreState {
  app: AppState;
}

const storeModel = {
  app: appState,
  canvas: {
    ...canvas.initialState,
    ...canvas.actions
  },
  resize: {
    ...resize.initialState,
    ...resize.actions
  }
};

const store = createStore(storeModel);

export default store;
