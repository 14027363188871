import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { useStoreState } from 'easy-peasy';

import { Timer } from './Timer';

import style from './GameBoard.module.css';

interface GameBoardProps {
  backgroundImage?: string;
  className?: string;
}

export const GameBoard: FunctionComponent<GameBoardProps> = (props) => {
  const modalOpen = useStoreState(state => state.app.modalOpen);

  const wrapper = (
    <div className={classnames(style.wrapper, modalOpen ? style.blur : '', props.className)}>
      { props.backgroundImage && (
        <img
          src={props.backgroundImage}
          className={style.backgroundImage}
          alt="Background"
          onDragStart={ev => ev.preventDefault()}
        />
      )}
      <div className={style.gameBoard}>
        {props.children}
      </div>
      <div className={style.stats}>
        <Timer />
        {/* <div>
          <TopTeam />
          <BottomTeam />
        </div> */}
      </div>
    </div>
  );

  return <div className={classnames(modalOpen ? style.blur : '')}>{wrapper}</div>;
};
