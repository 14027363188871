import React from 'react';
import classNames from 'classnames';

import { useVialsState, correctSequences } from './vialsState';

import style from './VialMachine.module.css';

import bekr from './assets/vmTriple.png';
import hand from './assets/vmHand.png';
import magn from './assets/vmMag.png';

export const slotDisplayImages = [
  bekr,
  hand,
  magn
];

interface SlotDisplayProps {
  slot: string;
}

export const SlotDisplay: React.FC<SlotDisplayProps> = ({ slot }) => {
  const [vialsState] = useVialsState();
  const slotState = (vialsState as any)[`slot${slot}State`];
  const slotSolved = (vialsState as any)[`slot${slot}Solved`];

  const error = slotState === 0 && !slotSolved;
  const total = (correctSequences as any)[slot].length;
  const percentage = ((total - slotState) / total) * 100;
  const empty = percentage === 0;

  let solution = <></>;

  if (slotSolved && slot === 'Poland') {
    solution = <img src={hand} alt="Hand" className={style.symbol} />;
  }

  if (slotSolved && slot === 'Ireland') {
    solution = <img src={magn} alt="Magnifying glass" className={style.symbol} />;
  }

  if (slotSolved && slot === 'Nepal') {
    solution = <img src={bekr} alt="Beaker" className={style.symbol} />;
  }

  return (
    <div className={classNames(style.slotDisplay, style[`slotDisplay${slot}`])}>
      <div
        className={classNames(style.slotState, error && style.slotStateError, (empty || slotSolved) && style.slotStateNoBorder)}
        style={{ height: `${percentage}%` }}
      >
        {error ? 'ERR' : ''}
        {solution}
      </div>
    </div>
  );
};
