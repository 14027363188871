import React, { useState } from 'react';
import classNames from 'classnames';
import { GameItem, sounds, speech } from '../../../components';
import { useCurrentPlayer, useGame } from '../../../state';
import { useVentilationState } from '../Ventilation/ventilationState';
import { useLevel5State } from '../level5State';

import style from './Door.module.css';

import doorAlly from './assets/doorAlly.png';
import doorOldDog from './assets/doorOldDog.png';
import handle from './assets/handle.png';

export const doorImages = [
  doorAlly,
  doorOldDog,
  handle
];

const hintsOldDog: any = [
  'To open the door Ally will have to find a safe.',
  'The key to the door is hidden in the safe. Ally will have to grab it and open the door for you.',
  'To find the safe you\'ll have to solve the puzzle with mice.',
  'In order to open the safe and get the key, you should follow clues for the safe, parrot, and monkey.'
];
const hintsAlly: any = [
  'To open the door you\'ll have to find and open a safe.',
  'The key to the door is hidden in the safe. To find the safe you\'ll have to solve the puzzle with mice.',
  'In order to open the safe and get the key, you should follow clues for the safe, parrot, and monkey.'
];

export const Door = () => {
  const [{ isOldDog }, { changeLevel }] = useCurrentPlayer();
  const [handleState, setHandleState] = useState<'default' | 'moving' | 'unlock'>('default');
  const [{ pipesSolved }] = useVentilationState();
  const [{ data }] = useGame();
  const [{ documentsCollected }] = useLevel5State();
  const isBombActivated = data?.bombArmed;

  const onHandleClick = () => {
    if (handleState === 'moving') return;

    if (documentsCollected) {
      sounds.doorLocked3();
      setHandleState('unlock');
      setTimeout(() => {
        setTimeout(() => changeLevel('6-waiting'), 1000);
        sounds.success2();
      }, 1000);
      return;
    }

    setHandleState('moving');
    sounds.doorLocked2();
    setTimeout(() => setHandleState('default'), 2800);

    if (pipesSolved) {
      speech.say(isOldDog ? 'l5OldDogDoorFinal' : 'l5AllyDoorFinal');
    } else if (isBombActivated) {
      speech.say(isOldDog ? 'l5OldDogDoorClosed' : 'l5AllyDoorClosed');
    }
  };

  return (
    <GameItem
      name={`l5Door${isOldDog ? 'OldDog' : 'Ally'}`}
      imageSrc={isOldDog ? doorOldDog : doorAlly}
      hints={isOldDog ? hintsOldDog : hintsAlly}
      iconStyle={isOldDog ? {
        top: '5vw',
        left: '37vw',
        width: '16vw',
        height: '27vw'
      } : {
        top: '9vw',
        left: '41vw',
        width: '16vw',
        height: '25vw'
      }}
    >
      <img src={handle} className={classNames(style.handle, style[`handle${handleState}`])} alt="Handle" onClick={() => onHandleClick()} />
    </GameItem>
  );
};
