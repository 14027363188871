import React from 'react';
import classnames from 'classnames';
import { useDrop } from 'react-dnd';

import style from './Computer.module.css';

interface RoomDropzoneProps {
  destination: string;
  playing: boolean;
}

export const RoomDropzone: React.FC<RoomDropzoneProps> = ({ destination, playing }) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'file',
    drop: () => ({ destination }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const isActive = canDrop && isOver;

  return (
    <div ref={drop} className={classnames(style.room, isActive && style.roomActive)}>{playing ? 'Playing...' : destination}</div>
  );
};
