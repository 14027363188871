import React from 'react';

import { GameItem } from '../../../components';
import { Key, keyImages } from './Key';
import { useCurrentPlayer } from '../../../state';

import cellOrganismsAlly from './assets/unicellarAlly.png';
import cellOrganismsOldDog from './assets/unicellarOldDog.png';

export const unicelledImages = [
  cellOrganismsAlly,
  cellOrganismsOldDog,
  ...keyImages
];

const hintsOldDog = [
  'To solve this puzzle, you need a poster with unicellular organisms and a precision key in Old Dog’s room and the same things in Ally’s room.',
  'Communicate with Ally to discover which unicellular organisms you have in common and different.',
  'Place a precision key (circle to circle) on the unicellular organism that cannot be found in Ally’s poster and turn it. Then, wait for something to appear in a square hole.',
  'The solution is a „STAR” and „DOWN”.'
];

const hintsAlly = [
  'To solve this puzzle, you need a poster with unicellular organisms and a precision key in Ally’s room and the same things in Old Dog’s room.',
  'Communicate with Old Dog to discover which unicellular organisms you have in common and different.',
  'Place a precision key (circle to circle) on the unicellular organism that cannot be found in Ally’s poster and turn it. Then, wait for something to appear in a square hole.',
  'The solution is a „CIRCLE” and „UP”.'
];

export const UnicelledOrganisms = () => {
  const [{ isOldDog }] = useCurrentPlayer();
  const bg = isOldDog ? cellOrganismsOldDog : cellOrganismsAlly;

  return (
    <GameItem
      name={isOldDog ? 'l4UnicelledOrganismsOldDog' : 'l4UnicelledOrganismsAlly'}
      imageSrc={bg}
      hints={isOldDog ? hintsOldDog : hintsAlly}
      iconStyle={isOldDog ? {
        top: '4vw',
        left: '34vw',
        width: '12vw',
        height: '10vw'
      } : {
        top: '1vw',
        left: '80.3vw',
        width: '13vw',
        height: '12vw'
      }}
    >
      <Key />
    </GameItem>
  );
};
