import React, { useState, useEffect } from 'react';

import { GameItem } from '../../../components';

import style from './Monitor.module.css';

import bg from './assets/bg.png';
import monitorBlue from './assets/blue.png';
import monitorGreen from './assets/green.png';
import monitorRed from './assets/red.png';
import monitorYellow from './assets/yellow.png';
import monitorNoise from './assets/grey1.png';
import monitorNoise2 from './assets/grey2.png';

export const monitorImages = [
  bg,
  monitorBlue,
  monitorGreen,
  monitorRed,
  monitorYellow,
  monitorNoise,
  monitorNoise2
];

export const COLORS = ['red', 'blue', 'green', 'yellow', 'yellow', 'blank', 'blank'];
export const INTERVAL = 800;
export const NOISE_INTERVAL = 100;
export const NOISE_CHANGE_INTERVAL = 200;

const hints = [
  'The puzzle requires both the blinking monitor and books on shelves which Ally can find in her room.',
  'Look closely to see which colors and in what order are blinking.',
  'When you look closely, you can notice the sequence: red, blue, green, yellow, and yellow. You may need to pass that information to Ally to solve this puzzle.',
  'The red color gives T, blue gives H, green gives R, and two yellows give EE - THREE. Thus, the solution is number 3.'
];

const getMonitorImage = (color: string, noiseVisible: boolean, noiseI: number) => {
  if (noiseVisible) {
    return noiseI ? monitorNoise : monitorNoise2;
  }

  switch (color) {
    case 'red':
      return monitorRed;
    case 'blue':
      return monitorBlue;
    case 'green':
      return monitorGreen;
    case 'yellow':
      return monitorYellow;
    default:
      return noiseI ? monitorNoise : monitorNoise2;
  }
};

export const Monitor = () => {
  const [noiseI, setNoiseI] = useState(0);
  const [colorState, setColorState] = useState({ num: 5, noiseVisible: false });
  const color = COLORS[colorState.num];

  const light = getMonitorImage(color, colorState.noiseVisible, noiseI);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setColorState({
        num: colorState.noiseVisible ? (colorState.num + 1) % COLORS.length : colorState.num,
        noiseVisible: !colorState.noiseVisible
      });
    }, colorState.noiseVisible ? NOISE_INTERVAL : INTERVAL);
    return () => clearTimeout(timeout);
  }, [colorState]);

  useEffect(() => {
    // damn, this is ugly
    // to0 tired to make it pretty
    const interval = setInterval(() => {
      setNoiseI(i => (i + 1) % 2);
    }, NOISE_CHANGE_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  return (
    <GameItem
      name="l2Monitor"
      hints={hints}
      imageSrc={bg}
      iconStyle={{
        top: '1vw',
        left: '13vw',
        width: '30vw',
        height: '23vw'
      }}
    >
      <img src={light} alt="Light" className={style.light} />
    </GameItem>
  );
};
