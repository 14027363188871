import React, { useState, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';

import { ClassicButton, GameItem, sounds } from '../../../components';
import { ParrotSpeechBubble } from './ParrotSpeechBubble';
import { useComputerState } from '../Computer/computerState';

import style from './Parrot.module.css';

import background from './assets/bg.png';
import body from './assets/body.png';
import head from './assets/head.png';
import wing1 from './assets/wing1.png';
import wing2 from './assets/wing2.png';
import tail from './assets/tail.png';
import stick from './assets/stick.png';
import bars from './assets/bars.png';

export const parrotImages = [
  background,
  body,
  head,
  wing1,
  wing2,
  tail,
  stick,
  bars
];

const hints = [
  'This is a part of a complex puzzle. To solve it, you will need fruits and vegetables with scales and a parrot in Ally’s room and a snake in a terrarium and a computer in Old Dog’s room.',
  'You need to solve the snake’s puzzle first.',
  'You now need to solve the computer’s puzzle.',
  'Giving a correct answer to a parrot is not enough because you are not Gustav. Maybe you can pretend to be Gustav? Ask Old Dog for help.',
  'The parrot says: „The safe is tricky! Larva, zebrafish, seven, and piggy!”'
];

const RIDDLE = `I am with you since birth, and will always be yours.
You can see me, but can't touch me.
I am following you everywhere,
but not in darkness.
Who am I?`;

const ANSWER_ON_WRONG = 'The answer is silly! You silly!';
const ANSWER_ON_CORRECT = 'You\'re not Gustav! Can\'t open the safe! Not Gustav!';
const SOLUTION_ANSWER = 'The safe is tricky! Larva, zebrafish, seven and piggy!';

const PARROT_MODE_NONE = 'none';
const PARROT_MODE_INIT = 'init';
const PARROT_MODE_QUESTION = 'question';
const PARROT_MODE_ANSWER_WRONG = 'answer_wrong';
const PARROT_MODE_ANSWER_CORRECT = 'answer_correct';
const PARROT_MODE_SOLVED = 'solved';

export const Parrot = () => {
  const [speechVisible, setSpeechVisible] = useState(false);
  const [answersVisible, setAnswersVisible] = useState(false);
  const [parrotMode, setParrotMode] = useState(PARROT_MODE_INIT);
  const [{ playingSoundDestination, playingSoundName }] = useComputerState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const parrotText = (() => {
    if (parrotMode === PARROT_MODE_QUESTION) return RIDDLE;
    if (parrotMode === PARROT_MODE_ANSWER_WRONG) return ANSWER_ON_WRONG;
    if (parrotMode === PARROT_MODE_ANSWER_CORRECT) return ANSWER_ON_CORRECT;
    if (parrotMode === PARROT_MODE_SOLVED) return SOLUTION_ANSWER;
    return '';
  })();

  const playingSoundCancel = useRef<any>();
  const playParrotVoice = useCallback((gameMode: string) => {
    if (playingSoundCancel.current) {
      playingSoundCancel.current();
    }
    if (gameMode === PARROT_MODE_QUESTION) {
      playingSoundCancel.current = sounds.parrotRiddle();
    }
    if (gameMode === PARROT_MODE_ANSWER_WRONG) {
      playingSoundCancel.current = sounds.parrotSilly();
    }
    if (gameMode === PARROT_MODE_ANSWER_CORRECT) {
      playingSoundCancel.current = sounds.parrotNotGustav();
    }
    if (gameMode === PARROT_MODE_SOLVED) {
      playingSoundCancel.current = sounds.parrotSolution();
    }
  }, []);

  const changeParrotMode = useCallback((newMode: string) => {
    setAnswersVisible(false);
    setSpeechVisible(false);
    setTimeout(() => {
      setParrotMode(newMode);
      playParrotVoice(newMode);
      if (newMode !== PARROT_MODE_NONE && isModalOpen) {
        setSpeechVisible(true);
      }
      if (newMode === PARROT_MODE_QUESTION && isModalOpen) {
        setTimeout(() => isModalOpen && setAnswersVisible(true), 2000);
      }
    }, 1000);
  }, [playParrotVoice, isModalOpen]);

  useEffect(() => {
    let timeout1: any;
    let timeout2: any;
    if (parrotMode === PARROT_MODE_ANSWER_CORRECT || parrotMode === PARROT_MODE_ANSWER_WRONG) {
      timeout1 = setTimeout(() => changeParrotMode(PARROT_MODE_QUESTION), 5000);
    }
    if (parrotMode === PARROT_MODE_INIT && isModalOpen) {
      timeout2 = setTimeout(() => changeParrotMode(PARROT_MODE_QUESTION), 2000);
    }
    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, [parrotMode, changeParrotMode, isModalOpen]);

  const onAnswerClick = (correct?: boolean) => {
    changeParrotMode(correct ? PARROT_MODE_ANSWER_CORRECT : PARROT_MODE_ANSWER_WRONG);
  };

  useEffect(() => {
    if (parrotMode === PARROT_MODE_QUESTION && playingSoundName === 'gustav_shadow.mp3' && playingSoundDestination === 'Room 2' && isModalOpen) {
      changeParrotMode(PARROT_MODE_NONE);
      setTimeout(() => changeParrotMode(PARROT_MODE_SOLVED), 4000); // wait for Gustav to stop speaking
    }
  }, [playingSoundName, playingSoundDestination, speechVisible, parrotText, changeParrotMode, isModalOpen, parrotMode]);

  const onModalClose = useCallback(() => {
    playingSoundCancel.current && playingSoundCancel.current();
    setParrotMode(PARROT_MODE_INIT);
    setAnswersVisible(false);
    setSpeechVisible(false);
    setIsModalOpen(false);
  }, []);

  return (
    <GameItem
      name="l5Parrot"
      imageSrc={background}
      hints={hints}
      onClose={onModalClose}
      onOpen={() => setIsModalOpen(true)}
      iconStyle={{
        top: '12vw',
        left: '59vw',
        width: '21vw',
        height: '22vw'
      }}
    >
      <div className={style.parrotContainer}>
        <img src={wing1} className={style.wing1} alt="wing1" />
        <img src={body} className={style.body} alt="body" />
        <img src={tail} className={style.tail} alt="tail" />
        <img src={wing2} className={style.wing2} alt="wing2" />
        <img src={head} className={style.head} alt="head" />
        <ParrotSpeechBubble visible={speechVisible} text={parrotText} />
      </div>
      <img src={stick} className={style.stick} alt="stick" />
      <img src={bars} className={style.bars} alt="bars" />
      <div className={classNames(style.answers, answersVisible && style.answersVisible)}>
        <ClassicButton onClick={() => onAnswerClick()} className={classNames(style.answerButton, style.answerButton1)}>
          Light
        </ClassicButton>
        <ClassicButton onClick={() => onAnswerClick()} className={classNames(style.answerButton, style.answerButton2)}>
          Smell
        </ClassicButton>
        <ClassicButton onClick={() => onAnswerClick(true)} className={classNames(style.answerButton, style.answerButton3)}>
          Shadow
        </ClassicButton>
        <ClassicButton onClick={() => onAnswerClick()} className={classNames(style.answerButton, style.answerButton4)}>
          Name
        </ClassicButton>
      </div>
    </GameItem>
  );
};
