import React, { useState, useCallback } from 'react';

import { GameItem, CartoonButton, sounds, ClassicButton } from '../../../components';
import { useCurrentPlayer, useGame } from '../../../state';

import style from './Lock.module.css';

import lock from './assets/bg.png';

import buttonNormal from './assets/buttonNormal.png';
import buttonHover from './assets/buttonHover.png';
import buttonClick from './assets/buttonClick.png';
import buttonDNormal from './assets/buttonDNormal.png';
import buttonDHover from './assets/buttonDHover.png';
import buttonDClick from './assets/buttonDClick.png';

import line from './assets/line.png';
import circle from './assets/circle.png';
import diamond from './assets/diamond.png';
import square from './assets/square.png';
import star from './assets/star.png';
import triangle from './assets/triangle.png';
import t from './assets/t.png';
import pentagon from './assets/pentagon.png';
import solution from './assets/solution.png';
import solutionWhite from './assets/solutionWhite.png';

export const lockImages = [
  lock,
  buttonNormal,
  buttonHover,
  buttonClick,
  buttonDNormal,
  buttonDHover,
  buttonDClick,
  line,
  circle,
  diamond,
  square,
  star,
  triangle,
  t,
  pentagon,
  solution,
  solutionWhite
];

const SHAPES = [
  line, circle, diamond, square, star, triangle, t, pentagon
];

const CORRECT_COMBINATION = [3, 1, 5, 7, 2, 4, 0, 6];

const hints = [
  'To unlock the padlock, you need to solve all puzzles in this room. They will all require cooperation.',
  'The order of the signs on the lock will determine the puzzle with a microscope in Old Dog’s room and scientific paper in Ally’s room.',
  'The colors you can find in each puzzle will help you determine which shapes go where on the lock.',
  <>
    The solution is first blue, a square over rhombus, second green a circle over a star, third red a triangle over a line, and fourth a pentagon over a reversed T letter. <br />
    <img src={solution} alt="Solution" style={{ width: '15vw', marginTop: '2vw' }} />
  </>
];

export const Lock = () => {
  const [{ isOldDog }, { changeLevel, logAction }] = useCurrentPlayer();
  const [combination, setCombination] = useState([0, 1, 2, 3, 4, 5, 6, 7]);
  // const [combination, setCombination] = useState(CORRECT_COMBINATION); // correct combination, left for debug purposes
  const [{ data }] = useGame();
  const levelCompletedByOtherPlayer = !!data?.level4End;

  const onButtonClick = useCallback((location: 'top' | 'bottom', i: number) => {
    const j = i + (location === 'bottom' ? 4 : 0);
    const newCombination = [...combination];
    newCombination[j] = (newCombination[j] + 1) % SHAPES.length;
    setCombination(newCombination);
    sounds.toe();
  }, [combination, setCombination]);

  const onSubmit = useCallback(() => {
    if (combination.join('') === CORRECT_COMBINATION.join('')) {
      sounds.success2();
      setTimeout(() => changeLevel('5-waiting'), 500);
      logAction('lock-solved', 'l4Lock');
    } else {
      sounds.error();
      logAction('lock-error', 'l4Lock', {
        combination: combination.join('')
      });
    }
  }, [combination, changeLevel, logAction]);

  return (
    <GameItem
      name={`l4Lock${isOldDog ? 'OldDog' : 'Ally'}`}
      imageSrc={lock}
      hints={hints}
      iconStyle={isOldDog ? {
        top: '1vw',
        left: '18vw',
        width: '17vw',
        height: '27vw'
      } : {
        top: '1vw',
        left: '65.6vw',
        width: '16vw',
        height: '30vw'
      }}
    >
      {
        [
          { width: '4.3vw', left: '36.6vw', top: '16.6vw' },
          { width: '4.3vw', left: '44.1vw', top: '16.6vw' },
          { width: '4.3vw', left: '51.6vw', top: '16.6vw' },
          { width: '4.3vw', left: '59.1vw', top: '16.6vw' }
        ].map((position, i) => <CartoonButton src={buttonNormal} hoverSrc={buttonHover} activeSrc={buttonClick} style={position} onClick={() => onButtonClick('top', i)} />)
      }
      {
        [
          { width: '4.3vw', left: '37vw', top: '31.6vw' },
          { width: '4.3vw', left: '44.4vw', top: '31.6vw' },
          { width: '4.3vw', left: '51.9vw', top: '31.6vw' },
          { width: '4.3vw', left: '59.1vw', top: '31.6vw' }
        ].map((position, i) => <CartoonButton src={buttonDNormal} hoverSrc={buttonDHover} activeSrc={buttonDClick} style={position} onClick={() => onButtonClick('bottom', i)} />)
      }
      {combination.map((value, i) => {
        return SHAPES.map((img, j) => <img src={img} alt={`shape${i}-${j}`} className={style[`shape${i}`]} style={{ opacity: value === j ? 1 : 0 }} />);
      })}

      <ClassicButton
        style={{ width: '15vw', top: '22vw', left: '76vw' }}
        onClick={() => onSubmit()}
      >
        Open
      </ClassicButton>
      {levelCompletedByOtherPlayer && (
        <div className={style.solution}>
          Correct combination: <img src={solutionWhite} alt="Solution White" className={style.solutionWhite} />
        </div>
      )}
    </GameItem>
  );
};
