import { db, useFirestoreQuery } from '../../../firebase';
import { useAccessCode } from '../../../state';
import { sounds } from '../../../components';

interface MicroscopePayload {
  insertedVirus: string;
}

export const initialState: MicroscopePayload = {
  insertedVirus: ''
};

type MicroscopeResponse = [MicroscopePayload, {
  insertVirus: (name: string) => void;
}];

export function useMicroscope(): MicroscopeResponse {
  const [{ code }] = useAccessCode();
  const stateDoc = db.collection('game')
    .doc(code)
    .collection('l4Microscope')
    .doc('state');
  const { data } = useFirestoreQuery<MicroscopePayload>(stateDoc);

  const state = { ...initialState, ...data };

  function insertVirus(name: string) {
    stateDoc.set({ insertedVirus: name }, { merge: true });
    sounds.putOnTable();
  }

  return [state, { insertVirus }];
}
